import { Component, HostListener, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core'

import { IWordWithFrequency } from '../pia3-word-cloud-interfaces'
import { Pia3WordCloudService } from '../pia3-word-cloud.service'

/*
Source:
https://github.com/maitrungduc1410/d3-cloud-angular
*/

@Component({
    selector: 'pia3-angular-d3-word-cloud',
    templateUrl: './pia3-angular-d3-word-cloud.component.html',
    styleUrls: ['./pia3-angular-d3-word-cloud.component.scss'],
})
export class Pia3AngularD3WordCloudComponent implements OnInit, OnChanges {
    @Input('wordsWithFreq') wordsWithFrequency: IWordWithFrequency[] = null || undefined
    @Input('stopWords') stopWords: string[] = null || undefined
    @Input('modifier') modifier: number
    filteredWordsWithFreq: IWordWithFrequency[] = null || undefined
    // displayWord: string = '';
    // displayFreqOfWord: number = 0;
    multiplier: number = 1

    @Input('width') width: number = 840
    @Input('height') height: number = 600

    @Output('wordClicked') wordClicked = new EventEmitter()
    @Output('wordMouseOver') wordMouseOver = new EventEmitter()
    @Output('wordMouseOut') wordMouseOut = new EventEmitter()

    data = ['No', 'data', 'yet'].map(function (d) {
        return { text: d, value: 10 + Math.random() * 10 }
    })

    constructor(private pia3WordCloudService: Pia3WordCloudService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.stopWords || changes.wordsWithFrequency || changes.modifier) {
            //this.onWindowResize();

            this.loadData()
        }
    }

    async ngOnInit() {
        if (this.wordsWithFrequency != null) {
            //this.onWindowResize();
            this.loadData()
        }
    }

    async loadData() {
        this.modifier == null ? (this.multiplier = 3) : (this.multiplier = 3 * this.modifier)
        this.height = window.innerHeight * 0.6
        this.width = window.innerWidth
        if (this.stopWords !== null) {
            await this.pia3WordCloudService
                .filterWords(this.wordsWithFrequency, this.stopWords)
                .then((filteredWords) => {
                    this.filteredWordsWithFreq = filteredWords
                })
                .then(() => {
                    let displayData = this.filteredWordsWithFreq.map((v) => {
                        return {
                            text: v.word,
                            value: v.freq * this.multiplier,
                        }
                    })

                    this.data = displayData
                })
        }
    }

    onWordClickEvent($event) {
        //some code here
        //////console.log('word clicked: ' + $event);
        this.wordClicked.emit($event)
    }
    wordMouseOverEvent($event) {
        //some code here
        this.wordMouseOver.emit($event)
    }

    wordMouseOutEvent($event) {
        this.wordMouseOver.emit($event)
    }

    // @HostListener('window:resize', ['$event'])
    // onWindowResize() {
    //   // if (this.wordsWithFrequency.length < 2000) {
    //   //   if (window.innerWidth > 992) {
    //   //     this.multiplier = 2.7;
    //   //   }
    //   //   if (window.innerWidth <= 992 && window.innerWidth > 768) {
    //   //     this.multiplier = 2.1;
    //   //   }
    //   //   if (window.innerWidth <= 768 && window.innerWidth > 576) {
    //   //     this.multiplier = 1.8;
    //   //   }
    //   //   if (window.innerWidth <= 576 && window.innerWidth > 350) {
    //   //     this.multiplier = 1;
    //   //   }
    //   //   if (window.innerWidth <= 350) {
    //   //     this.multiplier = 0.6;
    //   //   } else {
    //   //     // this.multiplier = 1;
    //   //   }
    //   // } else {
    //   //   if (window.innerWidth > 992) {
    //   //     this.multiplier = 0.9;
    //   //   }
    //   //   if (window.innerWidth <= 992 && window.innerWidth > 768) {
    //   //     this.multiplier = 0.7;
    //   //   }
    //   //   if (window.innerWidth <= 768 && window.innerWidth > 576) {
    //   //     this.multiplier = 0.5;
    //   //   }
    //   //   if (window.innerWidth <= 576 && window.innerWidth > 350) {
    //   //     this.multiplier = 0.2;
    //   //   }
    //   //   if (window.innerWidth <= 350) {
    //   //     this.multiplier = 0.1;
    //   //   } else {
    //   //     // this.multiplier = 1;
    //   //   }
    //   // }
    //   // this.multiplier = 3;
    //   // this.height = window.innerHeight * 0.6;
    //   // this.width = window.innerWidth;
    //   // this.loadData();
    // }
}
