import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { GenericErrorHandlerService } from 'src/app/.common/services/generic-error-handler.service'
import { SpeakerService } from 'src/app/.common/services/speaker.service'
import { Speaker, SpeakersByClientByLastNameQuery } from 'src/app/API.service'
import { Pia3AuthenticationService, Pia3CognitoRole } from 'pia3'
import { ModalController } from '@ionic/angular'

@Component({
    selector: 'app-pia-speaker-list',
    templateUrl: './pia-speaker-list.component.html',
    styleUrls: ['./pia-speaker-list.component.scss'],
})
export class PiaSpeakerListComponent implements OnInit {
    @Input('speakers') speakers: Speaker[] = []

    @Output('speakerSelectedEvent') speakerSelectedEvent: EventEmitter<Speaker> = new EventEmitter()

    public isSpeakerModalOpen: boolean = false
    public speakerBeingEdited: Speaker = null
    public enableModifySpeakerButton: boolean = false

    constructor(private modalController: ModalController, private pia3AuthenticationService: Pia3AuthenticationService) {
        this.enableModifySpeakerButton = this.pia3AuthenticationService.hasAnyPia3Role([Pia3CognitoRole.DATA_TEAMMANAGER, Pia3CognitoRole.SUPERADMIN])
    }

    ngOnInit() {}

    speakerSelected(speaker: Speaker) {
        this.speakerSelectedEvent.emit(speaker)
    }

    speakerEditButton(speaker: Speaker) {
        this.speakerBeingEdited = speaker
        this.setSpeakerModalOpen(true)
    }

    setSpeakerModalOpen(isOpen: boolean) {
        this.isSpeakerModalOpen = isOpen
    }

    childSpeakerChanged(speaker: Speaker) {
        let oldSpeakerIndex = this.speakers.indexOf(this.speakerBeingEdited)
        this.speakerBeingEdited = null

        this.speakers.splice(oldSpeakerIndex, 1)
        this.speakers.push(speaker)

        this.modalController.dismiss()
    }

    // async getSpeakersForClient(clientID: string) {
    //     this.speakerService.listSpeakersByClientByLastname(clientID, 100).then(this.speakersLoaded, this.genericErrorHandlerService.handleGenericGraphQLError).catch(this.genericErrorHandlerService.handleGenericError)
    // }

    // speakersLoaded = (result: { data: SpeakersByClientByLastNameQuery }) => {
    //     this.speakers.push(...(result.data.SpeakersByClientByLastName.items as Speaker[]))
    // }
}
