import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, Subscription } from 'rxjs'
import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'
//import { RouterParamsService } from '../.common/services/router-params.service';

import { Storage } from 'aws-amplify'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'

import {
    queries,
    mutations,
    subscriptions,
    Contextchannel,
    Session,
    ListUsersQuery,
    GetUserQuery,
    Language,
    User,
    GetContextchannelQuery,
    ListContextchannelsQuery,
    CreateContextchannelInput,
    GetClientQuery,
    CreateContextchannelLanguagesInput,
    CreateContextchannelSpeakersInput,
    UpdateContextchannelInput,
    CreateContextchannelMutation,
    CreateContextchannelLanguagesMutation,
    CreateContextchannelSpeakersMutation,
    UpdateContextchannelMutation,
    CreateContextchannelSubscriptionMutation,
} from '@humain-r/pia3-codegen'
import { updateCognitoGroupsForUser } from '@humain-r/pia3-codegen/dist/src/mutations'

@Injectable({
    providedIn: 'root',
})
export class Pia3ContextchannelService {
    private _currentContextchannel: BehaviorSubject<Contextchannel> = new BehaviorSubject(null)
    public readonly currentContextchannel: Observable<Contextchannel> = this._currentContextchannel.asObservable()

    public defaultContextchannelImage: string = 'assets/ui/components/pia-video/PIA3_video_poster.png'

    constructor(private errorHandlerService: ErrorhandlingService) {}

    async getContextchannel(id: string): Promise<{ data: GetContextchannelQuery }> {
        const query = await (API.graphql<Contextchannel>(graphqlOperation(queries.getContextchannel, { id: id })) as Promise<{ data: GetContextchannelQuery }>)
        return query
    }

    async getContextchannelTest() {
        setTimeout(() => {
            //console.log('second')
        }, 6000)
    }

    getContextchannelTestSync() {
        //setTimeout(() => {
        //console.log('second but sync')
        //}, 6000);
    }

    async getContextchannelsForCurrentUser() {
        return API.graphql(graphqlOperation(queries.listContextchannels)) as Promise<{
            data: ListContextchannelsQuery
        }>
    }

    async getContextchannelsForCurrentUserWithImages(): Promise<{
        data: ListContextchannelsQuery
    }> {
        const listContextchannelQueryResult = (await API.graphql<Contextchannel[]>({
            query: queries.listContextchannels,
        })) as Promise<{ data: ListContextchannelsQuery }>

        return listContextchannelQueryResult
        // const contextchannelsWithImages = await Promise.all(
        //   (
        //     await contextchannels
        //   )..map(async (contextchannel) => {
        //     //console.log(contextchannel);
        //     const image = await Storage.get(contextchannel.s3ImageUrl);
        //     contextchannel.s3ImageUrl =
        //       image != null ? image : this.defaultContextchannelImage;
        //     return contextchannel;
        //   })
        // );
        // return contextchannelsWithImages.data.listContextchannels.items;
    }

    async getContextchannelsByClientId(clientId: string) {
        const query = await (API.graphql(graphqlOperation(queries.getClient, { id: clientId })) as Promise<{ data: GetClientQuery }>)
        return query.data.getClient.Contextchannels
    }

    async createContextchannel(input: CreateContextchannelInput) {
        const mutation = await (API.graphql(graphqlOperation(mutations.createContextchannel, { input: input })) as Promise<{ data: CreateContextchannelMutation }>)
        return (await mutation).data.createContextchannel
    }

    async createContextchannelLanguages(input: CreateContextchannelLanguagesInput) {
        const mutation = await (API.graphql(graphqlOperation(mutations.createContextchannelLanguages, { input: input })) as Promise<{ data: CreateContextchannelLanguagesMutation }>)
        return (await mutation).data.createContextchannelLanguages
    }

    async createContextchannelSpeakers(input: CreateContextchannelSpeakersInput) {
        const mutation = await (API.graphql(graphqlOperation(mutations.createContextchannelSpeakers, { input: input })) as Promise<{ data: CreateContextchannelSpeakersMutation }>)
        return (await mutation).data.createContextchannelSpeakers
    }

    async updateContextchannel(input: UpdateContextchannelInput) {
        const mutation = await (API.graphql(graphqlOperation(mutations.updateContextchannel, { input: input })) as Promise<{ data: UpdateContextchannelMutation }>)
        return (await mutation).data.updateContextchannel
    }

    async updateContextchannelImage(contextChannelId: string, imagePath: string) {
        let input: UpdateContextchannelInput = {
            id: contextChannelId,
            s3ImageUrl: imagePath,
        }

        const mutation = await (API.graphql(graphqlOperation(mutations.updateContextchannel, { input: input })) as Promise<{ data: UpdateContextchannelMutation }>)
        return (await mutation).data.updateContextchannel
    }

    async getOnCreateContextchannelsSubscription(next: void, error: void) {
        //const subscription = API.graphql(graphqlOperation(subscriptions.onCreateContextchannel)).subscribe({})
    }

    async getOnUpdateContextchannelsSubscription() {
        // return API.graphql(graphqlOperation(subscriptions.onUpdateContextchannel));
    }

    async getOnDeleteContextchannelsSubscription() {
        // return API.graphql(graphqlOperation(subscriptions.onDeleteContextchannel));
    }

    async subscribeUserToContextchannel(contextchannel: Contextchannel) {
        return API.graphql(graphqlOperation(mutations.createContextchannelSubscription)) as Promise<{
            data: CreateContextchannelSubscriptionMutation
        }>
    }

    async addClientSpeakerToContextchannel(input: CreateContextchannelSpeakersInput) {
        const mutation = await (API.graphql(graphqlOperation(mutations.createContextchannelSpeakers, { input: input })) as Promise<{ data: CreateContextchannelSpeakersMutation }>)
        return mutation.data.createContextchannelSpeakers
    }
}
