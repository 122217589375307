import { Component, Input, OnInit } from '@angular/core'
import { SpecialObjectClass } from './_special-object-class'

@Component({
    selector: 'pia3-general-selector',
    templateUrl: './pia3-general-selector.component.html',
    styleUrls: ['./pia3-general-selector.component.scss'],
})
export class Pia3GeneralSelectorComponent implements OnInit {
    //Incoming data
    @Input('arrayOfData') arrayOfData!: (string | number | object)[]

    arrayOfString!: string[]
    arrayOfNumber!: number[]
    arrayOfObject!: object[]

    _resultOfStringArray!: any[]
    _resultOfNumberArray!: any[]
    _resultOfObjectArray!: any[]

    parentPropertyName!: string
    childPropertyName!: string

    constructor() {}

    ngOnInit() {
        this.classifyData()
        this._resultOfObjectArray = this.getValuesFromAnObjectAccordingToThePropertyNameAndSiblingLevel(this.arrayOfObject, 'name', 3)
        //////console.log("🚀 ~ Pia3GeneralSelectorComponent ~ ngOnInit ~ this. _resultOfObjectArray", this. _resultOfObjectArray);
    }

    /**
     *
     * @param data
     */
    detectPropertyNamesAndLevels(data: any[]) {
        let detect = new SpecialObjectClass(data)
        let exractedPropertiesAndLevels = detect.getAllPropertyNamesWithLevels()
        //////console.log('🚀 ~ Pia3GeneralSelectorComponent ~ exractedPropertiesAndLevelsOfGivenObject: ', exractedPropertiesAndLevels);
    }

    /**
     * @params object, propertyName, levelOfProperty
     * @returns string array from an object according to the property name and property level
     * First you must detect property name and level from your object
     */
    getValuesFromAnObjectAccordingToThePropertyNameAndSiblingLevel(object: any[], propertyName: string, levelOfProperty: number): string[] {
        if (this.arrayOfObject !== null || this.arrayOfObject !== undefined) {
            return new SpecialObjectClass(object).getValuesForPropertyNameAndLevel(propertyName, levelOfProperty)
        } else {
            throw new Error('Pia3GeneralSelectorComponent ~ getValuesFromAnObjectAccordingToThePropertyNameAndLevel ~ Data is null or undefined.')
        }
    }

    /**
     * classify and copy data
     */
    classifyData() {
        let typeOfData = this.detectTypeOfData()
        //////console.log("🚀 ~ Pia3GeneralSelectorComponent ~ classifyData ~ typeOfData: ", typeOfData);

        if (typeOfData == 'string') {
            this.arrayOfString = [...(this.arrayOfData as Array<string>)]
        }

        if (typeOfData == 'number') {
            this.arrayOfNumber = [...(this.arrayOfData as Array<number>)]
        }

        if (typeOfData == 'object') {
            this.arrayOfObject = { ...(this.arrayOfData as Array<object>) }
        }
    }

    /**
     *
     * @returns type of given data
     */
    detectTypeOfData(): string {
        if (typeof this.arrayOfData[0] == 'string') {
            return 'string'
        }

        if (typeof this.arrayOfData[0] == 'number') {
            return 'number'
        }

        if (typeof this.arrayOfData == 'object') {
            return 'object'
        }

        return null
    }
}
