import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
        data: {
            title: 'redirect',
            hideFromMenu: true,
            disabledInMenu: true,
        },
    },
    {
        path: 'auth',
        data: {
            title: 'Authenticatie',
            hideFromMenu: true,
            disabledInMenu: true,
        },
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'clients',
        data: {
            title: 'Organisaties',
            allowedRoles: [],
        },
        loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsPageModule),
    },
    {
        path: 'contextchannels',
        data: {
            title: 'Contextkanalen',
            hideFromMenu: false,
            disabledInMenu: false,
            allowedRoles: [],
        },
        loadChildren: () => import('./contextchannels/contextchannels.module').then((m) => m.ContextchannelsPageModule),
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
