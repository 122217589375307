import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Video } from './../../../API.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { Pia3VideoService, IPia3VideoPlayer, IPia3Videocontrolcommand, Videocontrolcommand } from '../pia3-video.service'
import { VgApiService, VgFullscreenApiService, VgPlayerComponent } from '@videogular/ngx-videogular/core'

import { SelectCustomEvent, NavController } from '@ionic/angular'
import { timingSafeEqual } from 'crypto'
import { Router } from '@angular/router'

export interface IPia3VideoplayerConfig {
    autohideControls?: boolean
    autohideControlsAfterSeconds?: number
    autoplay?: boolean
    autoplayOnHover?: boolean
    showControls?: boolean
    title?: string
    showBackButton?: boolean
}

@Component({
    selector: 'pia3-video-player',
    templateUrl: './pia3-video-player.component.html',
    styleUrls: ['./pia3-video-player.component.scss'],
})
export class Pia3VideoPlayerComponent implements OnInit {
    @ViewChild('vd') vd!: ElementRef
    @ViewChild('VgPlayer') vgPlayer: VgPlayerComponent

    //#region Local Variables
    public controlID: string = Math.random().toString(36).slice(-10).toString()
    private _video$: BehaviorSubject<Video> = new BehaviorSubject<Video>(null)
    public _video: Video = null
    public _videoUrl: string = null
    public _videoSubtitlesUrl: string = null
    private _height: number = 100
    public _videoplayerConfig: IPia3VideoplayerConfig = {
        autoplay: false,
        autoplayOnHover: false,
        autohideControls: false,
        autohideControlsAfterSeconds: 3,
        showControls: true,
        showBackButton: false,
    }
    public autoplayActive: boolean = false
    public autoplayCanActivate: boolean = false

    public _vgApiService: VgApiService = null

    public pia3VideoPlayer: IPia3VideoPlayer = {
        playerElement: null,
        isPlaying: false,
        isLoading: false,
        hasError: false,
        video: null,
    }
    public isLoading: boolean
    public enableVideo: boolean = false
    //#endregion

    //#region INPUTS
    @Input('video') public set video(video: Video) {
        this.pia3VideoPlayer.video = video
        this._video$.next(video)
    }

    @Input('config') public set videoplayerConfig(config: IPia3VideoplayerConfig) {
        this._videoplayerConfig = config
    }
    //#endregion

    //#region OUTPUTS
    @Output('controlID') controlIDEmitter = new EventEmitter<string>()

    //#endregion

    constructor(public router: Router, public navCtrl: NavController, public pia3VideoService: Pia3VideoService, private _vgFullscreenService: VgFullscreenApiService) {
        this.pia3VideoService.videoControl$.subscribe(this.onVideocontrolcommand)
    }

    ngOnInit() {
        this.controlIDEmitter.emit(this.controlID)
    }

    ngAfterViewInit() {
        this._video$.subscribe((video) => {
            if (video != null) {
                //Get VideoUrl
                this.pia3VideoService.getVideoUrl(video.compressedVideoS3Key).then((videoUrl) => {
                    this._videoUrl = videoUrl
                })
                //Get SubtitlesUrl
                this.pia3VideoService.getSubtitlesUrl(video.sessionID).then((subtitlesUrl) => {
                    this._videoSubtitlesUrl = subtitlesUrl
                })
            }
        })
    }

    navigateBackToOverview() {
        //clean up before leaving
        //navigate to overview
        //this.router.navigate(['home']);
        this.navCtrl.back()
    }

    restartVideo() {
        this._vgApiService.currentTime = 0
    }

    async onPlayerReady(api: VgApiService) {
        this._vgApiService = api
        //Subscribe to events
        this._vgApiService.getDefaultMedia().subscriptions.play.subscribe(this.onPlay)
        this._vgApiService.getDefaultMedia().subscriptions.pause.subscribe(this.onPause)
        this._vgApiService.fsAPI.onChangeFullscreen.subscribe(this.onChangeFullscreen)

        //Prepare autoplayOnHover
        if (this._videoplayerConfig.autoplayOnHover != null && this._videoplayerConfig.autoplayOnHover) {
            //this._vgApiService.volume = 0;
            //this._vgApiService.play();
            //this._vgApiService.pause();
            //this._vgApiService.volume = 1;
            this._vgApiService.currentTime = 1
        }

        //Trigger autoplay if set
        if (this._videoplayerConfig.autoplay != null && this._videoplayerConfig.autoplay) {
            this._vgApiService.play()
        }
    }

    trackChange($event) {
        let change: SelectCustomEvent = $event as SelectCustomEvent
        let selectedTrackLanguage: string = change.detail.value
        for (const [k, v] of Object.entries(this._vgApiService.getDefaultMedia().textTracks)) {
            if (v.language == 'es') {
                v.mode = 'showing'
            } else {
                v.mode = 'disabled'
            }
        }
    }

    //#region VgPlayer Events

    onMouseEnter($event) {}
    onMouseOver($event) {
        if (this._videoplayerConfig.autoplayOnHover != null && this._videoplayerConfig.autoplayOnHover) {
            this.autoplayCanActivate = true
            setTimeout(() => {
                if (this.autoplayCanActivate) this._vgApiService.play()
            }, 1000)
        }
    }
    onMouseOut($event) {
        if (this._videoplayerConfig.autoplayOnHover != null && this._videoplayerConfig.autoplayOnHover) {
            this.autoplayCanActivate = false
            this._vgApiService.pause()
        }
    }

    onPlay = (val) => {
        this.pia3VideoService.videoControl$.next({
            sender: this,
            controlID: this.controlID,
            command: Videocontrolcommand.PLAY,
            value: null,
        })
    }

    onPause = (val) => {
        this.pia3VideoService.videoControl$.next({
            sender: this,
            controlID: this.controlID,
            command: Videocontrolcommand.PAUSE,
            value: null,
        })
    }

    onChangeFullscreen = (val) => {
        this.pia3VideoService.videoControl$.next({
            sender: this,
            controlID: this.controlID,
            command: Videocontrolcommand.FULLSCREEN,
            value: val,
        })
    }

    //#region Videocontrolcommand Events
    onVideocontrolcommand = (command: IPia3Videocontrolcommand) => {
        if (command.sender != this && this.pia3VideoService.isMe(this.controlID, command)) {
            switch (command.command) {
                case Videocontrolcommand.PLAY:
                    this._vgApiService.play()
                    break

                case Videocontrolcommand.PAUSE:
                    this._vgApiService.pause()
                    break

                case Videocontrolcommand.STOP:
                    this._vgApiService.seekTime(0)
                    this._vgApiService.pause()
                    break

                case Videocontrolcommand.SEEK:
                    //this._vgApiService.seekTime(command.value);
                    this._vgApiService.currentTime = this.pia3VideoService.convertTimestringToSeconds(command.value)
                    break

                case Videocontrolcommand.FULLSCREEN:
                    break

                default:
                    break
            }
        } else {
            ////////console.log('videocontrol send a control message:', command);
        }
    }
    //#endregion
}
