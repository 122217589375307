<!-- SINGLE client SELECTOR -->
<!-- Enable single selector if selectorMode is SINGLE -->
<ion-select slot="end" placeholder="Selecteer een Klant" (ionChange)="clientChanged($event)"
    *ngIf="selectorMode == 'SINGLE' && clients !=undefined && clients.length> 0" interface="popover">
    <ion-select-option *ngFor="let client of clients" [value]="client">
        {{ client.displayName }}
    </ion-select-option>
</ion-select>

<ion-text *ngIf="selectorMode == 'SINGLE' && clients==undefined || clients.length==0">
    <p>Nog geen klanten beschikbaar.</p>
</ion-text>


<!-- MULTI clientS SELECTOR -->
<!-- Enable multi selector if selectorMode is MULTI -->
<div *ngIf="selectorMode == 'MULTI'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal-client"><strong>Select</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal-client" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>clients</strong></ion-label>
                    </div>
                </ion-toolbar>
            </ion-header>

            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent"
                    [indeterminate]="indeterminateState" (click)="selectAllClients()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let s of resultsOfSearch">
                        <ion-label>{{ s.client.displayName }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="s.isClientChecked"
                            (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>
<!-- show selection multi -->
<ion-list lines="none" *ngIf="showClients !== null">
    <ion-item *ngFor="let s of showClients; let i = index">
        <ion-icon color="primary" name="person-circle"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ s.displayName }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small" (click)="removeSelectionMulti(i)"></ion-icon>
    </ion-item>
</ion-list>

<!-- <ion-item-divider *ngIf="showclients !== null"></ion-item-divider> -->
<hr class="solid" *ngIf="showClients !== null" />

<!-- MULTI clientS SELECTOR WITH SEARCH-->
<!-- Enable multi client selector with search if selectorMode is MULTI_WITH_SEARCH -->
<div *ngIf="selectorMode == 'MULTI_WITH_SEARCH'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal-client"><strong>Kies</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal-client" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>clients</strong></ion-label>
                    </div>

                    <ion-searchbar [debounce]="500" (ionChange)="handleChange($event)"></ion-searchbar>
                </ion-toolbar>
            </ion-header>
            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent"
                    [indeterminate]="indeterminateState" (click)="selectAllClients()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let s of resultsOfSearch">
                        <ion-label>{{ s.client.displayName }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="s.isClientChecked"
                            (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>
<!-- show selection multi with search -->
<ion-list lines="none" *ngIf="showClientsWithSearch !== null">
    <ion-item *ngFor="let s of showClientsWithSearch; let i = index">
        <ion-icon color="primary" name="person-circle"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ s.displayName }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small"
            (click)="removeSelectionMultiWithSearch(i)"></ion-icon>
    </ion-item>
</ion-list>
<!-- <ion-item-divider *ngIf="showclientsWithSearch !== null"></ion-item-divider> -->
<hr class="solid" *ngIf="showClientsWithSearch !== null" />