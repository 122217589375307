import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Session } from './../../../API.service'
import { Pia3SessionCardConfig } from '../pia3-session-card/pia3-session-card.component'
import { Pia3SessionService } from '../pia3-session.service'

@Component({
    selector: 'pia3-session-search',
    templateUrl: './pia3-session-search.component.html',
    styleUrls: ['./pia3-session-search.component.scss'],
})
export class Pia3SessionSearchComponent implements OnInit {
    public searchTerm: string = null
    public searchLoading: boolean = false
    public sessions: Session[] = []

    @Input('sessionCardConfig') public sessionCardConfig: Pia3SessionCardConfig
    @Input('contextchannelIDs') contextchannelIDs: string[]

    constructor(private pia3SessionsService: Pia3SessionService, private modalCtrl: ModalController) {}

    ngOnInit() {}

    ngAfterViewInit() {
        //////console.log('after view init', this.sessionCardConfig)
    }

    //#region SEARCHBAR METHODS

    closeSearch() {
        this.modalCtrl.dismiss()
    }

    searchOnBlur($event) {
        //////console.log('searchOnBlur')
    }
    searchOnCancel($event) {
        //////console.log('searchOnCancel')
    }
    searchOnChange($event) {
        //////console.log('searchOnChange', $event)
        this.searchTerm = $event.detail.value
        if ($event.detail.value.replace(' ', '') != '') {
            this.sessions = []
            let searchTerm = $event.detail.value
            this.doSearch(searchTerm, this.contextchannelIDs)
        }
    }
    searchOnClear($event) {
        //////console.log('searchOnClear')
    }
    searchOnFocus($event) {
        //////console.log('searchOnFocus')
    }
    searchOnInput($event) {
        //////console.log('searchOnInput')
    }

    //#endregion

    //#region SEARCH METHODS

    async doSearch(term: string, contextchannelIDs: string[]) {
        this.searchLoading = true
        try {
            this.sessions = (await (await this.pia3SessionsService.searchSessionsInMultipleChannels(term, contextchannelIDs))) as Session[]
            this.searchLoading = false
        } catch (error) {
            this.searchLoading = false
        }
    }

    //#endregion
}
