import { Component, Input, OnInit } from '@angular/core'
import { CognitoUser } from '@aws-amplify/auth'
import { Pia3AuthenticationService } from '../authentication.service'

@Component({
    selector: 'pia3-logout-button',
    templateUrl: './pia3-logout-button.component.html',
    styleUrls: ['./pia3-logout-button.component.scss'],
})
export class Pia3LogoutButtonComponent implements OnInit {
    public currentCognitoUser: CognitoUser = null
    public _buttonText: string = 'afmelden'

    @Input('buttonText') public set buttonText(buttonText: string) {
        this._buttonText = buttonText
    }

    constructor(public authService: Pia3AuthenticationService) {
        this.authService.currentLoggedInUser.subscribe(this.currentLoggedInUserSubscriptionEvent)
    }

    ngOnInit() {}

    currentLoggedInUserSubscriptionEvent(currentCognitoUser: CognitoUser) {
        //////console.log('received event for currentcognitouser:', currentCognitoUser);
        this.currentCognitoUser = currentCognitoUser
    }

    logout() {
        //////console.log('signing out user');
        this.authService.signOutUser().then(() => {
            this.currentCognitoUser = null
        })
    }
}
