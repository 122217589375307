import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core'
import { v4 as uuidv4 } from 'uuid'
import { Audio } from './../../../API.service'
import * as WaveSurfer from 'wavesurfer.js'
import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap'
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions'
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline'
import { Region } from 'wavesurfer.js/dist/plugin/wavesurfer.regions'
import { BehaviorSubject } from 'rxjs'
import { Pia3AudioService, IPia3AudioWaveform, IPia3AudioPlayer, AudioControlCommand, IPia3AudioControlCommand } from '../audio.service'

export interface IPia3AudioplayerConfig {
    showControls?: boolean
    autoplay?: boolean
    autoplayOnHover?: boolean
}

@Component({
    selector: 'pia3-audio-player',
    templateUrl: './pia3-audio-player.component.html',
    styleUrls: ['./pia3-audio-player.component.scss'],
})
export class Pia3AudioPlayerComponent implements AfterViewInit {
    @ViewChild('ws') ws!: ElementRef

    //#region Local Variables
    public controlID: string = Math.random().toString(36).slice(-10).toString()
    private _audio$: BehaviorSubject<Audio> = new BehaviorSubject<Audio>(null)
    public configuration: IPia3AudioplayerConfig = {
        autoplay: false,
        autoplayOnHover: false,
        showControls: true,
    }
    private _height: number = 60
    private _waveColor: string = 'danger'
    private _waveProgressColor: string = 'danger'

    public pia3AudioPlayer: IPia3AudioPlayer = {
        wavesurfer: null,
        isPlaying: false,
        isLoading: false,
        hasError: false,
        audio: null,
    }
    public autoplayCanActivate: boolean = false
    public isLoading: boolean
    //#endregion

    //#region INPUTS
    @Input('audio') public set audio(audio: Audio) {
        this.pia3AudioPlayer.audio = audio
        this._audio$.next(audio)
    }
    @Input('config') public set config(config: IPia3AudioplayerConfig) {
        if (config != null) {
            this.configuration = config
        }
    }
    @Input('height') public set waveHeight(height: number) {
        this._height = height
    }

    @Input('waveColor') public set waveColor(color: string) {
        this._waveColor = color
    }
    @Input('waveProgressColor') public set waveProgressColor(color: string) {
        this._waveProgressColor = color
    }
    //#endregion

    //#region OUTPUTS
    @Output('controlID') controlIDEmitter = new EventEmitter<string>()
    //#endregion

    constructor(public pia3AudioService: Pia3AudioService) {
        this.pia3AudioService.audioControl$.subscribe(this.onAudioControlCommand)
    }

    async ngOnInit() {
        this.controlIDEmitter.emit(this.controlID)
    }

    ngAfterViewInit() {
        this._audio$.subscribe((val) => {
            this.initWavesurfer(val)
        })
    }

    onMouseEnter($event) {}
    onMouseOver($event) {
        if (this.configuration.autoplayOnHover) {
            this.autoplayCanActivate = true
            setTimeout(() => {
                if (this.autoplayCanActivate) this.play(this.pia3AudioPlayer)
            }, 1000)
        }
    }
    onMouseOut($event) {
        if (this.configuration.autoplayOnHover != null && this.configuration.autoplayOnHover) {
            this.autoplayCanActivate = false
            this.stop(this.pia3AudioPlayer)
        }
    }

    async initWavesurfer(audio: Audio) {
        setTimeout(async () => {
            if (audio != null) {
                this.isLoading = true
                this.pia3AudioPlayer.wavesurfer = WaveSurfer.create({
                    container: '#ws' + this.controlID,
                    height: this._height,
                    fillParent: true,
                    responsive: true,
                    backend: 'MediaElement',
                    waveColor: this._waveColor,
                    progressColor: this._waveProgressColor,
                })
                this.pia3AudioPlayer.wavesurfer.on('error', this.wavesurferOnError)
                this.pia3AudioPlayer.wavesurfer.on('play', this.wavesurferOnPlay)
                this.pia3AudioPlayer.wavesurfer.on('pause', this.wavesurferOnPause)
                this.pia3AudioPlayer.wavesurfer.on('ready', this.wavesurferOnReady)
                this.pia3AudioPlayer.wavesurfer.on('seek', this.wavesurferOnSeek)
                this.pia3AudioPlayer.wavesurfer.on('waveform-ready', this.wavesurferOnWaveformReady)
                this.pia3AudioPlayer.wavesurfer.on('region-play', this.wavesurferOnRegionPlay)
                this.pia3AudioPlayer.wavesurfer.on('region-in', this.wavesurferOnRegionIn)
                this.pia3AudioPlayer.wavesurfer.on('region-out', this.wavesurferOnRegionOut)

                let audioUrl: string = await this.pia3AudioService.getAudioUrl(audio.compressedAudioS3Key, 1000)
                let waveformData: IPia3AudioWaveform = await this.pia3AudioService.getAudioWaveformData(audio.compressedAudioS3Key)
                this.pia3AudioPlayer.wavesurfer.load(audioUrl, waveformData.data)
            } else {
                //////console.log('initWavesurfer: Audio is null')
            }
        }, 2000)
    }

    //#region Wavesurfer Events

    wavesurferOnReady = () => {
        ////////console.log('wavesurfer ready');
    }

    wavesurferOnWaveformReady = () => {
        this.isLoading = false
    }

    wavesurferOnError = (error) => {
        //////console.log('wavesurfer error:', error)
    }

    wavesurferOnPlay = () => {
        this.pia3AudioPlayer.isPlaying = true
    }

    wavesurferOnPause = () => {
        this.pia3AudioPlayer.isPlaying = false
    }

    wavesurferOnSeek = (value) => {
        ////////console.log('wavesurfer seek:', value);
    }

    wavesurferOnRegionPlay = () => {}
    wavesurferOnRegionIn = () => {}
    wavesurferOnRegionOut = () => {}

    //#endregion

    //#region Playback controls

    play(pia3AudioPlayer: IPia3AudioPlayer) {
        this.pia3AudioService.audioControl$.next({
            sender: this,
            controlID: this.controlID,
            command: AudioControlCommand.PLAY,
            value: null,
        })
        pia3AudioPlayer.wavesurfer.play()
    }
    pause(pia3AudioPlayer: IPia3AudioPlayer) {
        this.pia3AudioService.audioControl$.next({
            sender: this,
            controlID: this.controlID,
            command: AudioControlCommand.PAUSE,
            value: null,
        })
        pia3AudioPlayer.wavesurfer.pause()
    }
    stop(pia3AudioPlayer: IPia3AudioPlayer) {
        this.pia3AudioService.audioControl$.next({
            sender: this,
            controlID: this.controlID,
            command: AudioControlCommand.STOP,
            value: null,
        })
        pia3AudioPlayer.wavesurfer.stop()
    }
    backward(pia3AudioPlayer: IPia3AudioPlayer) {
        //pia3AudioPlayer.wavesurfer.skipBackward();
    }
    forward(pia3AudioPlayer: IPia3AudioPlayer) {
        //pia3AudioPlayer.wavesurfer.skipForward();
    }

    //#endregion

    //#region AudioControlCommand Events
    onAudioControlCommand = (command: IPia3AudioControlCommand) => {
        if (command.sender != this && this.pia3AudioService.isMe(this.controlID, command)) {
            //////console.log('AudioControl received a message:', command)

            switch (command.command) {
                case AudioControlCommand.PLAY:
                    this.play(this.pia3AudioPlayer)
                    break

                case AudioControlCommand.PAUSE:
                    this.pause(this.pia3AudioPlayer)
                    break

                case AudioControlCommand.STOP:
                    this.stop(this.pia3AudioPlayer)
                    break

                case AudioControlCommand.SEEK:
                    let totalSeconds: number = this.pia3AudioPlayer.wavesurfer.getDuration()
                    let positionSeconds: number = this.pia3AudioService.convertTimestringToSeconds(command.value)
                    let progress: number = positionSeconds / totalSeconds
                    //////console.log('totalSeconds:', totalSeconds)
                    //////console.log('positionSeconds:', positionSeconds)
                    //////console.log('progress:', progress)

                    this.pia3AudioPlayer.wavesurfer.seekAndCenter(progress)
                    break

                default:
                    break
            }
        } else {
            ////////console.log('another control send a message:', command);
        }
    }
    //#endregion
}
