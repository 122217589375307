import { Injectable } from '@angular/core'
import Amplify from 'aws-amplify'
import { Auth } from '@aws-amplify/auth'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { Storage } from '@aws-amplify/storage'
import { awsmobile, queries, mutations, LanguageLabel, ListUsersQuery } from '@humain-r/pia3-codegen';
import { DataStore, AuthModeStrategyType } from '@aws-amplify/datastore'

@Injectable({
    providedIn: 'root',
})
export class Pia3Service {
    constructor() { }

    public setupPia3() {
        //console.log('Setting up Pia3.')
        Amplify.configure(awsmobile)
        Storage.configure(awsmobile)
        Auth.configure(awsmobile)
        API.configure(awsmobile)
        //console.log('Pia3 has been setup.')
    }

    //#region S3 Loading Methods

    public async storageGetS3Url(key: string, expirationTime: number = 1000) {
        ////console.log('getting s3url for', key);
        let url: string = null
        try {
            url = await Storage.get(key.replace('public/', ''), {
                expires: expirationTime,
            })
            ////console.log(url)
            return url
        } catch (error) {
            //console.log(error)
        }
        ////console.log('url is:', url);
    }

    public async storageDownloadS3File(key: string, expirationTime: number = 1000) {
        let url: string = null
        try {
            await Storage.get(key.replace('public/', ''), {
                download: true,
                expires: expirationTime,
            })
        } catch (error) {
            //console.log(error)
        }
    }

    public async getS3FileAsJson(key: string) {
        const downloadResult = await Storage.get(key.replace('public/', ''), {
            download: true,
        })
        const jsonTextResult = await (downloadResult.Body as Blob).text()
        const jsonData = JSON.parse(jsonTextResult)
        return jsonData
    }

    public async getS3FileAsText(key: string) {
        const downloadResult = await Storage.get(key.replace('public/', ''), {
            download: true,
        })
        return await (downloadResult.Body as Blob).text()
    }

    public async listUsers(): Promise<{ data: ListUsersQuery }> {
        return API.graphql(
            graphqlOperation(queries.listUsers), {}
        ) as Promise<{ data: ListUsersQuery }>
    }

    //#endregion
}
