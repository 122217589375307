import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PiaSurferComponent } from './pia-surfer.component';
import { Pia3Module } from 'pia3';


@NgModule({
  imports: [CommonModule, HttpClientModule, FormsModule, IonicModule, Pia3Module],
  declarations: [PiaSurferComponent],
  exports: [PiaSurferComponent],
})
export class PiaSurferModule { }
