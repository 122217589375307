<ion-header>
  <ion-toolbar>
    <ion-label class="word-header" slot="start" (click)="sortWordTrigger()">
      Woord
      <ion-icon
        [name]="wordAsceding ? 'chevron-up' : 'chevron-down'"
        color="primary"
      ></ion-icon>
    </ion-label>
    <ion-label
      class="frequency-header"
      slot="end"
      (click)="sortFrequencyTrigger()"
    >
      Frequentie
      <ion-icon
        [name]="frequencyAsceding ? 'chevron-up' : 'chevron-down'"
        color="primary"
      ></ion-icon>
    </ion-label>
  </ion-toolbar>
</ion-header>
<ion-content id="ion-content-items">
  <ion-list class="ion-padding">
    <ion-item *ngFor="let item of displayWordsWithFreq">
      <ion-label class="items">{{ item.word }}</ion-label>
      <ion-label slot="end" class="items">{{ item.freq }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
