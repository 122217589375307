import { Injectable } from '@angular/core'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as queries from './../../graphql/queries';
import * as mutations from './../../graphql/mutations';
//import { queries, mutations, LanguageLabel } from '@humain-r/pia3-codegen'
import {
    SearchLanguageLabelsQuery,
    SearchableLanguageLabelFilterInput,
    GetLanguageLabelQuery,
    LanguageLabelsByGoldTranscriptByStarttimeQuery,
    LanguageLabelsBySubmittedUserBySubmittedAtQuery,
    ModelSortDirection,
    UpdateLanguageLabelInput,
    UpdateLanguageLabelMutation,
    ValidationStatus,
    LanguageLabel
} from './../../API.service';;
import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'


export type SearchLanguageLabelsQueryForChartsItem = {
    __typename: "ChartLabel";
    contextchannelID: string;
    starttime: string;
    endtime: string;
    submittedAt?: string | null;
    validatedAt?: string | null;
    submitteduserID?: string | null;
    validateduserID?: string | null;
    validationStatus?: ValidationStatus | null;
}

export type SearchLanguageLabelsQueryForCharts = {
    searchLanguageLabels?: {
        __typename: "SearchableLanguageLabelConnection";
        items: SearchLanguageLabelsQueryForChartsItem[]
        nextToken?: string | null;
    } | null;
}

@Injectable({
    providedIn: 'root',
})

export class Pia3LanguagelabelService {
    constructor(private genericErrorHandlerService: ErrorhandlingService) { }

    async SearchLanguageLabelsForCharts(
        submittedAt: {
            between: [string, string];
        } = null,
        validatedAt: {
            between: [string, string];
        } = null,
        contextchannelID: string = null,
        submitteduserID: string = null,
        validateduserID: string = null,
        sortBy: 'submittedAt' | 'validatedAt' = 'submittedAt',
        limit: number = 999,
        nextToken: string = null
    ): Promise<{ data: SearchLanguageLabelsQueryForCharts }> {
        if (submittedAt === null && validatedAt === null) {
            throw new Error('At least one of submittedAt or validatedAt must be set');
        }
        let filterArgs: SearchableLanguageLabelFilterInput = {};
        if (submittedAt) {
            filterArgs.submittedAt = {
                range: submittedAt.between,
            };
        }
        if (validatedAt) {
            filterArgs.validatedAt = {
                range: validatedAt.between,
            };
        }
        if (contextchannelID) {
            filterArgs.contextchannelID = {
                eq: contextchannelID,
            };
        }
        if (submitteduserID) {
            filterArgs.submitteduserID = {
                eq: submitteduserID,
            };
        }
        if (validateduserID) {
            filterArgs.validateduserID = {
                eq: validateduserID,
            };
        }
        return API.graphql(
            graphqlOperation(`
            query SearchLanguageLabels(
              $filter: SearchableLanguageLabelFilterInput
              $sort: [SearchableLanguageLabelSortInput]
              $limit: Int
              $nextToken: String
              $from: Int
              $aggregates: [SearchableLanguageLabelAggregationInput]
            ) {
              searchLanguageLabels(
                filter: $filter
                sort: $sort
                limit: $limit
                nextToken: $nextToken
                from: $from
                aggregates: $aggregates
              ) {
                items {
                  submitteduserID
                  validateduserID
                  submittedAt
                  validatedAt
                  contextchannelID
                  starttime
                  endtime
                  validationStatus
                }
                nextToken
              }
            }`, {
                filter: filterArgs,
                limit: limit,
                nextToken: nextToken,
                sort: {
                    field: sortBy,
                    direction: 'asc',
                }
            })
        ) as Promise<{ data: SearchLanguageLabelsQueryForCharts }>;
    }

    async LanguageLabelsBySubmittedUserBySubmittedAt(
        submitteduserid: string,
        fromDate: string,
        toDate: string,
        limit: number = 999,
        nextToken: string = null
    ): Promise<{ data: LanguageLabelsBySubmittedUserBySubmittedAtQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsBySubmittedUserBySubmittedAt, {
                submitteduserID: submitteduserid,
                submittedAt: {
                    between: [fromDate, toDate]
                },
                limit: limit,
                nextToken: nextToken
            })
        ) as Promise<{ data: LanguageLabelsBySubmittedUserBySubmittedAtQuery }>;
    }

    async AllLanguageLabelsBySubmittedUserBySubmittedAt(
        submitteduserid: string,
        fromDate: string,
        toDate: string,
        limit: number = 999,
    ): Promise<LanguageLabel[]> {

        let initialized: boolean = false
        let nextToken: string = null
        let results: LanguageLabel[] = []

        while (initialized == false || nextToken != null) {
            initialized = true
            let result = await this.LanguageLabelsBySubmittedUserBySubmittedAt(
                submitteduserid, fromDate, toDate, limit, nextToken)

            results.push(...result.data.LanguageLabelsBySubmittedUserBySubmittedAt.items)
            nextToken = result.data.LanguageLabelsBySubmittedUserBySubmittedAt.nextToken
        }
        return results
    }

    async listLanguageLabelsByGoldTranscriptIdByStarttime(goldtranscriptid: string, limit: number = 999, nextToken: string = null): Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsByGoldTranscriptByStarttime, {
                goldtranscriptID: goldtranscriptid,
                limit: limit,
                nextToken: nextToken,
            })
        ) as Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }>
    }

    async listAllLanguageLabelsByGoldTranscriptIdByStarttime(goldtranscriptid: string): Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsByGoldTranscriptByStarttime, {
                goldtranscriptID: goldtranscriptid,
                limit: 999,
            })
        ) as Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }>
    }

    async get(id: string): Promise<{ data: GetLanguageLabelQuery }> {
        return API.graphql(graphqlOperation(queries.getLanguageLabel, { id: id })) as Promise<{ data: GetLanguageLabelQuery }>
    }

    async updateContent(id: string, content: string, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            content: content,
            _version: _version,
        }
        //console.log(input)
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async updateSpeaker(id: string, speakerID: string, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            speakerID: speakerID,
            _version: _version,
        }
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async updateValidationStatus(id: string, validationStatus: ValidationStatus, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            validationStatus: validationStatus,
            _version: _version,
        }
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async getLanguageLabelsBySubmittedUserBySubmittedAt(userID: string, startDate?: string, endDate?: string, sortDirection?: ModelSortDirection, limit?: number, nexttoken?: string): Promise<{ data: LanguageLabelsBySubmittedUserBySubmittedAtQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsBySubmittedUserBySubmittedAt, {
                submitteduserID: userID,
                // submittedAt: {
                //   between: [startDate, endDate],
                // },
                sortDirection: sortDirection,
                limit: limit != null ? limit : 100,
                nextToken: nexttoken,
            })
        ) as Promise<{ data: LanguageLabelsBySubmittedUserBySubmittedAtQuery }>
    }
}
