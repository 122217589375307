import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export enum PiaMediaEventSource {
    PiaApplication,
    PiaSurfer,
    PiaVideo,
    PiaAutoTranscript,
    PiaGoldTranscript,
    PiaCustomTranscript,
    PiaLanguageLabel,
}

@Injectable({
    providedIn: 'root',
})
export class PiaMediaEventsService {
    public sessionPlay: Subject<{ source: PiaMediaEventSource; sessionId: string; position?: number }> = new Subject()
    public sessionStop: Subject<{ source: PiaMediaEventSource; sessionId: string; position?: number }> = new Subject()
    public sessionPause: Subject<{ source: PiaMediaEventSource; sessionId: string; position?: number }> = new Subject()
    public sessionIsPlaying: Subject<{ source: PiaMediaEventSource; sessionId: string; playing: boolean }> = new Subject()
    public globalPlayPause: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()
    public globalScrubLeft: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()
    public globalScrubRight: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()
    public globalStartOfLabel: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()
    public globalEndOfLabel: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()
    public globalFaster: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()
    public globalSlower: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()
    public insertEh: Subject<{ source: PiaMediaEventSource; sessionId: string }> = new Subject()

    constructor() {}

    globalPlayPauseRemoveMe() {
        //console.log('globalPlayPause')
        // this.sessionIsPlaying.subscribe(
        //     (value) => {
        //         if (value.playing.valueOf()) {
        //             //console.log('session is playing')
        //             this.sessionPause.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
        //         } else {
        //             //console.log('session is paused or stopped')
        //             this.sessionPlay.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
        //         }
        //     },
        //     () => {},
        //     () => {
        //         this.sessionIsPlaying.unsubscribe()
        //         //console.log('directly unsubscribed')
        //     }
        // )
    }
}
