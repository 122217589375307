import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { AlertController, IonModal, SelectChangeEventDetail } from '@ionic/angular'
import { Language, ModelSortDirection } from './../../../API.service'
import { Pia3LanguageService } from '../pia3-language.service'
import { OverlayEventDetail } from '@ionic/core/components'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'

// interface SelectCustomEvent<T = any> extends CustomEvent {
//   detail: SelectChangeEventDetail<Language>;
//   target: HTMLIonSelectElement;
// }

interface ICheckboxLanguage {
    isLanguageChecked: boolean
    language: Language
}

@Component({
    selector: 'pia3-language-selector',
    templateUrl: './pia3-language-selector.component.html',
    styleUrls: ['./pia3-language-selector.component.scss'],
})
export class Pia3LanguageSelectorComponent implements OnInit {
    public languages: Language[]
    @Input('selectorMode') selectorMode: string = SelectorMode.SINGLE
    @Output('languageSelected') languageSelected = new EventEmitter<Language[]>()

    //Modal page
    @ViewChild(IonModal) modal: IonModal
    message = ''
    name: string

    //Searchbar
    indeterminateState: boolean | undefined
    checkParent: boolean | undefined
    public checkboxLanguages: ICheckboxLanguage[] = []
    public resultsOfSearch: ICheckboxLanguage[] = []

    //show languages
    showLanguages: Language[] = null
    showLanguagesWithSearch: Language[] = null

    constructor(private pia3LanguageService: Pia3LanguageService, private alertController: AlertController) {}

    async ngOnInit() {
        this.languages = (await this.getLanguages()).data.listLanguages.items as Language[]

        //create new languages array with isLanguageChecked property for searchbar
        this.languages.forEach((language) => {
            this.checkboxLanguages.push({
                isLanguageChecked: false,
                language: language,
            })
        })
        //copy entire created checkboxLanguages to resultsOfSearch to protect first instances
        this.resultsOfSearch = [...this.checkboxLanguages]
    }

    //when language changed emit current lang. - this is for single mode
    languageChanged($event) {
        if (this.selectorMode === SelectorMode.SINGLE) {
            let selectedLanguage: Language[] = $event.detail.value as Language[] // $event is coming as an Event here
            this.languageSelected.emit(selectedLanguage)
        }

        if (this.selectorMode === SelectorMode.MULTI) {
            let selectedLanguages: Language[] = $event //$event is coming as an Language[] array here. Coming from confirm()
            this.languageSelected.emit(selectedLanguages)
            //show selected languages
            this.showLanguages = selectedLanguages
        }

        if (this.selectorMode === SelectorMode.MULTI_WITH_SEARCH) {
            let selectedLanguages: Language[] = $event //$event is coming as an Language[] array here. Coming from confirm()
            this.languageSelected.emit(selectedLanguages)
            //show selected languages
            this.showLanguagesWithSearch = selectedLanguages
        }
    }

    //get languages from server
    async getLanguages(nexttoken: string = null) {
        let result = this.pia3LanguageService.getLanguages(undefined, ModelSortDirection.ASC, 999, nexttoken)
        return result
    }

    //remove selection multi
    removeSelectionMulti(index: number) {
        if (this.showLanguages.length > 0) {
            this.showLanguages.splice(index, 1)
        }

        if (this.showLanguages.length == 0) {
            this.showLanguages = null
        }
        this.languageChanged(this.showLanguages)
    }

    //remove selection multi with search
    removeSelectionMultiWithSearch(index: number) {
        if (this.showLanguagesWithSearch.length > 0) {
            this.showLanguagesWithSearch.splice(index, 1)
        }

        if (this.showLanguagesWithSearch.length == 0) {
            this.showLanguagesWithSearch = null
        }
        this.languageChanged(this.showLanguagesWithSearch)
    }

    /*Modal page */
    cancel() {
        this.modal.dismiss(null, 'cancel')
    }

    confirm() {
        this.modal.dismiss(this.name, 'confirm')

        const checkedLanguages: Language[] = []

        this.resultsOfSearch.forEach((checkboxLanguages) => {
            checkboxLanguages.isLanguageChecked ? checkedLanguages.push(checkboxLanguages.language) : ''
        })

        if (checkedLanguages.length > 0) {
            this.languageChanged(checkedLanguages)
        }
        if (checkedLanguages.length == 0) {
            this.presentNoCheckedItemAlert()
            this.showLanguages = null
            this.showLanguagesWithSearch = null
            this.languageChanged(null)
        }
    }

    onWillDismiss(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
    }

    //searchbar
    handleChange($event) {
        const query = $event.detail.value.toLowerCase()
        this.resultsOfSearch = this.checkboxLanguages.filter((d) => d.language.name.toLowerCase().indexOf(query) > -1)
    }

    /*checkbox*/
    verifyEvent() {
        const allItems = this.resultsOfSearch.length
        let selected = 0
        this.resultsOfSearch.map((language: { isLanguageChecked: any }) => {
            if (language.isLanguageChecked) selected++
        })
        if (selected > 0 && selected < allItems) {
            // One item is selected among all checkbox elements
            this.indeterminateState = true
            this.checkParent = false
        } else if (selected == allItems) {
            // All item selected
            this.checkParent = true
            this.indeterminateState = false
        } else {
            // No item is selected
            this.indeterminateState = false
            this.checkParent = false
        }
    }

    selectAllLanguages() {
        setTimeout(() => {
            this.resultsOfSearch.forEach((language: { isLanguageChecked: boolean | undefined }) => {
                language.isLanguageChecked = this.checkParent
            })
        })
    }

    /*Alerts*/
    //alert-no checked item
    async presentNoCheckedItemAlert() {
        const alert = await this.alertController.create({
            header: 'No checked language',
            // subHeader: 'No results were found in the selected date range!',
            message: 'Please check one ore more language.',
            buttons: ['OK'],
        })

        await alert.present()
    }
}
