import { Injectable } from '@angular/core'

export enum Pia3ThemeColor {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
    Medium = 'medium',
    Light = 'light',
}

export enum Pia3ThemeColorValue {
    Primary = '#5a00e1',
    Secondary = '#350084',
    Tertiary = '#2af9c8',
    Success = '#1cd93c',
    Warning = '#ffc409',
    Danger = '#eb445a',
    Medium = '#92949c',
    Light = '#f4f5f8',
}

export enum Pia3ThemePositionHorizontal {
    Start = 'start',
    Center = 'center',
    End = 'end',
}

export enum Pia3ThemePositionVertical {
    Top = 'top',
    Center = 'center',
    Bottom = 'bottom',
}

@Injectable({
    providedIn: 'root',
})
export class Pia3ThemeService {
    private undefinedThemeColorValue: string = 'red'
    private documentStyles: CSSStyleDeclaration = null

    constructor() {
        this.documentStyles = getComputedStyle(document.documentElement)
    }

    private getThemeColorValueByNameString(themeColorNameString: string) {
        try {
            var styles = getComputedStyle(document.documentElement)
            var value = String(styles.getPropertyValue('--ion-color-' + themeColorNameString)).trim()
            return value
        } catch (error) {
            //console.log(error)

            return this.undefinedThemeColorValue
        }
    }

    public getThemeColorValue(themeColor: Pia3ThemeColor): string {
        return this.getThemeColorValueByNameString(themeColor.toString())
    }

    public getThemeColorRGBValue(themeColor: Pia3ThemeColor): string {
        return this.getThemeColorValueByNameString(themeColor.toString() + '-rgb')
    }

    public getThemeColorContrastValue(themeColor: Pia3ThemeColor) {
        return this.getThemeColorValueByNameString(themeColor.toString() + '-contrast')
    }

    public getThemeColorContrastRGBValue(themeColor: Pia3ThemeColor): string {
        return this.getThemeColorValueByNameString(themeColor.toString() + '-contrast')
    }

    public getThemeColorTintValue(themeColor: Pia3ThemeColor): string {
        return this.getThemeColorValueByNameString(themeColor.toString() + '-tint')
    }

    public getThemeColorShadeValue(themeColor: Pia3ThemeColor): string {
        return this.getThemeColorValueByNameString(themeColor.toString() + '-shade')
    }
}
