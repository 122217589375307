import { Injectable } from '@angular/core'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import {
    queries,
    mutations,
    PipelineDefinition,
    GetPipelineDefinitionQuery,
    GetStepConnectionQuery,
    GetStepTypeQuery,
    StepType,
    ListContextchannelsQuery,
    GetStepDefinitionQuery,
    StepDefinition,
    GetPipelineOutputQuery,
    PipelineOutput,
    GetPipelineExecutionQuery,
    PipelineExecution,
    ListPipelineDefinitionsQuery,
    ListPipelineOutputDefinitionsQuery,
    ListPipelineOutputsQuery,
    GetPipelineOutputDefinitionQuery,
    PipelineOutputDefinition,
    PipelineOutputsByContextChannelQuery,
    ModelPipelineOutputFilterInput,
    PipelineDefinitionsByContextChannelQueryVariables,
    PipelineDefinitionsByContextChannelQuery,
} from '@humain-r/pia3-codegen'
import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'

@Injectable({
    providedIn: 'root',
})
export class Pia3PipelineService {
    constructor(private errorHandlerService: ErrorhandlingService) {}

    async getPipelineDefinition(pipelineId: string): Promise<PipelineDefinition> {
        const result = API.graphql(graphqlOperation(queries.getPipelineDefinition, { id: pipelineId })) as Promise<{ data: GetPipelineDefinitionQuery }>

        return (await result).data.getPipelineDefinition as PipelineDefinition
    }

    async getStep(stepId: string): Promise<StepDefinition> {
        const result = API.graphql(graphqlOperation(queries.getStepDefinition, { id: stepId })) as Promise<{ data: GetStepDefinitionQuery }>
        return (await result).data.getStepDefinition as StepDefinition
    }

    async getStepType(stepId: string): Promise<StepType> {
        const result = API.graphql(graphqlOperation(queries.getStepType, { id: stepId })) as Promise<{ data: GetStepTypeQuery }>
        return (await result).data.getStepType as StepType
    }

    async getContextChannelPipelines(contextChannelId: string) : Promise<PipelineDefinition[]>
    { 
        const result = API.graphql(graphqlOperation(queries.pipelineDefinitionsByContextChannel, { contextchannelID: contextChannelId })) as Promise<{ data: PipelineDefinitionsByContextChannelQuery }>
        return (await result).data.PipelineDefinitionsByContextChannel.items as PipelineDefinition[]
    }

    async getPipelineOutputDefintion(pipelineOutputId: string): Promise<PipelineOutputDefinition> {
        const result = (await API.graphql(graphqlOperation(queries.getPipelineOutputDefinition, { id: pipelineOutputId }))) as Promise<{ data: GetPipelineOutputDefinitionQuery }>
        return (await result).data.getPipelineOutputDefinition as PipelineOutputDefinition
    }

    async getPipelineOutput(pipelineOutputId: string): Promise<PipelineOutput> {
        const result = API.graphql(graphqlOperation(queries.getPipelineOutput, { id: pipelineOutputId })) as Promise<{ data: GetPipelineOutputQuery }>
        return (await result).data.getPipelineOutput as PipelineOutput
    }

    async getPipelineExecution(excetutionId: string): Promise<PipelineExecution> {
        const result = API.graphql(graphqlOperation(queries.getPipelineExecution, { id: excetutionId })) as Promise<{ data: GetPipelineExecutionQuery }>
        return (await result).data.getPipelineExecution as PipelineExecution
    }

    async getPipelineOutputsByContextChannelId(contextChannelId: string, filter?: ModelPipelineOutputFilterInput): Promise<PipelineOutput[]> {
        const result = API.graphql(graphqlOperation(queries.pipelineOutputsByContextChannel, { contextchannelID: contextChannelId, filter: filter })) as Promise<{ data: PipelineOutputsByContextChannelQuery }>
        return (await result).data.PipelineOutputsByContextChannel.items as PipelineOutput[]
    }

    async getPipelineOutputsByContextChannelIdAndPipelineId(contextChannelId: string, pipeLineId: string): Promise<PipelineOutput[]> {
        const result = (await API.graphql(graphqlOperation(queries.pipelineOutputsByContextChannel, { contextchannelID: contextChannelId }))) as Promise<{ data: PipelineOutputsByContextChannelQuery }>

        const filteredResults = (await result).data.PipelineOutputsByContextChannel.items.filter((o) => {
            return o.PipelineExecution.pipelineID == pipeLineId
        })

        return filteredResults
    }
}
