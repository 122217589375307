import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { PipesModule } from 'src/app/.common/pipes/pipes.module'
import { PiaSpeakerCardComponent } from 'src/app/.common/components/pia-speaker/pia-speaker-card/pia-speaker-card.component'
import { PiaSpeakerFormComponent } from 'src/app/.common/components/pia-speaker/pia-speaker-form/pia-speaker-form.component'
import { PiaSpeakerListComponent } from 'src/app/.common/components/pia-speaker/pia-speaker-list/pia-speaker-list.component'
import { Pia3Module } from 'pia3'

@NgModule({
    declarations: [PiaSpeakerCardComponent, PiaSpeakerFormComponent, PiaSpeakerListComponent],
    imports: [CommonModule, IonicModule, FormsModule, PipesModule, Pia3Module],
    exports: [PiaSpeakerCardComponent, PiaSpeakerFormComponent, PiaSpeakerListComponent],
})
export class PiaSpeakerModule {}
