import { GPTCompletionInput } from './../../../API.service'
import { Pia3TextconverterService } from './../pia3-textconverter.service'
import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'pia3-textconverter',
    templateUrl: './pia3-textconverter.component.html',
    styleUrls: ['./pia3-textconverter.component.scss'],
})
export class Pia3TextconverterComponent implements OnInit {
    text: string = ''
    output: string = ''
    outputColor: string = 'medium'

    input: GPTCompletionInput = {
        instruction: 'Je ontvangt een tekst die bestaat uit paragrafen van verschillende bronnen. Herschrijf deze tekst op een natuurlijke wijze.',
        input: this.text,
        temperature: 0,
    }

    constructor(private pia3gptComplation: Pia3TextconverterService) {}

    ngOnInit() {}

    convert() {
        this.input = {
            instruction: 'Je ontvangt een tekst die bestaat uit paragrafen van verschillende bronnen. Herschrijf deze tekst op een natuurlijke wijze.',
            input: this.text,
            temperature: 0,
        }
        this.pia3gptComplation.getGptCompletion(this.input).then((result) => {
            try {
                // //////console.log('Input :', this.text);
                // //////console.log('Output :', result.data.gptCompletion);
                this.output = result.data.gptCompletion
                this.outputColor = 'light'
            } catch (error) {}
        })
    }
}
