import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Language } from './../../../API.service'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'

@Component({
    selector: 'pia3-language-selector-single',
    templateUrl: './pia3-language-selector-single.component.html',
    styleUrls: ['./pia3-language-selector-single.component.scss'],
})
export class Pia3LanguageSelectorSingleComponent implements OnInit {
    selectorMode: string = SelectorMode.SINGLE
    @Output('languageSelected') languageSelected = new EventEmitter<Language>()
    constructor() {}

    ngOnInit() {}

    _languageSelected($event) {
        this.languageSelected.emit($event)
    }
}
