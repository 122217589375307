import { createWordTaggerModuleOutput } from './../../graphql/mutations'
import { Contextchannel, Session, CreateWordTaggerModuleOutputInput, CreateWordTaggerModuleOutputMutation, WordTaggerModuleOutput, GetWordTaggerModuleOutputQuery, ListWordTaggerModuleOutputsQuery, ModelSessionFilterInput } from './../../API.service'
import { Injectable } from '@angular/core'
// import {
//   ICategoryWithId,
//   IWordTagger,
//   IWords,
// } from './pia3-word-tagger-interfaces';
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as mutations from './../../graphql/mutations'
import * as queries from './../../graphql/queries'

export interface IWordTagger {
    text: string
    tag: string
}

export interface IWords {
    id: number
    word: string
    isWordSelected: boolean
    hasCloseIcon: boolean
    isHiddenCloseIcon: boolean
    isCatagoryNameActiveOnSelectedWord: boolean
    belongsToWhichGroup: number
    categoryName: string
}

export interface ICategoryWithId {
    id: number
    category: string
    buttonFillSolid: boolean
}

@Injectable({
    providedIn: 'root',
})
export class Pia3WordTaggerService {
    constructor() {}

    /**
     *
     * @param contextChannel
     * @returns Promise<string[]>
     */
    public async getConfiguredCategories(contextChannel: Contextchannel): Promise<string[]> {
        return contextChannel.wordTaggerModuleSettings.categories as string[]
    }

    /**
     *
     * @param session
     * @param taggedWords
     * @param categories
     * @returns Promise<{ data: CreateWordTaggerModuleOutputMutation }>
     */
    public async createWordTaggerModuleOutput(session: Session, taggedWords: IWordTagger[], categories: string[]): Promise<{ data: CreateWordTaggerModuleOutputMutation }> {
        const input: CreateWordTaggerModuleOutputInput = {
            clientID: session.clientID,
            sessionID: session.id,
            taggedWords: taggedWords,
            categories: categories,
        }
        const query = await (API.graphql<WordTaggerModuleOutput>(graphqlOperation(mutations.createWordTaggerModuleOutput, { input: input })) as Promise<{ data: CreateWordTaggerModuleOutputMutation }>)
        return query
    }

    /**
     *
     * @param id
     * @returns Promise<{ data: GetWordTaggerModuleOutputQuery }>
     */
    public async getWordTaggerModuleOutput(id: string): Promise<{ data: GetWordTaggerModuleOutputQuery }> {
        return (await API.graphql<WordTaggerModuleOutput>(graphqlOperation(queries.getWordTaggerModuleOutput, { id: id }))) as Promise<{ data: GetWordTaggerModuleOutputQuery }>
    }

    /**
     *
     * @param filter
     * @param limit
     * @param nexttoken
     * @returns Promise<{ data: ListWordTaggerModuleOutputsQuery }>
     */
    public async listWordTaggerModuleOutput(filter?: ModelSessionFilterInput, limit?: number, nexttoken?: string): Promise<{ data: ListWordTaggerModuleOutputsQuery }> {
        return (await API.graphql<WordTaggerModuleOutput>(
            graphqlOperation(queries.listWordTaggerModuleOutputs, {
                filter: filter,
                limit: limit != null ? limit : 100,
                nextToken: nexttoken,
            })
        )) as Promise<{ data: ListWordTaggerModuleOutputsQuery }>
    }

    // //
    // showWordTaggerModuleOutput(data: WordTaggerModuleOutput) {
    //   //#region category code
    //   let categories: string[] = [];
    //   let categoriesWithId: ICategoryWithId[] = []; //will return

    //   data.categories.forEach((category: string) => {
    //     categories.push(category);
    //   });

    //   let lengthOfCategories = categories.length;

    //   for (let i = 0; i < lengthOfCategories; i++) {
    //     let id = i + 1;
    //     let category = categories[i];
    //     let buttonFillSolid = false;
    //     if (category !== '' && category !== undefined) {
    //       categoriesWithId.push({ id, category, buttonFillSolid });
    //     }
    //   }
    //   //#endregion category code

    //   //#region preparation of wordsArray:IWords[]
    //   let taggedWordsWithId = []; // will return as wordsArray:IWords[]
    //   taggedWordsWithId = data.taggedWords.map((taggedWord, index) => ({
    //     id: index + 1,
    //     word: taggedWord.text,
    //     isWordSelected:
    //       taggedWord.tag.indexOf('I-') > -1 || taggedWord.tag.indexOf('B-') > -1,
    //     hasCloseIcon: taggedWord.tag.indexOf('B-') > -1,
    //     isHiddenCloseIcon: taggedWord.tag.indexOf('B-') > -1 ? false : true,
    //     belongsToWhichGroup: this.detectGroupId(
    //       data,
    //       taggedWord.tag,
    //       index + 1,
    //       taggedWord.tag.indexOf('I-') > -1 || taggedWord.tag.indexOf('B-') > -1
    //     ),
    //     categoryName:
    //       taggedWord.tag.indexOf('B-') > -1
    //         ? taggedWord.tag.substring(
    //             taggedWord.tag.indexOf('B-') + 2,
    //             taggedWord.tag.length
    //           )
    //         : taggedWord.tag.indexOf('I-') > -1
    //         ? taggedWord.tag.substring(
    //             taggedWord.tag.indexOf('I-') + 2,
    //             taggedWord.tag.length
    //           )
    //         : '',
    //   }));

    //   // console.warn(taggedWordsWithId);
    //   //#endregion preparation of wordsArray:IWords[]

    //   const wordsArray: IWords[] = [];
    //   wordsArray.push(...taggedWordsWithId);
    //   return [wordsArray, categoriesWithId];
    // }

    // private detectGroupId(
    //   data: WordTaggerModuleOutput,
    //   value: string,
    //   id: number,
    //   isWordSelected: boolean
    // ): number | undefined {
    //   if (value.length > 0 && isWordSelected) {
    //     if (value.indexOf('B-') > -1) {
    //       return id;
    //     }
    //     if (value.indexOf('I-') > -1) {
    //       let i = 0;
    //       while (!(data.taggedWords[id - i].tag.indexOf('B-') > -1)) {
    //         i++;
    //         if (i === 0) break;
    //       }
    //       return id - i + 1;
    //     }
    //   }
    //   return undefined;
    // }
}
