import { Component, OnInit, Input, SimpleChanges } from '@angular/core'
import { Pia3ColumnChartOptions } from '../pia3-columnchart/pia3-columnchart-options.model'
import 'anychart'

@Component({
  selector: 'pia3-charting-stacked-columnchart',
  templateUrl: './pia3-stackedcolumnchart.component.html',
  styleUrls: ['./pia3-stackedcolumnchart.component.scss'],
})

export class Pia3StackedcolumnchartComponent implements OnInit {

  private _chart!: anychart.charts.Cartesian
  private _chartTitle!: anychart.core.ui.Title

  @Input() public chartSeries: Map<string, any>
  @Input() public chartHeight: number = 500
  @Input() public chartOptions: Pia3ColumnChartOptions = new Pia3ColumnChartOptions()

  constructor() { }

  ngOnInit() {
    this.createChart()
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == "chartSeries") {
        this.updateChart(this.chartSeries)
        this.updateChartOptions(this.chartOptions)
      }

      if (propName == "chartOptions") {
        this.updateChartOptions(this.chartOptions)
      }
    }
  }

  createChart() {
    //Declaration
    this._chart = anychart.column()

    //Styling
    this._chart.animation(true)
    this._chart.barGroupsPadding(1)
    this._chart.padding([10, 40, 5, 20])

    //Title Element
    this._chartTitle = this._chart.title()
    this._chartTitle.text("Chart")
    this._chartTitle.enabled(true)

    //Stacked Mode
    this._chart.yScale().minimum(0)
    this._chart.yScale().stackMode('value')

    //Update to actual provided information
    this.updateChart(this.chartSeries)
    this.updateChartOptions(this.chartOptions)

    //Actually make the chart
    this._chart.container("ChartLibContainer")
    this._chart.draw()
  }

  updateChart(chartSeries: Map<string, any>) {
    if (!this._chart || !this.chartSeries) {
      return
    }

    this._chart.removeAllSeries()

    chartSeries.forEach((data: any, key: string) => {
      let series = this._chart.column(data)
      series.name(key)
    })
  }

  updateChartOptions(options: Pia3ColumnChartOptions) {
    if (!this._chartTitle) {
      return
    }

    this._chartTitle.text(options.title)

    if (this.chartOptions.xAxis != "") {
      this._chart.xAxis().title(options.xAxis)
    }

    if (this.chartOptions.yAxis != "") {
      this._chart.yAxis().title(options.yAxis)
    }

  }
}
