import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import Amplify from 'aws-amplify'
import { Auth } from '@aws-amplify/auth'
import { API } from '@aws-amplify/api'
import { Storage } from '@aws-amplify/storage'
import { awsmobile } from '@humain-r/pia3-codegen'
import { DataStore, AuthModeStrategyType } from '@aws-amplify/datastore'

Amplify.configure(awsmobile)
Storage.configure(awsmobile)
Auth.configure(awsmobile)
API.configure(awsmobile)

if (environment.production) {
    enableProdMode()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log('Error: ', err))
