<div *ngIf="!mutationMode">
  <ion-title *ngIf="!editMode">{{titleText}}
    <button *ngIf="allowedToEdit" (click)="editButtonclick($event)">
      <ion-icon size="medium" name="create-outline"></ion-icon>
    </button>
  </ion-title>

  <ion-input *ngIf="editMode" [(ngModel)]="titleText" (ionBlur)="titleEdited($event)"
    (keydown.enter)="onEnter($event)"></ion-input>

</div>

<div *ngIf="mutationMode">
  <ion-spinner></ion-spinner>
  <ion-label>Even Wachten A.U.B.</ion-label>
</div>