import { Injectable } from '@angular/core'
import { Pia3Service } from '../../pia3.service'
import { Video } from './../../API.service'
import { BehaviorSubject } from 'rxjs'

export interface IPia3VideoPlayer {
    playerElement: HTMLElement
    isPlaying: boolean
    isLoading: boolean
    hasError: boolean
    video: Video
}

export enum Videocontrolcommand {
    PLAY,
    PAUSE,
    STOP,
    SEEK,
    FULLSCREEN,
}

export interface IPia3Videocontrolcommand {
    sender: any
    controlID: string
    command: Videocontrolcommand
    value: any
}

@Injectable({
    providedIn: 'root',
})
export class Pia3VideoService {
    public videoControl$: BehaviorSubject<IPia3Videocontrolcommand> = new BehaviorSubject<IPia3Videocontrolcommand>({
        sender: null,
        controlID: null,
        command: null,
        value: null,
    })

    constructor(public pia3Service: Pia3Service) {
        this.videoControl$.subscribe(this.onVideoControlMessage)
    }

    //#region Service Methods
    public test() {
        return 'test'
    }

    public convertTimestringToSeconds(timestring: string): number {
        //format timestring: 00:02:34.68
        if (timestring.toString().indexOf(':') != -1) {
            let parts = timestring.split(':')
            let h = Number(parts[0])
            let m = Number(parts[1])
            let s = Number(parts[2])
            let secondsTotal = h * 3600 + m * 60 + s

            return secondsTotal
        } else {
            return Number(timestring)
        }
    }

    public async getVideoUrl(key: string, expirationTime: number = 1000) {
        return await this.pia3Service.storageGetS3Url(key.replace('public/', ''))
    }

    public async getSubtitlesUrl(key: string, expirationTime: number = 1000) {
        return await this.pia3Service.storageGetS3Url(key.replace('public/', ''))
    }

    public isMe(controlID: string, command: IPia3Videocontrolcommand) {
        return controlID == command.controlID
    }

    //#endregion

    //#region VideoControl Events
    public onVideoControlMessage = (command: IPia3Videocontrolcommand) => {
        if (command.sender == this) {
            //console.log('Sessionpage send a videocontrolmessage:', command)
        } else {
            //console.log('Sessionpage received a videocontrolmessage:', command)
        }
    }
    //#endregion
}
