import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'

import {
    queries,mutations,subscriptions,Contextchannel,
    Session,
    CreateSessionInput,
    CreateSessionMutation,
    ExportSessionQuery,
    ExportFormat,
    GetSessionQuery,
    ListSessionsQuery,
    SessionsByContextchannelByCreatedAtQuery,
    SearchableStringFilterInput,
    SearchableSessionFilterInput,
    SearchSessionsQuery,
    UpdateSessionMutation,
    OnCreateSessionSubscription,
    OnUpdateSessionSubscription,
    OnDeleteSessionSubscription,
    SearchableSessionSortableFields,
    ModelSortDirection,
    ValidationProgressStatus,
    ModelSessionFilterInput,
    Language,
    UpdateContextchannelInput,
    UpdateContextchannelMutation,
    UpdateSessionInput,
    ListUserIDsInCognitoGroupQuery,
    UpdateAccessGroupsForUserMutation,
    UpdateAccessGroupsForUserInput,
} from '@humain-r/pia3-codegen'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'

import { Pia3Service } from '../../pia3.service'
import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'


@Injectable({
    providedIn: 'root',
})
export class Pia3CognitoService {        
    constructor(private errorHandlerService: ErrorhandlingService, private pia3Service: Pia3Service) { }

    public async listCognitoUsersInGroup(group: string) : Promise<string[]>
    {
        const userIds = await API.graphql<string>(
            graphqlOperation(queries.listUserIDsInCognitoGroup, {
                cognitoGroup: group
            })
        ) as Promise<{ data: ListUserIDsInCognitoGroupQuery }>

        return (await userIds).data.listUserIDsInCognitoGroup 
    }

    public async updateUserCognitoGroups(userId: string, addToGroups: string[], removeFromGroups: string[])
    {
        var updateData : UpdateAccessGroupsForUserInput = {
            userID: userId,
            addToGroups: addToGroups,
            removeFromGroups: removeFromGroups
        }
        
        const mutation = await API.graphql<string>(
            graphqlOperation(mutations.updateAccessGroupsForUser, {
                input: updateData
            })
        ) as Promise<{ data: UpdateAccessGroupsForUserMutation }>

        return mutation
    }

    
    public async addUsersToCognitoGroup(userId: string, groupId: string)
    {
        return this.updateUserCognitoGroups(userId, [groupId], [])
    }
    
    public async removeUsersFromCognitoGroup(userId: string, groupId: string)
    {
        return this.updateUserCognitoGroups(userId, [], [groupId])
    }
}   
