<div *ngIf="!isReady">
</div>
<div *ngIf="isReady">
    <form [formGroup]="ctxcForm" (ngSubmit)="submit()">        
        <ion-list>
            <ion-item lines="full">
                <ion-label position="fixed">* Naam</ion-label>
                <ion-input placeholder="Voer een naam in..." color="primary" type="text" maxlength="30" minlength="2"
                    clearInput="true" formControlName="name"></ion-input>
            </ion-item>

            <ion-item lines="full">
                <ion-label position="fixed">Omschrijving</ion-label>
                <ion-input placeholder="Voer een omschrijving in..." color="primary" type="text" maxlength="145"
                    minlength="" clearInput="true" formControlName="description"></ion-input>
            </ion-item>

            <ion-item lines="full">
                <ion-label position="fixed">* Afbeelding</ion-label>

                <pia3-image-cropper *ngIf="newImage" (imageSelectedPath)="imageSelected($event)"></pia3-image-cropper>

                <ion-img *ngIf="!newImage" (click)="changeImage()" [src]="this.originalImagePath"></ion-img>
            </ion-item>
            <ion-item *ngIf="invalidImage" color="danger">
                <ion-label>Selecteer een plaatje.</ion-label>
            </ion-item>

            <ion-item lines="full" *ngIf="!oneClient">
                <ion-label position="fixed">* Klant</ion-label>

                <pia3-client-selector-single *ngIf="newClient"
                    (clientSelected)="clientSelected($event)"></pia3-client-selector-single>

                <ion-label class="originalClient" *ngIf="!newClient"
                    (click)="changeClient()">{{this.client.displayName}}</ion-label>
            </ion-item>
            <ion-item *ngIf="invalidClient" color="danger">
                <ion-label>Kies een klant.</ion-label>
            </ion-item>

            <ion-item *ngIf="!updateMode" lines="full">
                <ion-label position="fixed">* Taal</ion-label>
                <ion-select formControlName="language" interface="popover" placeholder="Selecteer een Taal"
                    errorText="Kies een taal.">
                    <ion-select-option value="pipelinetemplate_nld">Nederlands</ion-select-option>
                    <ion-select-option value="pipelinetemplate_fry">Fries & Nederlands </ion-select-option>
                </ion-select>
            </ion-item>
        </ion-list>

        <div class="ion-margin">
            <ion-row>
                <ion-col>
                    <ion-text>
                        * Dit is een verplicht veld.
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-button [disabled]='!ctxcForm.valid || !this.client || !this.imagePath  || this.imagePath == ""'
                        class="ion-margin" expand="block" type="submit">verzenden</ion-button>
                </ion-col>
            </ion-row>
        </div>
    </form>
</div>