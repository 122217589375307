import { Component, EventEmitter, Input, Output, OnInit, SimpleChange, ViewChild } from '@angular/core'
import { IonInfiniteScroll } from '@ionic/angular'
import { GoldTranscript, LanguageLabel } from 'src/app/API.service'
import { PiaTranscriptGoldService } from './pia-transcript-gold-service.service'
import { LanguageLabelService } from 'src/app/.common/services/languagelabel.service'
import { SpeakerService } from 'src/app/.common/services/speaker.service'
import { GenericErrorHandlerService } from 'src/app/.common/services/generic-error-handler.service'
import { PiaTranscriptLanguagelabelComponent } from './../pia-transcript-languagelabel/pia-transcript-languagelabel.component'
import { Pia3AuthenticationService, Pia3LocalStorageService } from 'pia3'
import { Speaker } from 'src/app/API.service'
import { ContextchannelService } from 'src/app/contextchannels/contextchannel.service'
import { PlaybackCommand } from '../../pia-video/pia-video.component'
import { label } from 'aws-amplify'

@Component({
    selector: 'app-pia-transcript-golden',
    templateUrl: './pia-transcript-golden.component.html',
    styleUrls: ['./pia-transcript-golden.component.scss'],
})
export class PiaTranscriptGoldenComponent implements OnInit {
    @Input() piaTranscriptGoldId: string = null
    @Output() languageLabelClickedEvent = new EventEmitter<LanguageLabel>(true)
    @Output() languageLabelPlaybackEventEmitter = new EventEmitter<PlaybackCommand>(true)

    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll

    public piaTranscriptGoldIsLoading: boolean = false
    public piaTranscriptGold: GoldTranscript = null
    public piaCurrentLanguageLabelID: string = null
    public pia3CurrentUserID: string = null
    public piaLanguageLabelsLoading: boolean = false
    public piaLanguageLabelsLoadingComplete: boolean = false
    public piaLanguageLabels: LanguageLabel[] = []
    public piaLanguageLabelsNextToken: string = null
    public piaLanguageLabelsCompiled: string = ''
    public piaSpeakersIsLoading: boolean = false
    public piaSpeakers: Speaker[] = new Array<Speaker>()
    public piaDialog: Array<{ speaker: Speaker; content: string }> = []
    public piaDialogText: string = ''

    private labelBookmark: string = ''
    public foundBookMark: boolean = false
    public canLoadBookmark: boolean = false

    constructor(
        private pia3AuthenticationService: Pia3AuthenticationService,
        private piaTranscriptGoldService: PiaTranscriptGoldService,
        private piaSpeakersService: SpeakerService,
        private languagelabelService: LanguageLabelService,
        private errorHandlerService: GenericErrorHandlerService,
        private contextchannelService: ContextchannelService,
        private localStorageService: Pia3LocalStorageService
    ) {
        this.pia3AuthenticationService.currentLoggedInUser.subscribe((user) => {
            if (user != null) {
                this.pia3CurrentUserID = user['attributes']['sub']
            }
        })
    }

    ngOnInit() {}

    ngOnChanges(change: { prop: SimpleChange }) {
        if (change['piaTranscriptGoldId'] != null) {
            let value: SimpleChange = change['piaTranscriptGoldId']
            if (value.currentValue != null && value.currentValue != '' && value.currentValue != value.previousValue) {
                this.getTranscriptGold(value.currentValue)
                this.getLanguageLabels(value.currentValue, this.piaLanguageLabelsNextToken)
            }
        }
        // if (change['piaCurrentLanguageLabelID'] != null) {
        //   let value: SimpleChange = change['piaCurrentLanguageLabelID'];
        //   if (
        //     value.currentValue != null &&
        //     value.currentValue != '' &&
        //     value.currentValue != value.previousValue
        //   ) {
        //     alert('current label id changed:'+value.currentValue)
        //   }
        // }
    }

    async getTranscriptGold(id: string) {
        this.piaTranscriptGoldIsLoading = true
        this.piaTranscriptGoldService.getTranscriptGold(id).then(
            (result) => {
                this.piaTranscriptGold = result.data.getGoldTranscript as GoldTranscript
                this.piaTranscriptGoldIsLoading = false
                this.getContextSpeakers(this.piaTranscriptGold.Session.contextchannelID)
            },
            (reject) => {}
        )
    }

    async getContextSpeakers(contextchannelID: string) {
        this.piaSpeakersIsLoading = true
        this.piaSpeakersService
            .getContextchannelSpeakersPromiseAll(contextchannelID)
            .then(
                (result) => {
                    this.piaSpeakers = result
                        .map((o) => {
                            return o.data.getSpeaker
                        })
                        .sort((a, b) => {})
                },
                (reject) => {}
            )
            .catch((error) => {})
    }

    async getLanguageLabels(goldtranscriptID: string, nextToken: string, limit?: number) {
        ////console.log('getting lang labels', nextToken)
        if (limit == null) {
            limit = 30
        }
        this.piaLanguageLabelsLoading = true
        try {
            let result = await this.languagelabelService.listLanguageLabelsByGoldTranscriptIdByStarttime(goldtranscriptID, limit, this.piaLanguageLabelsNextToken)
            this.piaLanguageLabels.push(...(result.data.LanguageLabelsByGoldTranscriptByStarttime.items as LanguageLabel[]))
            this.piaLanguageLabelsNextToken = result.data.LanguageLabelsByGoldTranscriptByStarttime.nextToken
            this.piaLanguageLabelsLoading = false
            this.piaLanguageLabelsLoadingComplete = result.data.LanguageLabelsByGoldTranscriptByStarttime.nextToken == null
        } catch (error) {
            this.piaLanguageLabelsLoading = false
            console.error(error)
            this.errorHandlerService.handleGenericGraphQLError(error)
        }

        let sessionBookmark = this.localStorageService.getItem('sessionBookmark')
        let sessionLabelBookmark = this.localStorageService.getItem('sessionLabelBookmark')
        if (sessionBookmark != null && sessionBookmark != undefined && (sessionLabelBookmark != null) != undefined && sessionLabelBookmark != undefined && this.piaLanguageLabels.length > 0) {
            let firstLabel = this.piaLanguageLabels[0]
            if (firstLabel.sessionID == sessionBookmark) {
                this.labelBookmark = sessionLabelBookmark
                this.canLoadBookmark = true
            }
        }

        return this.piaLanguageLabelsNextToken
    }

    async loadNextLanguageLabels(event, loadNextLanguageLabels) {
        ////console.log('loading labels with infinte scroll')
        await this.getLanguageLabels(this.piaTranscriptGoldId, this.piaLanguageLabelsNextToken)
        event.target.complete()
        if (this.piaLanguageLabelsNextToken == null) {
            event.target.disabled = true
            this.toggleInfiniteScroll()
        }
    }

    async loadAllLanguageLabelsAtOnce() {
        let next = await this.getLanguageLabels(this.piaTranscriptGoldId, this.piaLanguageLabelsNextToken, 300)
        while (next != null) {
            next = await this.getLanguageLabels(this.piaTranscriptGoldId, next, 300)
        }
    }

    async findToBookmark() {
        let next = await this.getLanguageLabels(this.piaTranscriptGoldId, this.piaLanguageLabelsNextToken, 300)
        while (next != null) {
            let found = this.piaLanguageLabels.find((l) => l.id == this.labelBookmark)
            if (found) {
                this.canLoadBookmark = false
                this.foundBookMark = true

                setTimeout(() => {
                    this.scrollToBookmark()
                }, 2000)

                return
            }

            next = await this.getLanguageLabels(this.piaTranscriptGoldId, next, 300)
        }

        this.canLoadBookmark = false
        this.foundBookMark = false
    }

    scrollToBookmark() {
        let id = 'llid-' + this.labelBookmark
        let element = document.getElementById(id)
        if (element != null) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    toggleInfiniteScroll() {
        ////console.log('toggling infinte scroll component')
        this.infiniteScroll.disabled = !this.infiniteScroll.disabled
    }

    getTextareaTranscript(languagelabels: LanguageLabel[]) {
        let text = ''
        let labelsWithSpeaker: Array<{ speaker: Speaker; content: string }> = []
        let currentSpeaker: Speaker = null
        let currentContent: string = ''
        languagelabels.forEach((p, index) => {
            if (currentSpeaker == null) {
                currentSpeaker = p.Speaker
            }

            if (currentSpeaker.id != p.Speaker.id) {
                //save
                labelsWithSpeaker.push({
                    speaker: currentSpeaker,
                    content: this.formatContent(this.cleanUpContent(currentContent)),
                })
                //create new set of params
                currentSpeaker = p.Speaker
                currentContent = p.content
            } else {
                currentContent += currentContent == '' ? p.content : p.content
            }

            if (index == languagelabels.length - 1) {
                labelsWithSpeaker.push({
                    speaker: currentSpeaker,
                    content: this.formatContent(this.cleanUpContent(currentContent)),
                })
            }
        })
        return labelsWithSpeaker
    }

    renderLanguageLabels(languagelabels: LanguageLabel[]) {
        let text = ''
        languagelabels.forEach((p) => {
            let content = p.content
            content = this.cleanUpContent(content)
            text = text + content
        })
        return text
    }

    //#region Label interaction
    languageLabelClick($event: LanguageLabel) {
        //////console.log('GoldenTranscript languagelabel clicked:', $event);
        this.piaCurrentLanguageLabelID = $event.id
        this.languageLabelClickedEvent.emit($event)

        this.localStorageService.setItem('sessionBookmark', $event.sessionID)
        this.localStorageService.setItem('sessionLabelBookmark', $event.id)
    }

    languageLabelPlaybackEvent($event: PlaybackCommand) {
        ////console.log('playback event from a languagelabel')
        this.languageLabelPlaybackEventEmitter.emit($event)
    }
    //#endregion

    //#region Label Content
    formatContent(content: string) {
        content = content.replace('   ', ' ')
        content = content.replace('  ', ' ')
        return content.trimEnd() + '.'
    }

    cleanUpContent(content: string) {
        let thingsToRemove: string[] = ['[--:za]', '[--: za]', '[--:za]', '[eh]', '[eh]', '[--:spraak_onherkenbaar]', '[--: spraak_onherkenbaar]', '[--:spraak_onherkenbaar]', '[$$:]', '']
        thingsToRemove.forEach((t) => {
            content = content.replace(t, '')
        })

        let thingsToReplace: Array<{ tag: string; replacement: string }> = [
            {
                tag: '[$$: ',
                replacement: '[$$:',
            },
            {
                tag: '[$:',
                replacement: '[$$:',
            },
        ]

        thingsToReplace.forEach((t) => {
            content = content.replace(t.tag, t.replacement)
        })

        // while (content.indexOf('[gr:') > -1) {
        //   let startIndex = content.indexOf('[gr:');
        //   let endIndex = content.indexOf(']', startIndex);
        //   let fullTag = content.substring(startIndex, endIndex + 1);
        //   content = content.replace(
        //     fullTag,
        //     fullTag.replace('[gr:', '').replace(']', '')
        //   );
        //   // ////console.log(
        //   //   'replaced:' +
        //   //     fullTag +
        //   //     ' >>> ' +
        //   //     fullTag.replace('[gr:', '').replace(']', '')
        //   // );
        // }

        content = content.replace('  ', ' ')

        return content + ' '
    }
    //#endregion
}
