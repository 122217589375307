import { Component, Input, OnInit } from '@angular/core'
import { User } from '@humain-r/pia3-codegen'

@Component({
    selector: 'pia3-user-avatar',
    templateUrl: './pia3-user-avatar.component.html',
    styleUrls: ['./pia3-user-avatar.component.scss'],
})
export class Pia3UserAvatarComponent implements OnInit {
    @Input() public user: User = null

    constructor() {}

    ngOnInit() {}

    userLetter(): string {
        return this.user.email.substring(0, 1)
    }
}
