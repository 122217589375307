import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Pia3ThemePositionHorizontal, Pia3ThemePositionVertical } from '../../theme/pia3-theme.service'
import { Speaker } from '@humain-r/pia3-codegen'
import { ModalController } from '@ionic/angular'
import { Pia3AuthenticationService, Pia3CognitoRole } from '../../authentication/authentication.service'

@Component({
    selector: 'pia3-speaker-new-client-speaker-button',
    templateUrl: './pia3-speaker-new-client-speaker-button.component.html',
    styleUrls: ['./pia3-speaker-new-client-speaker-button.component.scss'],
})
export class Pia3SpeakerNewClientSpeakerButtonComponent implements OnInit {
    @Input() public clientId: string
    @Input('horizontal') public horizontal: Pia3ThemePositionHorizontal = Pia3ThemePositionHorizontal.End // Enum:Pia3ThemePositionHorizontal start, center, end
    @Input('vertical') public vertical: Pia3ThemePositionVertical = Pia3ThemePositionVertical.Bottom // Enum:Pia3ThemePositionVertical top, center, bottom
    @Input('edge') public edge: boolean = false

    @Output() public speakerAdded: EventEmitter<Speaker> = new EventEmitter()

    public isSpeakerModalOpen: boolean = false
    public enableAddSpeakerButton: boolean = false

    constructor(private modalController: ModalController, private pia3AuthenticationService: Pia3AuthenticationService) {
        this.enableAddSpeakerButton = this.pia3AuthenticationService.hasAnyPia3Role([Pia3CognitoRole.DATA_TEAMMANAGER, Pia3CognitoRole.SUPERADMIN, Pia3CognitoRole.CLIENT_MANAGER, Pia3CognitoRole.CONTENT_MANAGER])
    }

    ngOnInit() {}

    setSpeakerModalOpen(isOpen: boolean) {
        this.isSpeakerModalOpen = isOpen
    }

    speakerModalDismissed() {}

    childSpeakerAdded(speaker: Speaker) {
        this.speakerAdded.emit(speaker)
        this.modalController.dismiss()
    }
}
