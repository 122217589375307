import { Pia3WordCloudService } from './../pia3-word-cloud.service';
import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IWordWithFrequency } from '../pia3-word-cloud-interfaces';

@Component({
  selector: 'pia3-word-cloud-list',
  templateUrl: './pia3-word-cloud-list.component.html',
  styleUrls: ['./pia3-word-cloud-list.component.scss'],
})
export class Pia3WordCloudListComponent implements OnInit, OnChanges {
  @Input('wordsWithFreq') wordsWithFrequency: IWordWithFrequency[] =
    null || undefined;
  @Input('stopWords') stopWords: string[] = null || undefined;
  filteredWordsWithFreq: IWordWithFrequency[] = null || undefined;
  displayWordsWithFreq: IWordWithFrequency[] = null || undefined;
  wordAsceding: boolean;
  frequencyAsceding: boolean;

  constructor(private pia3WordCloudService: Pia3WordCloudService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stopWords || changes.wordsWithFrequency) {
      this.filterWords();
      this.onWindowResize();
    }
  }

  ngOnInit() {
    if (this.wordsWithFrequency != null) {
      this.filterWords();
      this.onWindowResize();
    }
  }

  /**
   * Filtering word according to the stopWords
   */
  async filterWords() {
    if (this.stopWords !== null) {
      await this.pia3WordCloudService
        .filterWords(this.wordsWithFrequency, this.stopWords)
        .then((result) => {
          this.filteredWordsWithFreq = result;
        })
        .then(() => {
          //trigger desceding for freq
          this.sortFrequencyTrigger();
          this.sortFrequencyTrigger();
        });
    }
  }

  /**
   * Sorts the words of processedWords and assign to displayWords
   */
  sortWordTrigger() {
    if (this.wordAsceding) {
      this.displayWordsWithFreq = this.filteredWordsWithFreq.sort((a, b) =>
        a.word.localeCompare(b.word)
      );
    } else {
      this.displayWordsWithFreq = this.filteredWordsWithFreq.sort((a, b) =>
        b.word.localeCompare(a.word)
      );
    }

    this.wordAsceding = !this.wordAsceding;
  }

  /**
   * Sorts the freq of processedWords and assign to displayWords
   */
  sortFrequencyTrigger() {
    if (this.frequencyAsceding) {
      this.displayWordsWithFreq = this.filteredWordsWithFreq.sort((a, b) => {
        return b.freq - a.freq;
      });
    } else {
      this.displayWordsWithFreq = this.filteredWordsWithFreq.sort((a, b) => {
        return a.freq - b.freq;
      });
    }

    this.frequencyAsceding = !this.frequencyAsceding;
  }

  /**
   * determine height of ion-content
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const ionContent = document.getElementById('ion-content-items');
    const windowHeight = window.innerHeight;

    ionContent.style.height = (windowHeight - 150).toString() + 'px';
  }
}
