import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { SpeechModel } from './../../../API.service'
import { Pia3SpeechmodelService } from '../pia3-speechmodel.service'
import { Pia3ContextchannelService } from '../../contextchannel/pia3-contextchannel.service'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'
import { AlertController, IonModal } from '@ionic/angular'
import { OverlayEventDetail } from '@ionic/core'

interface IcheckboxSpeechModels {
    isSpeechModelChecked: boolean
    speechmodel: SpeechModel
}

@Component({
    selector: 'pia3-speechmodel-selector',
    templateUrl: './pia3-speechmodel-selector.component.html',
    styleUrls: ['./pia3-speechmodel-selector.component.scss'],
})
export class Pia3SpeechmodelSelectorComponent implements OnInit {
    public speechmodels: SpeechModel[]

    @Input('clientID') clientID!: string
    @Input('selectorMode') selectorMode: string = SelectorMode.MULTI_WITH_SEARCH
    @Output('speechmodelSelected') speechmodelSelected = new EventEmitter<SpeechModel[]>()

    //Modal page
    @ViewChild(IonModal) modalSpeechModel: IonModal
    message = ''
    name: string

    //Searchbar
    indeterminateState: boolean | undefined
    checkParent: boolean | undefined
    public checkboxSpeechModels: IcheckboxSpeechModels[] = []
    public resultsOfSearch: IcheckboxSpeechModels[] = []

    //show speakers
    showSpeechModelsWithSearch: SpeechModel[] = null
    showSpeechModels: SpeechModel[] = null

    constructor(private pia3SpeechmodelService: Pia3SpeechmodelService, private pia3ContextchannelService: Pia3ContextchannelService, private alertController: AlertController) {}

    async ngOnInit() {
        this.speechmodels = (await this.getAllSpeechModelsByClient(this.clientID)).data.listSpeechModels.items as SpeechModel[]
        //////console.log('this.speechmodels', this.speechmodels)
        //create new speechmodels array with isSpeechModelChecked property for searchbar
        this.speechmodels.forEach((speechmodel) => {
            this.checkboxSpeechModels.push({
                isSpeechModelChecked: false,
                speechmodel: speechmodel,
            })
        })

        //copy entire created checkboxSpeechModels to resultsOfSearch to protect first instances
        this.resultsOfSearch = [...this.checkboxSpeechModels]
    }

    //get speechmodels from server
    async getAllSpeechModelsByClient(clientID: string) {
        let result = await await this.pia3SpeechmodelService.listSpeechModelsByClientByName(clientID, 100)
        return result
    }

    //when speechmodel changed emit current speechmodel - this is for single mode
    speechmodelChanged($event) {
        if (this.selectorMode === SelectorMode.SINGLE) {
            let selectedspeechmodel: SpeechModel[] = $event.detail.value as SpeechModel[] //$event is coming as an Event here
            this.speechmodelSelected.emit(selectedspeechmodel)
        }

        if (this.selectorMode === SelectorMode.MULTI) {
            let selectedspeechmodels: SpeechModel[] = $event //$event is an SpeechModel[] array here. Coming from confirm()
            this.speechmodelSelected.emit(selectedspeechmodels)
            //show selected speechmodels
            this.showSpeechModels = selectedspeechmodels
        }

        if (this.selectorMode === SelectorMode.MULTI_WITH_SEARCH) {
            let selectedspeechmodels: SpeechModel[] = $event //$event is an SpeechModel[] array here. Coming from confirm()
            this.speechmodelSelected.emit(selectedspeechmodels)
            //show selected speechmodels
            this.showSpeechModelsWithSearch = selectedspeechmodels
        }
    }

    //remove selection multi
    removeSelectionMulti(index: number) {
        if (this.showSpeechModels.length > 0) {
            this.showSpeechModels.splice(index, 1)
        }

        if (this.showSpeechModels.length == 0) {
            this.showSpeechModels = null
        }
        this.speechmodelChanged(this.showSpeechModels)
    }

    //remove selection multi with search
    removeSelectionMultiWithSearch(index: number) {
        if (this.showSpeechModelsWithSearch.length > 0) {
            this.showSpeechModelsWithSearch.splice(index, 1)
        }

        if (this.showSpeechModelsWithSearch.length == 0) {
            this.showSpeechModelsWithSearch = null
        }
        this.speechmodelChanged(this.showSpeechModelsWithSearch)
    }

    /*Modal page */
    cancel() {
        this.modalSpeechModel.dismiss(null, 'cancel')
    }

    confirm() {
        this.modalSpeechModel.dismiss(this.name, 'confirm')

        const checkedspeechmodels: SpeechModel[] = []

        this.resultsOfSearch.forEach((checkboxSpeechModels) => {
            checkboxSpeechModels.isSpeechModelChecked ? checkedspeechmodels.push(checkboxSpeechModels.speechmodel) : ''
        })

        if (checkedspeechmodels.length > 0) {
            this.speechmodelChanged(checkedspeechmodels)
        }
        if (checkedspeechmodels.length == 0) {
            this.presentNoCheckedItemAlert()
            this.showSpeechModels = null
            this.showSpeechModelsWithSearch = null
            this.speechmodelChanged(null)
        }
    }

    onWillDismiss(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
    }

    //searchbar according to fullName
    handleChange($event) {
        const query = $event.detail.value.toLowerCase()
        this.resultsOfSearch = this.checkboxSpeechModels.filter((d) => d.speechmodel.connectionString.toLowerCase().indexOf(query) > -1 || d.speechmodel.Language.name.toLowerCase().indexOf(query) > -1)
    }

    /*checkbox*/
    verifyEvent() {
        const allItems = this.resultsOfSearch.length
        let selected = 0
        this.resultsOfSearch.map((speechmodel: { isSpeechModelChecked: any }) => {
            if (speechmodel.isSpeechModelChecked) selected++
        })
        if (selected > 0 && selected < allItems) {
            // One item is selected among all checkbox elements
            this.indeterminateState = true
            this.checkParent = false
        } else if (selected == allItems) {
            // All item selected
            this.checkParent = true
            this.indeterminateState = false
        } else {
            // No item is selected
            this.indeterminateState = false
            this.checkParent = false
        }
    }

    selectAllspeechmodels() {
        setTimeout(() => {
            this.resultsOfSearch.forEach((speechmodel: { isSpeechModelChecked: boolean | undefined }) => {
                speechmodel.isSpeechModelChecked = this.checkParent
            })
        })
    }

    /*Alerts*/
    //alert-no checked item
    async presentNoCheckedItemAlert() {
        const alert = await this.alertController.create({
            header: 'No checked speechmodel',
            // subHeader: 'No results were found in the selected date range!',
            message: 'Please check one ore more speaker.',
            buttons: ['OK'],
        })

        await alert.present()
    }
}
