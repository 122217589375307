<!-- <ion-header>
  <ion-toolbar color="secondary">
    <ion-title class="ion-padding">
      <img
        class="flex-center img"
        style="max-height: 85px"
        src="//images.squarespace-cdn.com/content/v1/63f4d18642f2100294b4e8a4/1676988882928-XOV6QYMCIA6XS9H5QMF5/Logo+Humainr+transparent+dark+background.png?format=1500w"
        alt="Humainr logo"
      />
    </ion-title>
  </ion-toolbar>
</ion-header> -->

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12" size-sm="6">
        <ion-item>
          <ion-textarea
            style="min-height: 400px"
            placeholder="Type or paste text here"
            [autoGrow]="false"
            [(ngModel)]="text"
          >
          </ion-textarea>
        </ion-item>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-text [color]="outputColor">
          {{ output }}
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div style="margin: 10px">
    <ion-row class="ion-padding">
      <ion-col style="height: 60px; width: auto" sizeSm="10" offsetSm="1">
        <ion-button class="flex-center" (click)="convert()">
          Convert
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
