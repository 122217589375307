import { Component, Input, OnInit } from '@angular/core'
import { Pia3AuthenticationService } from '../../authentication/authentication.service'

@Component({
    selector: 'pia3-documentation-display',
    templateUrl: './pia3-document-display.component.html',
    styleUrls: ['./pia3-document-display.component.scss'],
})
export class Pia3DocumentDisplayComponent implements OnInit {
    @Input() public isDocumentationModalOpen: boolean = true

    public isDataAgent: boolean = true

    constructor(private pia3AuthService: Pia3AuthenticationService) {}

    ngOnInit() {}

    documentationModalDismissed(event: any) {
        this.isDocumentationModalOpen = false
    }
}
