import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class Pia3LocalStorageService {
    setItem(key: string, value: string) {
        localStorage.setItem(key, value)
    }

    getItem(key: string) {
        return localStorage.getItem(key)
    }

    removeItem(key: string) {
        localStorage.removeItem(key)
    }

    clear() {
        localStorage.clear()
    }

    print() {
        for (let i = 0; i < localStorage.length; i++) {
            console.log(localStorage.key(i) + '=[' + localStorage.getItem(localStorage.key(i)) + ']')
        }
    }
}
