import { Component, Input, OnInit, SimpleChange } from '@angular/core'

import { Contextchannel, PipelineOutput } from '@humain-r/pia3-codegen'

import { Pia3ContextchannelService } from '../pia3-contextchannel.service'
import { ErrorhandlingService } from '../../_common/errorhandling/errorhandling.service'
import { Pia3PipelineService } from '../../pipeline/pia3-pipeline.service'

@Component({
    selector: 'pia3-contextchannel-question-awnser',
    templateUrl: './pia3-contextchannel-question-awnser.component.html',
    styleUrls: ['./pia3-contextchannel-question-awnser.component.scss'],
})
export class Pia3ContextchannelQuestionAwnserComponent implements OnInit {
    @Input() public contextChannelId: string

    public errorLoading: boolean = false
    public noQA: boolean = false    

    public contextChannel: Contextchannel
    public questions: { question: string; awnser: string; createdAt: number; open: boolean }[] = []

    constructor(private contextChannelService: Pia3ContextchannelService, private errorHandlerService: ErrorhandlingService, private pipelineService: Pia3PipelineService) {}

    ngOnInit() {
        if (this.contextChannelId != null && this.contextChannelId != '') {
            this.loadContextChannel(this.contextChannelId)
        }
    }

    async ngOnChanges(changes: SimpleChange) {
        let contextchannelChange = changes['contextChannelId']
        if (contextchannelChange.currentValue != null) {
            await this.loadContextChannel(contextchannelChange.currentValue)
            await this.loadPipelineData()
        }
    }

    async loadContextChannel(channelId: string) {
        try {
            var contextchannel = await this.contextChannelService.getContextchannel(channelId)
        } catch (error) {
            this.contextChannel = null
            this.errorHandlerService.handleGenericGraphQLError(error)
            this.errorLoading = true
            return
        }

        this.contextChannel = contextchannel.data.getContextchannel as Contextchannel
    }

    async loadPipelineData() {
        this.questions = []
        let outputs = await this.getAllPipelineOutputs()
        let unsortedQuestions = []

        outputs.forEach((item) => {
            let itemContent: { query: string; output: string; } = JSON.parse(item.value)            
            unsortedQuestions.push({ question: itemContent.query, awnser: itemContent.output, createdAt: Date.parse(item.createdAt), open: true })
        })     
                
        let sortedQuestions = unsortedQuestions.sort((i1,i2) => {                        
            return i1.createdAt-i2.createdAt
        });
        
        this.questions = sortedQuestions
    }

    async getAllPipelineOutputs() {        
        let qaPipelineId = ''
        let pipelines = await this.pipelineService.getContextChannelPipelines(this.contextChannel.id)        
        pipelines.forEach((item) => {
            if (item.name == 'Q&A Pipeline') {
                qaPipelineId = item.id
            }
        })

        if (!qaPipelineId) {
            return []
        }

        let contextChannelPipelineOutputs = await this.pipelineService.getPipelineOutputsByContextChannelIdAndPipelineId(this.contextChannel.id, qaPipelineId)            
        return contextChannelPipelineOutputs
    }

    toggleAllQuestions(state: boolean)
    {
        this.questions.forEach(question => {
            question.open = state
        })
    }

    divideText(input: string): string[] {
        if (input == null) {
            return ['']
        }

        if (input.indexOf('\n') == -1) {
            return [input]
        }

        return input.split('\n')
    }
}
