import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Speaker } from './../../../API.service'
import { Pia3SpeakerService } from '../pia3-speaker.service'
import { Pia3ContextchannelService } from '../../contextchannel/pia3-contextchannel.service'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'
import { AlertController, IonModal } from '@ionic/angular'
import { OverlayEventDetail } from '@ionic/core'

interface ICheckboxSpeakers {
    isSpeakerChecked: boolean
    speaker: Speaker
}

@Component({
    selector: 'pia3-speaker-selector',
    templateUrl: './pia3-speaker-selector.component.html',
    styleUrls: ['./pia3-speaker-selector.component.scss'],
})
export class Pia3SpeakerSelectorComponent implements OnInit {
    public speakers: Speaker[]

    @Input('clientID') clientID!: string
    @Input('selectorMode') selectorMode: string = SelectorMode.MULTI_WITH_SEARCH
    @Output('speakerSelected') speakerSelected = new EventEmitter<Speaker[]>()

    //Modal page
    @ViewChild(IonModal) modalSpeaker: IonModal
    message = ''
    name: string

    //Searchbar
    indeterminateState: boolean | undefined
    checkParent: boolean | undefined
    public checkboxSpeakers: ICheckboxSpeakers[] = []
    public resultsOfSearch: ICheckboxSpeakers[] = []

    //show speakers
    showSpeakersWithSearch: Speaker[] = null
    showSpeakers: Speaker[] = null

    constructor(private pia3SpeakerService: Pia3SpeakerService, private pia3ContextchannelService: Pia3ContextchannelService, private alertController: AlertController) {}

    async ngOnInit() {
        this.speakers = (await this.getAllSpeakersByClient(this.clientID)).data.SpeakersByClientByLastName.items as Speaker[]

        //create new speakers array with isSpeakerChecked property for searchbar
        this.speakers.forEach((speaker) => {
            this.checkboxSpeakers.push({
                isSpeakerChecked: false,
                speaker: speaker,
            })
        })

        //copy entire created checkboxSpeakers to resultsOfSearch to protect first instances
        this.resultsOfSearch = [...this.checkboxSpeakers]
    }

    //get Speakers from server
    async getAllSpeakersByClient(clientID: string) {
        let result = await await this.pia3SpeakerService.listSpeakersByClientByLastname(clientID, 100)
        return result
    }

    //when speaker changed emit current speaker - this is for single mode
    speakerChanged($event) {
        if (this.selectorMode === SelectorMode.SINGLE) {
            let selectedSpeaker: Speaker[] = $event.detail.value as Speaker[] //$event is coming as an Event here
            this.speakerSelected.emit(selectedSpeaker)
        }

        if (this.selectorMode === SelectorMode.MULTI) {
            let selectedSpeakers: Speaker[] = $event //$event is an Speaker[] array here. Coming from confirm()
            this.speakerSelected.emit(selectedSpeakers)
            //show selected speakers
            this.showSpeakers = selectedSpeakers
        }

        if (this.selectorMode === SelectorMode.MULTI_WITH_SEARCH) {
            let selectedSpeakers: Speaker[] = $event //$event is an Speaker[] array here. Coming from confirm()
            this.speakerSelected.emit(selectedSpeakers)
            //show selected speakers
            this.showSpeakersWithSearch = selectedSpeakers
        }
    }

    //remove selection multi
    removeSelectionMulti(index: number) {
        if (this.showSpeakers.length > 0) {
            this.showSpeakers.splice(index, 1)
        }

        if (this.showSpeakers.length == 0) {
            this.showSpeakers = null
        }
        this.speakerChanged(this.showSpeakers)
    }

    //remove selection multi with search
    removeSelectionMultiWithSearch(index: number) {
        if (this.showSpeakersWithSearch.length > 0) {
            this.showSpeakersWithSearch.splice(index, 1)
        }

        if (this.showSpeakersWithSearch.length == 0) {
            this.showSpeakersWithSearch = null
        }
        this.speakerChanged(this.showSpeakersWithSearch)
    }

    /*Modal page */
    cancel() {
        this.modalSpeaker.dismiss(null, 'cancel')
    }

    confirm() {
        this.modalSpeaker.dismiss(this.name, 'confirm')

        const checkedSpeakers: Speaker[] = []

        this.resultsOfSearch.forEach((checkboxSpeakers) => {
            checkboxSpeakers.isSpeakerChecked ? checkedSpeakers.push(checkboxSpeakers.speaker) : ''
        })

        if (checkedSpeakers.length > 0) {
            this.speakerChanged(checkedSpeakers)
        }
        if (checkedSpeakers.length == 0) {
            this.presentNoCheckedItemAlert()
            this.showSpeakers = null
            this.showSpeakersWithSearch = null
            this.speakerChanged(null)
        }
    }

    onWillDismiss(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
    }

    //searchbar according to fullName
    handleChange($event) {
        const query = $event.detail.value.toLowerCase()
        this.resultsOfSearch = this.checkboxSpeakers.filter((d) => d.speaker.firstName.toLowerCase().indexOf(query) > -1 || d.speaker.lastName.toLowerCase().indexOf(query) > -1)
    }

    /*checkbox*/
    verifyEvent() {
        const allItems = this.resultsOfSearch.length
        let selected = 0
        this.resultsOfSearch.map((speaker: { isSpeakerChecked: any }) => {
            if (speaker.isSpeakerChecked) selected++
        })
        if (selected > 0 && selected < allItems) {
            // One item is selected among all checkbox elements
            this.indeterminateState = true
            this.checkParent = false
        } else if (selected == allItems) {
            // All item selected
            this.checkParent = true
            this.indeterminateState = false
        } else {
            // No item is selected
            this.indeterminateState = false
            this.checkParent = false
        }
    }

    selectAllSpeakers() {
        setTimeout(() => {
            this.resultsOfSearch.forEach((speaker: { isSpeakerChecked: boolean | undefined }) => {
                speaker.isSpeakerChecked = this.checkParent
            })
        })
    }

    /*Alerts*/
    //alert-no checked item
    async presentNoCheckedItemAlert() {
        const alert = await this.alertController.create({
            header: 'No checked speaker',
            // subHeader: 'No results were found in the selected date range!',
            message: 'Please check one ore more speaker.',
            buttons: ['OK'],
        })

        await alert.present()
    }
}
