import { Component, Input, OnInit } from '@angular/core'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { Method } from 'ionicons/dist/types/stencil-public-runtime'

interface hotkeyDescription {
    shortcut: string
    description: string
}

@Component({
    selector: 'pia3-hotkey-display',
    templateUrl: './pia3-hotkey-display.component.html',
    styleUrls: ['./pia3-hotkey-display.component.scss'],
})
export class Pia3HotkeyDisplayComponent implements OnInit {
    @Input() public isShortcutsModalOpen: boolean = false

    public hotkeys: hotkeyDescription[] = []

    constructor(private hotkeyService: HotkeysService) {}

    ngOnInit() {
        //Globally Registered hotkeys
        this.hotkeyService.hotkeys.forEach((hotkey: Hotkey) => {
            if (!hotkey.description) {
                return
            }

            if (hotkey.description === 'Show / hide this help menu') {
                return
            }

            let combo: string = ''

            if (hotkey.combo instanceof Array) {
                combo = hotkey.combo.join(' / ')
            } else {
                combo = hotkey.combo
            }

            let desc: string = ''

            if (hotkey.description instanceof Function) {
                desc = hotkey.description()
            } else {
                desc = hotkey.description
            }

            this.hotkeys.push({ shortcut: this.PrettifyHotkeyCombo(combo), description: desc })
        })

        //Custom ones, these exist only to document behaviours in other places and are not technically angular2-hotkey hotkeys
        //this.hotkeys.push({ shortcut: 'F11', description: 'Toggle Fullscreen' })
        this.hotkeys.push({ shortcut: this.PrettifyHotkeyCombo('ctrl+f'), description: 'Zoeken' })
        this.hotkeys.push({ shortcut: this.PrettifyHotkeyCombo('tab'), description: 'Volgende Label' })
    }

    shortCutModalDismissed(event: any) {
        this.isShortcutsModalOpen = false
    }

    PrettifyHotkeyCombo(combo: string) {
        combo = this.stringReplaceAll(combo, '+', ' + ')
        combo = this.stringReplaceAll(combo, 'space', 'spatie')
        combo = this.stringReplaceAll(combo, 'ctrl', 'control')
        combo = this.stringReplaceAll(combo, 'left', 'links')
        combo = this.stringReplaceAll(combo, 'right', 'rechts')

        return this.titleCase(combo)
    }

    titleCase(input: string) {
        var inputElements = input.toLowerCase().split(' ')

        for (var i = 0; i < inputElements.length; i++) {
            inputElements[i] = inputElements[i].charAt(0).toUpperCase() + inputElements[i].substring(1)
        }

        return inputElements.join(' ')
    }

    stringReplaceAll(input: string, search: string, replacement: string) {
        return input.split(search).join(replacement)
    }
}
