import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { IonInput } from '@ionic/angular'
import { Pia3ContextchannelService } from '../../contextchannel/pia3-contextchannel.service'
import { Pia3AuthenticationService } from '../authentication.service'

@Component({
    selector: 'pia3-login-component',
    templateUrl: './pia3-login.component.html',
    styleUrls: ['./pia3-login.component.scss'],
})
export class Pia3LoginComponent implements OnInit {
    @Input('loggedInPath') loggedInPath: string
    @Input('resetpasswordPath') resetpasswordPath: string

    public email: string
    public password: string
    public isWorking: boolean = false
    loginError: string = ''
    currentUser: any

    constructor(public router: Router, public authenticationService: Pia3AuthenticationService, private pia3ContextchannelService: Pia3ContextchannelService) {}

    ngOnInit() {}

    async inloggen() {
        this.isWorking = true
        this.authenticationService
            .signInUser(this.email, this.password)
            .then(this.inloggenSuccess, this.inloggenReject)
            .catch(this.inloggenReject)
            .finally(() => {
                this.isWorking = false
            })
    }

    inloggenSuccess = (user) => {
        if (this.loggedInPath != null) this.router.navigate([this.loggedInPath])
    }

    inloggenReject = (rejection) => {
        this.loginError = rejection
    }
}
