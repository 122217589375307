<div *ngIf="errorLoading">
    <h2>Pia kon het contextkanaal helaas niet laden.</h2>
</div>

<div *ngIf="!errorLoading && noQA">
    <h2>Dit contextkanaal ondersteund geen Vragen en Antwoorden.</h2>
</div>

<div *ngIf="!errorLoading && !noQA && contextChannel">
    <div class="qaContent" *ngIf="questions.length == 0">
        <h2>Er zijn nog geen vragen op dit kanaal.</h2>
    </div>
    
    <div *ngIf="questions.length != 0" class="qaContent">
        
        
        <!--div>
            <ion-button (click)="toggleAllQuestions(true)">Open All</ion-button>
            <ion-button (click)="toggleAllQuestions(false)">Close All</ion-button>
        </div-->

        <pia3-accordion *ngFor="let item of questions" [title]="item.question" [toggleState]="item.open">
            <div class="ion-padding awnserContent">
                <p *ngFor="let p of this.divideText(item.awnser)">{{ p }}</p>
            </div>   
        </pia3-accordion>
    </div>
</div>

