import { Component, OnInit, Input, SimpleChanges } from '@angular/core'
import { Pia3ColumnChartOptions } from './pia3-columnchart-options.model'
import 'anychart'

@Component({
  selector: 'pia3-charting-columnchart',
  templateUrl: './pia3-columnchart.component.html',
  styleUrls: ['./pia3-columnchart.component.scss'],
})
export class Pia3ColumnChartComponent implements OnInit {

  private _chart!: anychart.charts.Cartesian
  private _chartTitle!: anychart.core.ui.Title

  @Input() public jsonData: any
  @Input() public chartHeight: number = 500
  @Input() public chartOptions: Pia3ColumnChartOptions = new Pia3ColumnChartOptions()

  ngOnInit() {
    this.createChart()
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == "jsonData") {
        this.updateChart(this.jsonData)
        this.updateChartOptions(this.chartOptions)
      }

      if (propName == "chartOptions") {
        this.updateChartOptions(this.chartOptions)
      }
    }
  }

  createChart() {
    this._chart = anychart.column(this.jsonData)
    this._chart.container("ChartLibContainer")

    this._chartTitle = this._chart.title()
    this._chartTitle.text("Chart")
    this._chartTitle.enabled(true)

    this._chart.barGroupsPadding(1)

    this.updateChart(this.jsonData)
    this.updateChartOptions(this.chartOptions)

    this._chart.draw()
  }

  updateChart(chartData: any) {
    if (chartData) {
      this._chart.removeAllSeries()
      this._chart.data(chartData)
    }
  }

  updateChartOptions(options: Pia3ColumnChartOptions) {
    if (!this._chartTitle) {
      return
    }

    this._chartTitle.text(options.title)

    if (this.chartOptions.xAxis != "") {
      this._chart.xAxis().title(options.xAxis)
    }

    if (this.chartOptions.yAxis != "") {
      this._chart.yAxis().title(options.yAxis)
    }

  }
}
