import { Component, Input, OnInit } from '@angular/core'
import { User } from '@humain-r/pia3-codegen'

@Component({
    selector: 'pia3-user-listitem',
    templateUrl: './pia3-user-listitem.component.html',
    styleUrls: ['./pia3-user-listitem.component.scss'],
})
export class Pia3UserListItemComponent implements OnInit {
    @Input() public user: User = null

    constructor() {}

    ngOnInit() {}

    userToString(user: User): string {
        let name = `${user.firstName || ''} ${user.infix || ''} ${user.lastName || ''}`.trim()

        if (!name) {
            return user.id
        }

        return name
    }
}
