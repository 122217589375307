<ion-card
  *ngIf="_session != null"
  [color]="_sessionCardConfig.cardColor"
  class="ion-text-left"
>
  <ion-img
    alt="Alt title of the image"
    src="https://picsum.photos/320/180?random={{ _session.id }}"
  ></ion-img>

  <!-- Pia3AudioPlayer-->
  <pia3-audio-player
    *ngIf="
      _audioPlayerWaveColor != undefined &&
      _sessionCardConfig.showAudioplayer &&
      _session.Video == null
    "
    [audio]="_session.Audio"
    [config]="_sessionCardConfig.audioplayerConfig"
    [waveColor]="_audioPlayerWaveColor"
    [waveProgressColor]="_audioPlayerWaveProgressColor"
  ></pia3-audio-player>

  <!-- Video-->
  <pia3-video-player
    *ngIf="
      _sessionCardConfig.showVideoplayer &&
      _session.videoID != null &&
      _session.Video != null
    "
    [video]="_session.Video"
    [config]="_sessionCardConfig.videoplayerConfig"
  ></pia3-video-player>

  <ion-card-header>
    <ion-fab
      *ngIf="_sessionCardConfig.showSpeakerAvatar"
      class="speakerAvatar"
      vertical="top"
      horizontal="end"
      edge="true"
    >
      <pia3-speaker-avatar [speaker]="undefined"></pia3-speaker-avatar
    ></ion-fab>
    <ion-fab
      class="categoryButton"
      vertical="top"
      horizontal="start"
      edge="true"
    >
      <ion-button
        *ngIf="_sessionCardConfig.showContextLabel"
        [color]="_sessionCardConfig.contextLabelColor"
        >{{ getGNLPCategory(_session) }}</ion-button
      >
    </ion-fab>

    <ion-card-title *ngIf="_sessionCardConfig.showTitle">{{
      _sessionTitle
    }}</ion-card-title>
    <ion-card-title *ngIf="!_sessionCardConfig.showTitle">{{
      _session.name
    }}</ion-card-title>
    <!-- <ion-card-subtitle>Subtitle of this card</ion-card-subtitle> -->
  </ion-card-header>

  <ion-card-content>
    <!-- Summary paragraph-->
    <p class="summary" *ngIf="_sessionCardConfig.showSummary">
      {{ getGNLPSummary(_session) }}
    </p>
    <!-- Keyword chips-->
    <div class="keywords" *ngIf="_sessionCardConfig.showKeywords">
      <ion-badge
        [color]="_sessionCardConfig.contextLabelColor"
        *ngFor="let keyword of getGNLPKeywords(_session)"
        >{{ keyword }}</ion-badge
      >
    </div>
  </ion-card-content>

  <!-- DetailButton-->
  <ion-fab class="detailButton" vertical="bottom" horizontal="end" edge="true">
    <ion-fab-button color="light" (click)="cardClick(_session)"
      ><ion-icon name="arrow-forward"></ion-icon
    ></ion-fab-button>
  </ion-fab>
</ion-card>
