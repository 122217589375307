<ion-grid>
  <ion-row>
    <ion-col size="2">
      <ion-button (click)="presentPopoverDetails($event)">Details</ion-button>
      <div *ngIf="this.filterIssue" class="warningTooltip">
        <span class="elementWithTooltip">&nbsp;&nbsp;⚠️</span>
        <span class="warningTooltipText">Het is niet mogelijk om een grafiek te maken met "Alles" van "Iedereen".
        </span>
      </div>
      <span *ngIf="this.totalDataMinutes > 0">Totaal Dataminuten: {{this.totalDataMinutes | number : '1.0-1'}}</span>
    </ion-col>
    <ion-col size="5">
      <ion-select interface="popover" [(ngModel)]="filteredUserGUID" (ionChange)="handleUserChange($event)">
        <ion-select-option [ngClass]="this.filterIssue ? 'ion-select-valid': 'ion-select-invalid'" value="{{u.value}}"
          *ngFor="let u of userMap | keyvalue">{{u.key}}</ion-select-option>
      </ion-select>
    </ion-col>
    <ion-col size="5">
      <ion-select interface="popover" [(ngModel)]="filteredChannelGUID" (ionChange)="handleChannelChange($event)">
        <ion-select-option value="{{u.value}}" *ngFor="let u of channelMap | keyvalue">{{u.key}}</ion-select-option>
      </ion-select>
    </ion-col>
  </ion-row>
</ion-grid>

<pia3-charting-stacked-columnchart [chartHeight]='700' [chartOptions]="chartOptions"
  [chartSeries]='chartData'></pia3-charting-stacked-columnchart>

<ion-modal #waitingModal [isOpen]="isOpenWaitingModal" [canDismiss]="true" class="WaitModal">
  <ng-template>
    <div>
      <div>
        <ion-spinner name="dots"></ion-spinner>
        <p class="ion-padding-horizontal">Even Wachten A.U.B. </p>
        <ion-button (click)="stopQuery()">Stop</ion-button>
      </div>
    </div>
  </ng-template>
</ion-modal>

<ion-popover #popoverChannels [isOpen]="isOpenChannelPopup" (didDismiss)="isOpenChannelPopup = false">
  <ng-template>
    <pia3-contextchannel-list></pia3-contextchannel-list>
  </ng-template>
</ion-popover>

<ion-popover #popoverDetails [isOpen]="isOpenDetailsPopup" (didDismiss)="isOpenDetailsPopup = false">
  <ng-template>
    <p>
      Recent:
    </p>
    <div>
      <ion-button (click)="fillDates($event, 0)">Vandaag</ion-button>
      <ion-button (click)="fillDates($event, 7)">Laatste 7 Dagen</ion-button>
      <ion-button (click)="fillDates($event, 14)">Laatste Twee Weken</ion-button>
      <ion-button (click)="fillDates($event, 30)">Laatste Maand</ion-button>
    </div>
    <p>
      Van:
      <ion-datetime-button datetime="chartRangeFrom"></ion-datetime-button>
    </p>
    <p>
      Tot:
      <ion-datetime-button datetime="chartRangeTo"></ion-datetime-button>
    </p>
    <p>
      Formaat:
    </p>
    <div>
      <ion-radio-group value="Week" [(ngModel)]="barType">
        <ion-radio labelPlacement="start" value="Day">Dagen</ion-radio> Dagen<br />
        <ion-radio labelPlacement="start" value="Week">Weken</ion-radio> Weken<br />
        <ion-radio labelPlacement="start" value="Month">Maanden</ion-radio> Maanden <br />
      </ion-radio-group>
      <br />
      <ion-checkbox [(ngModel)]="totalBar" labelPlacement="end"></ion-checkbox> Totalen Balk <br />
    </div>

    <p>
      <ion-button (click)="updateChart($event)">Update Grafiek</ion-button>
    </p>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime [showDefaultButtons]="true" [(ngModel)]="dateFrom" presentation="date"
          id="chartRangeFrom"></ion-datetime>
      </ng-template>
    </ion-modal>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime [showDefaultButtons]="true" [(ngModel)]="dateTo" presentation="date"
          id="chartRangeTo"></ion-datetime>
      </ng-template>
    </ion-modal>

  </ng-template>
</ion-popover>