import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

// import { Contextchannel } from 'src/models';
// import { Session } from 'src/models';
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import { queries, subscriptions, mutations, ExportFormat, ExportSessionQuery, ValidationProgressStatus,  } from '@humain-r/pia3-codegen'
import {
    Session,
    Contextchannel,
    Language,
    CreateSessionInput,
    CreateSessionMutation,
    GetSessionQuery,    ListSessionsQuery,
    SessionsByContextchannelByCreatedAtQuery,
    SearchableStringFilterInput,
    SearchableSessionFilterInput,
    SearchSessionsQuery,
    UpdateSessionMutation,
    OnCreateSessionSubscription,
    OnUpdateSessionSubscription,
    OnDeleteSessionSubscription,
    SearchableSessionSortableFields,
    ModelSortDirection,
    UpdateSessionInput
} from '@humain-r/pia3-codegen'
import { Pia3Service } from '../../pia3.service'

@Injectable({
    providedIn: 'root',
})
export class Pia3SessionService {
    private _currentSession: BehaviorSubject<Session> = new BehaviorSubject(null)
    public readonly currentSession: Observable<Session> = this._currentSession.asObservable()

    constructor(private pia3Service: Pia3Service) { }

    async getSession(id: string): Promise<{ data: GetSessionQuery }> {
        return API.graphql<Session>(graphqlOperation(queries.getSession, { id: id })) as Promise<{ data: GetSessionQuery }>
    }

    async getSessionsByContextchannel(contextchannelId: string, sortDirection: ModelSortDirection, limit?: number, nexttoken?: string): Promise<Session[]> {
        let queryResult = API.graphql(
            graphqlOperation(queries.sessionsByContextchannelByCreatedAt, {
                contextchannelID: contextchannelId,
                sortDirection: sortDirection,                
                limit: limit != null ? limit : 100,
                nextToken: nexttoken
            })
        ) as Promise<{ data: SessionsByContextchannelByCreatedAtQuery }>

        let sessions = (await queryResult).data.SessionsByContextchannelByCreatedAt.items as Session[]
        let unarchivedSession = sessions.filter(s => s.archived != true )
            
        return unarchivedSession;
    }

    async getSessionsByContextchannelWithArchived(contextchannelId: string, sortDirection: ModelSortDirection, limit?: number, nexttoken?: string): Promise<Session[]> {
        let queryResult = API.graphql(
            graphqlOperation(queries.sessionsByContextchannelByCreatedAt, {
                contextchannelID: contextchannelId,
                sortDirection: sortDirection,                
                limit: limit != null ? limit : 100,
                nextToken: nexttoken
            })
        ) as Promise<{ data: SessionsByContextchannelByCreatedAtQuery }>

        let sessions = (await queryResult).data.SessionsByContextchannelByCreatedAt.items as Session[]
        
        return sessions;
    }

    async searchSessions(searchterm: string, contextchannelId: string, archived: boolean = false): Promise<Session[]> {
        // queries.SearchableSessionFilterInput;
        // $filter: SearchableSessionFilterInput;
        // $sort: [SearchableSessionSortInput];
        // $limit: Int;
        // $nextToken: String;
        // $from: Int;
        // $aggregates: [SearchableSessionAggregationInput];

        const filterInput: SearchableSessionFilterInput = this.createSearchInput(searchterm, contextchannelId)
        //console.log(filterInput)

        const sessions = await API.graphql(
            graphqlOperation(queries.searchSessions, {
                filter: filterInput,
            })
        ) as Promise<{ data: SearchSessionsQuery }>

        if(!archived)
        {
            return (await sessions).data.searchSessions.items.filter(s => s.archived != true ) as Session[]
            
        }

        return (await sessions).data.searchSessions.items as Session[]                    
    }

    async searchSessionsInMultipleChannels(searchterm: string, contextchannelIDs: string[],  archived: boolean = false): Promise<Session[]> {
        // queries.SearchableSessionFilterInput;
        // $filter: SearchableSessionFilterInput;
        // $sort: [SearchableSessionSortInput];
        // $limit: Int;
        // $nextToken: String;
        // $from: Int;
        // $aggregates: [SearchableSessionAggregationInput];

        const filterInput: SearchableSessionFilterInput = this.createSearchInputnMultipleChannels(searchterm, contextchannelIDs)

        const sessions = API.graphql(
            graphqlOperation(queries.searchSessions, {
                filter: filterInput,
            })
        ) as Promise<{ data: SearchSessionsQuery }>

        if(!archived)
        {
            return (await sessions).data.searchSessions.items.filter(s => s.archived != true ) as Session[]
            
        }

        return (await sessions).data.searchSessions.items as Session[]  
    }

    createSearchInput(terms: string, contextchannelId: string) {
        let searchInput: SearchableSessionFilterInput = {
            and: [
                {
                    contextchannelID: {
                        eq: contextchannelId,
                    },
                },
            ],
        }

        terms.split(' ').forEach((term) => {
            if (term != null && term != '') {
                //check for wildcard query
                if (term.indexOf('*') != -1) {
                    searchInput.and.push({
                        name: {
                            wildcard: '*' + term.toLowerCase().replace('*', '') + '*',
                        },
                    })
                } else {
                    //normal matchPrefix
                    searchInput.and.push({
                        name: {
                            matchPhrasePrefix: term.toLowerCase(),
                        },
                    })
                }
            }
        })        
        return searchInput
    }

    createSearchInputnMultipleChannels(terms: string, contextchannelIDs: string[]) {
        let searchInput = {
            or: [],
        }

        terms.split(' ').forEach((term) => {
            if (term != null && term != '') {
                //check for wildcard query
                if (term.indexOf('*') != -1) {
                    searchInput.or.push({
                        keywords: {
                            wildcard: '*' + term.toLowerCase().replace('*', '') + '*',
                        },
                    })
                    searchInput.or.push({
                        title: {
                            wildcard: '*' + term.toLowerCase().replace('*', '') + '*',
                        },
                    })
                    searchInput.or.push({
                        summary: {
                            wildcard: '*' + term.toLowerCase().replace('*', '') + '*',
                        },
                    })
                    searchInput.or.push({
                        category: {
                            wildcard: '*' + term.toLowerCase().replace('*', '') + '*',
                        },
                    })
                } else {
                    //normal matchPrefix
                    searchInput.or.push({
                        keywords: {
                            matchPhrasePrefix: term.toLowerCase(),
                        },
                    })
                    searchInput.or.push({
                        title: {
                            matchPhrasePrefix: term.toLowerCase(),
                        },
                    })
                    searchInput.or.push({
                        summary: {
                            matchPhrasePrefix: term.toLowerCase(),
                        },
                    })
                }
            }
        })

        //console.log(searchInput)

        return searchInput
    }

    async createSession(createSessionInput: CreateSessionInput) {
        const query = await (API.graphql<Session>(graphqlOperation(mutations.createSession, { input: createSessionInput })) as Promise<{ data: CreateSessionMutation }>)
        return query
    }

    async updateSession(updateSessionInput: UpdateSessionInput){
        return await (API.graphql<Session>(graphqlOperation(mutations.updateSession, { input: updateSessionInput })) as Promise<{ data: UpdateSessionMutation }>)        
    }

    async updateSessionName(sessionId: string, versionNumber: number, nameText: string) {
        let sessionInput: UpdateSessionInput = {
            id: sessionId,
            _version: versionNumber,
            name: nameText
        }

        const mutation = await (API.graphql(graphqlOperation(mutations.updateSession, { input: sessionInput })) as Promise<{ data: UpdateSessionMutation }>)
        return (await mutation).data.updateSession
    }

    async updateSessionValidationProgressStatus(id: string, validationProgressStatus: ValidationProgressStatus, _version: number) {
        const query = await (API.graphql<Session>(
            graphqlOperation(mutations.updateSession, {
                input: {
                    id: id,
                    validationProgressStatus: validationProgressStatus,
                    _version: _version,
                },
            })
        ) as Promise<{ data: UpdateSessionMutation }>)
        return query
    }

    async exportSessionTranscript(sessionID: string, format: ExportFormat) {
        return API.graphql<string>(
            graphqlOperation(queries.exportSession, {
                sessionID: sessionID,
                exportFormat: format,
            })
        ) as Promise<{ data: ExportSessionQuery }>
    }

    //#region SUBSCRIPTIONS
    async onCreateSession() {
        return (await API.graphql(graphqlOperation(subscriptions.onCreateSession))) as Observable<OnCreateSessionSubscription>
    }

    async onUpdateSession() {
        return (await API.graphql(graphqlOperation(subscriptions.onUpdateSession))) as Observable<OnUpdateSessionSubscription>
    }

    async onDeleteSession() {
        return (await API.graphql(graphqlOperation(subscriptions.onDeleteSession))) as Observable<OnDeleteSessionSubscription>
    }
    //#endregion

    //#region Session Detail Methods

    public getGNLPCategory(session: Session, language: Language = null): string {
        let languageISOCode: string = language == null ? null : language.ISO_639_2
        let category: string = 'Categorie wordt gemaakt.'

        if (session != null && session.category != null) {
            category = session.category
            // for (const [key, val] of Object.entries(
            //   JSON.parse(session.gnlpOutputs)
            // )) {
            //   if (key == 'category') {
            //     category = val.toString();
            //   }
            // }
        }
        category = category.replace('_', ' ').replace('.', '')
        return category
    }

    public getGNLPTitle(session: Session, language: Language = null): string {
        let languageISOCode: string = language == null ? null : language.ISO_639_2
        let title: string = session.name

        if (session != null && session.title != null) {
            // for (const [key, val] of Object.entries(
            //   JSON.parse(session.gnlpOutputs)
            // )) {
            //   if (key == 'title') {
            //     title = val.toString();
            //   }
            // }
            title = session.title
        }
        title = title.replace('_', ' ')
        return title
    }

    public getGNLPSummary(session: Session, language: string = 'nld'): string {
        let summary: string = null
        summary = 'Samenvatting wordt gemaakt...' //remove to get out of construction
        if (session != null && session.summary != null) {
            // for (const [key, val] of Object.entries(
            //   JSON.parse(session.gnlpOutputs)
            // )) {
            //   if (key == 'summary') {
            //     summary = val.toString();
            //   }
            // }
            summary = session.summary
        }
        return summary
    }

    public getGNLPSummaryCondensed(session: Session, language: string = 'nld'): string {
        let summary: string = null
        summary = 'Introductie wordt gemaakt...' //remove to get out of construction
        if (session != null && session.condensed != null) {
            // for (const [key, val] of Object.entries(
            //   JSON.parse(session.gnlpOutputs)
            // )) {
            //   if (key == 'condensed') {
            //     summary = val.toString();
            //   }
            // }
            summary = session.condensed
        }
        return summary
    }

    public getGNLPKeywords(session: Session, language: string = 'nld'): string[] {
        let keywords: string[] = []
        keywords.push('Trefwoorden worden gemaakt...')
        // Enable to get out of construction
        if (session != null && session.keywords != null) {
            // for (const [key, val] of Object.entries(
            //   JSON.parse(session.gnlpOutputs)
            // )) {
            //   if (key == 'keywords') {
            //     keywords = (val as Array<string>).slice(0, 6);
            //   }
            // }
            keywords = (session.keywords as Array<string>).slice(0, 6)
        }

        //clean up keywords
        let charactersToRemove = ['"', '(', ')']
        keywords.forEach((keyword) => {
            charactersToRemove.forEach((char) => {
                keyword.replace(char, '')
            })
        })

        return keywords
    }

    async getSessionText(session: Session): Promise<string> {
        return this.pia3Service.getS3FileAsJson(session.AutoTranscript.transcriptStandardizedS3Key).then((result) => {
            const text = result.phrases.map((phrase) => phrase.text).join(' ')
            // //console.log('getSessionText: ', text);
            return text as string
        })
    }
    //#endregion
}
