import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { ErrorhandlingService } from '../../_common/errorhandling/errorhandling.service'
import { Router } from '@angular/router'
import { Pia3ClientService } from '../pia3-client.service'
import { ModalController } from '@ionic/angular'
import { Client, UpdateClientInput } from '@humain-r/pia3-codegen'

@Component({
    selector: 'pia3-client-create-form',
    templateUrl: './pia3-client-create-form.component.html',
    styleUrls: ['./pia3-client-create-form.component.scss'],
})
export class Pia3ClientCreateFormComponent implements OnInit {
    @Input('clientId') clientId: string = null
    public isReady: boolean = true

    private updateMode: boolean = false

    private client: Client

    clientForm = this.formBuilder.group({
        name: ['', Validators.required],
    })
    constructor(private clientService: Pia3ClientService, private errorhandlerService: ErrorhandlingService, private router: Router, private formBuilder: FormBuilder, private modalController: ModalController) {}

    ngOnInit() {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes['clientId'].currentValue != null && changes['clientId'].currentValue != '') {
            this.loadClient(this.clientId)
        }
    }

    async loadClient(clientId: string) {
        this.isReady = false

        try {
            this.client = (await this.clientService.get(clientId)).data.getClient as Client
        } catch (error) {
            console.log('Could not load client', error)
            this.errorhandlerService.handleGenericGraphQLError(error)
            return
        }

        this.clientForm.controls['name'].setValue(this.client.displayName)

        this.updateMode = true
        this.isReady = true
    }

    async submit() {
        if (!this.clientForm.valid) {
            return
        }

        if (this.updateMode) {
            this.updateClient(this.clientForm.value.name)
        } else {
            this.createNewClient(this.clientForm.value.name)
        }
    }

    async updateClient(clientName: string) {
        let resultClient: Client

        let currentClient: Client

        try {
            currentClient = (await this.clientService.get(this.client.id)).data.getClient as Client
        } catch (error) {
            //console.log("Could not fetch Client", error)
            this.errorhandlerService.handleGenericGraphQLError(error)
        }

        if (this.client._version != currentClient._version) {
            this.client = currentClient
            this.errorhandlerService.presentErrorToast('Fout bij het updaten van de Organisatie. Versie komt niet overeen.')
            return
        }

        let input: UpdateClientInput = {
            id: currentClient.id,
            _version: currentClient._version,
            displayName: clientName,
        }

        try {
            resultClient = await this.clientService.updateClient(input)
        } catch (error) {
            //console.log("Could not create Client", error)
            this.errorhandlerService.handleGenericGraphQLError(error)
        }

        this.modalController.dismiss()
    }

    async createNewClient(clientName: string) {
        // let regexNonletter = /[^a-z ]/gi
        // let regexLongWhitespace = /\s\s+/gi
        // let regexAnyWhitespace = /\s+/gi

        //Turn lowercase, replace non a-z letters with spaces, collapse all whitespace to one space, trim start and end spacs, replace spaces with _
        //let clientCognitoGroupName = 'client_' + clientName.toLowerCase().replace(regexNonletter, ' ').replace(regexLongWhitespace, ' ').trim().replace(regexAnyWhitespace, '_')

        try {
            var resultClient = await this.clientService.creatClient(clientName)
        } catch (error) {
            //console.log("Could not create Client", error)
            this.errorhandlerService.handleGenericGraphQLError(error)
        }

        this.router.navigate(['/clients', resultClient.id])
        this.modalController.dismiss()
    }
}
