import { Injectable } from '@angular/core'
import { queries, mutations, subscriptions, UpdateSpeakerInput, UpdateSpeakerMutation } from '@humain-r/pia3-codegen'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'

import { Observable, BehaviorSubject } from 'rxjs'

import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'
import { Pia3ContextchannelService } from '../contextchannel/pia3-contextchannel.service'

import { GetSpeakerQuery, ListSpeakersQuery, GetClientQuery, SpeakersByClientByLastNameQuery, Speaker } from './../../API.service'

@Injectable({
    providedIn: 'root',
})
export class Pia3SpeakerService {
    constructor(private pia3ContextchannelService: Pia3ContextchannelService) {}

    //#region API Promises

    async get(id: string): Promise<{ data: GetSpeakerQuery }> {
        return API.graphql(graphqlOperation(queries.getSpeaker, { id: id })) as Promise<{ data: GetSpeakerQuery }>
    }

    async updateSpeaker(input: UpdateSpeakerInput): Promise<Speaker> {
        const query = (await API.graphql(graphqlOperation(mutations.updateSpeaker, { input: input }))) as Promise<{ data: UpdateSpeakerMutation }>
        return (await query).data.updateSpeaker as Speaker
    }

    async listSpeakersByClientByLastname(clientID: string, limit: number = 100): Promise<{ data: SpeakersByClientByLastNameQuery }> {
        return API.graphql(
            graphqlOperation(queries.speakersByClientByLastName, {
                clientID: clientID,
                limit: limit,
            })
        ) as Promise<{ data: SpeakersByClientByLastNameQuery }>
    }

    async getContextchannelSpeakersPromiseAll(contextchannelID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let ctxSpeakerConnectionItems = this.pia3ContextchannelService
                .getContextchannel(contextchannelID)
                .then(
                    (result) => {
                        resolve(
                            Promise.all(
                                result.data.getContextchannel.Speakers.items.map((sp) => {
                                    return this.get(sp.speakerID)
                                })
                            )
                        )
                    },
                    (rejected) => {
                        reject(rejected)
                    }
                )
                .catch((error) => {
                    reject(error)
                })
        })
    }

    async getSpeakersByClientId(clientId: string) {
        const query = await (API.graphql(graphqlOperation(queries.getClient, { id: clientId })) as Promise<{ data: GetClientQuery }>)
        return query.data.getClient.Speakers.items as Speaker[]
    }

    //#endregion

    //#region Speaker Helpers

    public getFullname(speaker: Speaker) {
        if (speaker != undefined && speaker != null) {
            let fullName = (speaker.firstName != null ? speaker.firstName : 'Null') + ' '
            fullName += (speaker.infix != null ? speaker.infix : '') + ' '
            fullName += speaker.lastName != null ? speaker.lastName : 'Null'
            return fullName
        } else {
            return 'Undefined'
        }
    }

    public getShortName(speaker: Speaker) {
        if (speaker != undefined && speaker != null) {
            let shortName = speaker.firstName != null ? speaker.firstName.charAt(0) : '0'
            shortName += speaker.lastName != null ? speaker.lastName.charAt(0) : '0'
            return shortName
        } else {
            return '00'
        }
    }

    //#endregion

    //TODO: ListSpeakerByContextchannelByLastname (extend api to include an secondary index for SpeakersByContextchannel) - not a priority as this only leads to performance issues when there are a lot of speakers for each client and we do not provide functionality to edit/control speakers for the contextchannel at the moment (needed later)
    // async listSpeakersByContextchannelByLastname(
    //   clientID: string,
    //   limit: number = 999
    // ): Promise<{ data: SpeakersByClientByLastNameQuery }> {
    //   return API.graphql(
    //     graphqlOperation(queries.SpeakersByClientByLastNameQuery, {
    //       clientID: clientID,
    //       limit: limit,
    //     })
    //   ) as Promise<{ data: SpeakersByClientByLastNameQuery }>;
    // }
}
