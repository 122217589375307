import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core'
import { Router } from '@angular/router'
import { CognitoUser } from '@aws-amplify/auth'
import { PopoverController, IonModal, ModalController } from '@ionic/angular'
import { Pia3AuthenticationService } from '../../authentication/authentication.service'
import { Pia3UserPerformanceChartTranscriptionDataminutesComponent } from '../pia3-user-performance-chart-transcription-dataminutes/pia3-user-performance-chart-transcription-dataminutes.component'
import { Pia3UserStatisticsComponent } from '../../statistics/pia3-statistics-user-performance/pia3-statistics-user-statistics.component'
import { Pia3LocalStorageService } from '../../_common/pia3-local-storage/pia3-local-storage.service'
import { Pia3SessionService } from '../../session/pia3-session.service'

@Component({
    selector: 'pia3-current-user-fab-popover',
    templateUrl: './pia3-current-user-fab-popover.component.html',
    styleUrls: ['./pia3-current-user-fab-popover.component.scss'],
})
export class Pia3CurrentUserFabPopoverComponent implements OnInit {
    @ViewChild(IonModal) statsModal: IonModal
    @Input('senderEvent') senderEvent
    public currentUser: CognitoUser = null
    public userID: string = null
    public isStatsModalOpen: boolean = false
    public isTasksModalOpen: boolean = false

    constructor(public authService: Pia3AuthenticationService, public router: Router, public popoverController: PopoverController, public modalController: ModalController, private localStorageService: Pia3LocalStorageService, private sessionService: Pia3SessionService) {
        this.authService.currentLoggedInUser.subscribe((user) => {
            this.currentUser = user
            try {
                this.userID = user['attributes']['sub']
            } catch (error) {}
        })
    }

    ngOnInit() {}

    setStatsModalOpen(isOpen: boolean) {
        this.isStatsModalOpen = isOpen
    }

    setTasksModalOpen(isOpen: boolean) {
        this.isTasksModalOpen = isOpen
    }

    async openUserSessionBookmark() {
        let sessionBookmark = this.localStorageService.getItem('sessionBookmark')

        try {
            var session = await this.sessionService.getSession(sessionBookmark)
        } catch (error) {
            console.error('Error while trying to get session with bookmark', sessionBookmark)
            return
        }

        var contextChannelId = session.data.getSession.contextchannelID

        if (sessionBookmark && contextChannelId) {
            this.router.navigateByUrl(`/contextchannels/${contextChannelId}/sessions/${sessionBookmark}/transcripts`)
        }
    }

    getUserEmailShort() {
        let userEmailShort: string = null
        try {
            userEmailShort = this.currentUser['attributes'].email.substring(0, this.currentUser['attributes'].email.indexOf('@'))
        } catch (error) {
            userEmailShort = 'Niet beschikbaar'
        }
        return userEmailShort
    }

    async openUserStatsModal() {
        /*const modal = await this.modalController.create({
            component: Pia3UserPerformanceChartTranscriptionDataminutesComponent,
            componentProps: {
                userID: this.userID,
            },
            component: Pia3UserStatisticsComponent
        })
        modal.present()*/
        this.isStatsModalOpen = true
    }

    logout() {
        this.authService.signOutUser().then(() => {
            this.popoverController.dismiss()
        })
    }
}
