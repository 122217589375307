import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { Contextchannel, Speaker } from '@humain-r/pia3-codegen'

import { Pia3ContextchannelService } from '../pia3-contextchannel.service'
import { Pia3SpeakerService } from '../../speaker/pia3-speaker.service'

import { CreateContextchannelSpeakersInput } from '@humain-r/pia3-codegen'

@Component({
    selector: 'pia3-contextchannel-add-speaker',
    templateUrl: './pia3-contextchannel-add-speaker.component.html',
    styleUrls: ['./pia3-contextchannel-add-speaker.component.scss'],
})
export class Pia3ContextchannelAddSpeakerComponent implements OnInit {
    constructor(private contextchannelService: Pia3ContextchannelService, private speakerService: Pia3SpeakerService) {}

    @Input() public contextchannelId: string
    public contextChannel: Contextchannel

    public formLoaded: boolean = false
    public clientSpeakerUI: Map<string, string> = new Map<string, string>()
    public channelSpeakerUI: Map<string, string> = new Map<string, string>()

    public clientSpeakers: Speaker[] = []
    public channelSpeakersIds: string[] = []

    public candidateSpeakerNames: string[] = []
    public candidateSpeakerNamesList: string = ''
    public candidateSpeakerIds: string[] = []

    public canAdd: boolean = false
    public queryInProgress: boolean = false

    ngOnInit() {
        if (this.contextchannelId && this.contextchannelId != '') {
            this.formLoaded = false
            this.setupForm()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName == 'contextchannelId') {
                this.formLoaded = false
                this.setupForm()
            }
        }
    }

    async setupForm() {
        await this.getContextChannel()

        if (this.contextChannel.Client == null && this.contextChannel.clientID) {
            console.error('No client associated with context channel', this.contextChannel.name, this.contextChannel)
            return
        }

        try {
            this.clientSpeakers = await this.speakerService.getSpeakersByClientId(this.contextChannel.Client.id || this.contextChannel.clientID)
        } catch (error) {
            console.error('Problem while quering client speakers', error)
        }

        this.updateClientSpeakers()
        this.displayChannelSpeakers()

        this.formLoaded = true
    }

    async updateClientSpeakers() {
        this.clientSpeakerUI = new Map<string, string>()
        this.clientSpeakers.forEach((speaker) => {
            this.clientSpeakerUI.set(this.fullSpeakerName(speaker), speaker.id)
        })
    }

    async getContextChannel() {
        let channel = (await this.contextchannelService.getContextchannel(this.contextchannelId)).data.getContextchannel as Contextchannel        
        this.channelSpeakersIds = channel.Speakers.items.map((c) => {
            return c.speakerID
        })
        this.contextChannel = channel
    }

    async displayChannelSpeakers() {
        this.channelSpeakerUI = new Map<string, string>()

        for (let i = 0; i < this.contextChannel.Speakers.items.length; i++) {
            let s = this.contextChannel.Speakers.items[i]
            let speaker = this.clientSpeakers.find((e) => e.id == s.speakerID)
            if (speaker != null) {
                this.channelSpeakerUI.set(this.fullSpeakerName(speaker), speaker.id)
            } else {
                //Handle speaker that does not exist in the client
                let fetchedSpeaker = (await this.speakerService.get(s.id)).data.getSpeaker as Speaker
                if (fetchedSpeaker != null) {
                    this.clientSpeakers.push(fetchedSpeaker)
                    this.channelSpeakerUI.set(this.fullSpeakerName(fetchedSpeaker), speaker.id)
                } else {
                    //Handle speaker that does not exist in the client and does not return from a general query
                    this.channelSpeakerUI.set('Removed Speaker ' + s.id, s.id)
                }
            }
        }
    }

    async addSpeakerClick() {
        this.queryInProgress = true
        await this.getContextChannel()

        for (let i = 0; i < this.candidateSpeakerIds.length; i++) {
            let speakerId = this.candidateSpeakerIds[i]

            //Early exit, do not add speakers that are already in the contextchannel
            if (this.channelSpeakersIds.includes(speakerId)) {
                continue
            }

            let queryInput: CreateContextchannelSpeakersInput = {
                contextchannelID: this.contextChannel.id,
                speakerID: speakerId,
            }

            //Add user
            try {
                await this.contextchannelService.addClientSpeakerToContextchannel(queryInput)
            } catch (exception) {
                console.error('Could not add speaker to channel', exception)
            }
        }

        //Update the contextChannel
        await this.getContextChannel()
        this.displayChannelSpeakers()

        //Clean up interface state
        this.candidateSpeakerIds = []
        this.candidateSpeakerNames = []
        this.candidateSpeakerNamesList = ''

        //Free UI
        this.queryInProgress = false
    }

    clientSpeakerClick(speakerName: string) {
        //Dissallow editing the speakers that have been selecting while we are mutating
        if (this.queryInProgress) {
            return
        }

        this.canAdd = false

        if (!this.candidateSpeakerNames.includes(speakerName)) {
            //Add a speaker to the candidates list
            this.candidateSpeakerNames.push(speakerName)
            this.candidateSpeakerIds.push(this.clientSpeakerUI.get(speakerName))
        } else {
            //Remove a speaker from the canidates list
            let rName = this.candidateSpeakerNames.indexOf(speakerName)
            this.candidateSpeakerNames.splice(rName, 1)

            let rId = this.candidateSpeakerIds.indexOf(this.clientSpeakerUI.get(speakerName))
            this.candidateSpeakerIds.splice(rId, 1)
        }

        //If there is only one speaker in the list disable the button if that speaker is already in the channel speakers
        if (this.candidateSpeakerIds.length <= 1) {
            let alreadyAdded = this.contextChannel.Speakers.items.find((s) => s.speakerID == this.candidateSpeakerIds[0])

            if (alreadyAdded == null) {
                this.canAdd = true
            }
        } else {
            this.canAdd = true
        }

        this.candidateSpeakerNamesList = this.candidateSpeakerNames.join(', ')
    }

    //The users has used the floating + button to add a new speaker to the client, we should update the list
    clientSpeakerAdded(speaker: Speaker) {
        this.clientSpeakers.push(speaker)
        this.updateClientSpeakers()
    }

    selectAllButtonClick() {
        this.canAdd = true
        if (this.queryInProgress) {
            return
        }

        let allCandidatesSpeakerNames = []
        let allCandidatesSpeakerIds = []

        this.clientSpeakers.forEach((s) => {
            //Do not add speakers that are already in the contextchannel
            if (this.channelSpeakersIds.includes(s.id)) {
                return;
            }

            allCandidatesSpeakerNames.push(this.fullSpeakerName(s))
            allCandidatesSpeakerIds.push(s.id)
        })

        this.candidateSpeakerNames = allCandidatesSpeakerNames
        this.candidateSpeakerNamesList = this.candidateSpeakerNames.join(', ')
        this.candidateSpeakerIds = allCandidatesSpeakerIds
    }

    selectNoneButtonClick() {
        this.candidateSpeakerNames = []
        this.candidateSpeakerIds = []
        this.canAdd = true
    }

    fullSpeakerName(speaker: Speaker): string {
        return `${speaker.firstName || ''} ${speaker.infix || ''} ${speaker.lastName || ''}`
    }
}
