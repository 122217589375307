<ion-button color="{{ buttonColor }}" *ngIf="sessionID != null" (click)="setIsModalOpen(true)">{{ buttonText }}</ion-button>
<ion-button color="{{ buttonColor }}" disabled="true" *ngIf="sessionID == null">Exporteer niet beschikbaar</ion-button>

<ion-modal [isOpen]="isModalOpen">
    <ng-template>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-title>Transcript exporteren</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="cancel()">sluiten</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding" color="light" style="background-color: White">
            <ion-text>
                <h4 style="padding-bottom: 30px">Uw exports worden gemaakt zodra deze klaar zijn kunt u deze downloaden.</h4>
            </ion-text>
            <ion-item>
                <ion-label>JSON export</ion-label>
                <div *ngIf="jsonExportIsLoading">JSON export wordt gemaakt... <ion-spinner></ion-spinner></div>
                <div *ngIf="jsonExportIsLoaded">
                    <div *ngIf="!jsonExportIsError">
                        <a href="{{ jsonExportDownloadUrl }}" target="_blank">download als JSON</a>
                    </div>
                    <div *ngIf="jsonExportIsError">
                        <ion-text color="danger">{{ jsonExportErrorMessage }}</ion-text>
                    </div>
                </div>
            </ion-item>

            <ion-item>
                <ion-label>TXT export</ion-label>
                <div *ngIf="txtExportIsLoading">TXT export wordt gemaakt... <ion-spinner></ion-spinner></div>
                <div *ngIf="txtExportIsLoaded">
                    <div *ngIf="!txtExportIsError">
                        <a href="{{ txtExportDownloadUrl }}" target="_blank">download als TXT</a>
                    </div>
                    <div *ngIf="txtExportIsError">
                        <ion-text color="danger">{{ txtExportErrorMessage }}</ion-text>
                    </div>
                </div>
            </ion-item>
        </ion-content>
    </ng-template>
</ion-modal>
