import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Session } from './../../../API.service'
import { Pia3ThemeColor } from '../../theme/pia3-theme.service'
import { Pia3SessionCardConfig } from '../pia3-session-card/pia3-session-card.component'

@Component({
    selector: 'pia3-session-category-slider',
    templateUrl: './pia3-session-category-slider.component.html',
    styleUrls: ['./pia3-session-category-slider.component.scss'],
})
export class Pia3SessionCategorySliderComponent implements OnInit {
    //#region INPUTS
    @Input('categoryName') categoryName: string = 'Categoryname not set'
    @Input('categoryNameColor') categoryNameColor: string = 'primary'
    @Input('cardColor') cardColor: Pia3ThemeColor = Pia3ThemeColor.Primary
    @Input('sessions') sessions: Session[]
    @Input('sessionCardConfig') sessionCardConfig: Pia3SessionCardConfig
    @Input('sessionsLoading') sessionsLoading: boolean = false
    @Input('slidesPerViewXs') slidesPerViewXs: number
    @Input('slidesPerViewSm') slidesPerViewSm: number
    @Input('slidesPerViewMd') slidesPerViewMd: number
    @Input('slidesPerViewLg') slidesPerViewLg: number
    @Input('slidesPerViewXl') slidesPerViewXl: number
    @Input('slidesPerViewXXl') slidesPerViewXXl: number
    @Input('ionSliderReachedEndNoMoreData')
    ionSliderReachedEndNoMoreData: boolean = false
    //#endregion

    //#region OUTPUTS
    @Output('ionSliderReachEnd') ionSliderReachEnd: EventEmitter<string> = new EventEmitter<string>(true)
    @Output('ionSliderSessionCardClick')
    ionSliderSessionCardClick: EventEmitter<Session> = new EventEmitter<Session>(true)
    //#endregion

    constructor() {}

    ngOnInit() {}

    ionSliderReachedEnd($event: Event) {
        this.ionSliderReachEnd.emit('Some value')
    }

    ionSliderSessionCardClicked(session: Session) {
        this.ionSliderSessionCardClick.emit(session)
    }
}
