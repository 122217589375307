<!-- Add Contextchannel Fab-->
<ion-fab [vertical]="vertical" [horizontal]="horizontal" [edge]="edge" position="static" slot="fixed">
    <ion-fab-button (click)="openAddContextchannelModal()" color="tertiary">
        <ion-icon name="add"></ion-icon>
    </ion-fab-button>
</ion-fab>
<ion-modal [isOpen]="isModalOpen" (ionModalWillDismiss)="setOpen(false)">
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-title>Contextkanaal toevoegen</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="setOpen(false)">Sluiten</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <pia3-create-context-channel></pia3-create-context-channel>
        </ion-content>
    </ng-template>
</ion-modal>
