<!-- Add speaker fab -->
<ion-fab *ngIf="enableAddSpeakerButton" [vertical]="vertical" [horizontal]="horizontal" [edge]="edge" position="static" slot="fixed">
    <ion-fab-button (click)="setSpeakerModalOpen(true)" color="tertiary">
        <ion-icon name="add"></ion-icon>
    </ion-fab-button>
</ion-fab>

<ion-modal [isOpen]="isSpeakerModalOpen" (ionModalWillDismiss)="setSpeakerModalOpen(false)" (didDismiss)="speakerModalDismissed()">
    <div class="borderBox">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <ion-title>Spreker</ion-title>
                    <ion-buttons slot="end">
                        <ion-button (click)="setSpeakerModalOpen(false)">Sluiten</ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <pia3-speaker-new-client-speaker [clientId]="this.clientId" (speakerAdded)="childSpeakerAdded($event)"></pia3-speaker-new-client-speaker>
            </ion-content>
        </ng-template>
    </div>
</ion-modal>
