import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { Validators, FormBuilder } from '@angular/forms'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'

import { Storage } from 'aws-amplify'
import { Filesystem } from '@capacitor/filesystem'

import { CreateContextchannelInput, Client, UpdateContextchannelInput, Contextchannel } from './../../../API.service'
import { ErrorhandlingService } from '../../_common/errorhandling/errorhandling.service'
import { Pia3ClientService } from '../../client/pia3-client.service'
import { Pia3ContextchannelService } from '../pia3-contextchannel.service'


@Component({
    selector: 'pia3-create-context-channel',
    templateUrl: './pia3-create-context-channel.component.html',
    styleUrls: ['./pia3-create-context-channel.component.scss'],
})
export class Pia3CreateContextChannelComponent implements OnInit {

    @Input("ChannelID") channelID: string //if this is set the form will update an existing channel instead of creating a new one    

    public imagePath: string
    public originalImagePath: string
    public clientID: string = null
    public client: Client

    public oneClient: boolean = false
    public isReady: boolean = false
    public invalidClient: boolean = false
    public invalidImage: boolean = false

    public newImage: boolean = true
    public newClient: boolean = true

    public updateMode: boolean = false

    ctxcForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        language: ['', Validators.required]
    });

    constructor(private modalController: ModalController, private formBuilder: FormBuilder, private pia3ClientService: Pia3ClientService, private pia3ContextchannelService: Pia3ContextchannelService, private pia3ErrorhandlerService: ErrorhandlingService, private router: Router) { }

    async ngOnInit() {
        let clients = (await (this.pia3ClientService.listClients())).data.listClients.items

        if (clients.length == 1) {
            this.oneClient = true
            this.client = clients[0] as Client
            this.clientID = clients[0].id
        }

        this.isReady = true
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes['channelID'].currentValue != null && changes['channelID'].currentValue != "") {
            this.loadContextChannel(this.channelID)
        }
    }

    async loadContextChannel(channelId: string) {
        this.isReady = false
        this.updateMode = true

        this.newClient = false
        this.newImage = false

        let cntxtChannel: Contextchannel

        if (channelId == "") {
            return
        }

        try {
            cntxtChannel = (await this.pia3ContextchannelService.getContextchannel(channelId)).data.getContextchannel as Contextchannel
        } catch (error) {
            //console.error("Error fetching contextchannel details: ", error);
            this.modalController.dismiss()
            this.pia3ErrorhandlerService.handleGenericGraphQLError(error)
            return
        }

        try {
            this.originalImagePath = await Storage.get(cntxtChannel.s3ImageUrl)
            this.imagePath = cntxtChannel.s3ImageUrl
        } catch (error) {
            //console.error("Error processing channel image: ", error);
            this.modalController.dismiss()
            this.pia3ErrorhandlerService.handleGenericError(error)
            return
        }

        this.ctxcForm.controls["name"].setValue(cntxtChannel.name)
        this.ctxcForm.controls["description"].setValue("")
        this.ctxcForm.controls["language"].setValue(cntxtChannel.speechmodelID)

        this.clientID = cntxtChannel.clientID
        this.client = cntxtChannel.Client

        this.isReady = true
    }

    clientSelected($event) {
        this.clientID = $event.id
        this.client = $event

        this.invalidClient = false
    }

    async imageSelected(imagePath) {
        this.imagePath = imagePath
        this.invalidImage = false
    }

    changeClient() {
        this.client = null
        this.clientID = ""

        this.newClient = true
    }

    changeImage() {
        this.newImage = true
    }

    async submit() {
        if (!this.ctxcForm.valid) {
            return
        }

        if (this.client == null) {
            this.invalidClient = true
            return
        }

        if (this.imagePath == null || this.imagePath == "") {
            this.invalidImage = true
            return
        }

        let input: CreateContextchannelInput = {
            name: this.ctxcForm.value.name,
            speechmodelID: this.ctxcForm.value.language,
            clientID: this.clientID
        }

        let contextChannelLanguageID = ""
        if (this.ctxcForm.value.language == "pipelinetemplate_nld") {
            contextChannelLanguageID = "98766ed5-db23-4c97-be79-2b0ddf20c399"
        } else if (this.ctxcForm.value.language == "pipelinetemplate_fry") {
            contextChannelLanguageID = "fe601669-c4f4-41ff-9cfa-574feca3bf6e"
        } else {
            contextChannelLanguageID = ""
        }

        if (this.updateMode) {
            await this.updateContextChannel(this.channelID, input, contextChannelLanguageID, this.imagePath)
        } else {
            await this.createNewContextChannel(input, contextChannelLanguageID, this.imagePath)
        }

    }

    async updateContextChannel(channelId: string, partialInput: CreateContextchannelInput, languageId: string, thumbnailPath: string) {
        //Get current context channel state, for versioning
        let cntxChannelVersion = 0

        try {
            let cntxtChannel = (await this.pia3ContextchannelService.getContextchannel(channelId)).data.getContextchannel
            cntxChannelVersion = cntxtChannel._version
        } catch (error) {
            //console.error("Error uploading contextchannel thumbnail: ", error);
            this.modalController.dismiss()
            this.pia3ErrorhandlerService.handleGenericGraphQLError(error)
            return
        }

        let input: UpdateContextchannelInput = {
            id: channelId,
            name: partialInput.name,
            _version: cntxChannelVersion
        }

        if (this.newImage) {
            //Upload Image
            try {
                let s3ImagePath = await this.uploadChannelThumbnail(thumbnailPath, channelId)
                input['s3ImageUrl'] = s3ImagePath

            } catch (error) {
                //console.error("Error uploading contextchannel thumbnail: ", error);
                this.modalController.dismiss()
                this.pia3ErrorhandlerService.handleGenericError(error)
                return
            }
        }

        //Update Context Channel      
        try {
            await this.pia3ContextchannelService.updateContextchannel(input)

        } catch (error) {
            //console.error("Error updating context channel", error)
            this.modalController.dismiss()
            this.pia3ErrorhandlerService.handleGenericGraphQLError(error)
            return
        }

        this.modalController.dismiss()
    }

    async createNewContextChannel(input: CreateContextchannelInput, languageId: string, thumbnailPath: string) {
        let resultContextchannelId: string
        let s3ImagePath: string

        //Create Context Channel
        try {
            let createContextchannelResult = await this.pia3ContextchannelService.createContextchannel(input)
            resultContextchannelId = createContextchannelResult.id
        } catch (error) {
            //console.error("Error creating context channel:", error)
            this.pia3ErrorhandlerService.handleGenericGraphQLError(error)
            this.modalController.dismiss()
            return
        }

        //Upload Image
        try {
            s3ImagePath = await this.uploadChannelThumbnail(thumbnailPath, resultContextchannelId)
        } catch (error) {
            //console.error("Error uploading contextchannel thumbnail: ", error);
            this.modalController.dismiss()
            this.pia3ErrorhandlerService.handleGenericError(error)
            return
        }

        //Update Context Channel Image Path        
        try {
            await this.pia3ContextchannelService.updateContextchannelImage(resultContextchannelId, s3ImagePath)

        } catch (error) {
            //console.error("Error changing image of context channel", error)
            this.modalController.dismiss()
            this.pia3ErrorhandlerService.handleGenericGraphQLError(error)
            return
        }

        //redirect to newly created context channel
        this.modalController.dismiss()
        this.router.navigate(['/contextchannels', resultContextchannelId])

        //Todo: pop up add speaker modal
    }

    async uploadChannelThumbnail(thumbnailPath: string, contextChannelId: string): Promise<string> {
        //Get Context Channel Image from FileSystem
        let imageData = await Filesystem.readFile({
            path: thumbnailPath,
        });

        //Wrangle Base64 string into a Blob
        let imageFileName = thumbnailPath.split("/").slice(-1)
        let s3ImagePath = `${this.clientID}/${contextChannelId}/${imageFileName}`

        var image = new Image();
        image.src = 'data:image/png;base64,' + imageData.data;

        //Let the browser be responsible for converting from base64 encoded string to actual png image
        document.body.appendChild(image)
        let imageBlob = await (fetch(image.src).then((res) => res.blob()))
        document.body.removeChild(image)

        //Upload Context Channel Image
        await Storage.put(s3ImagePath, imageBlob, {
            contentType: "image/png",
        });

        return s3ImagePath
    }
}
