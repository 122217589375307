import { Component, Input, OnInit } from '@angular/core';
import { ErrorhandlingService } from '../../_common/errorhandling/errorhandling.service';
import { Pia3SessionService } from '../pia3-session.service';
import { Pia3AuthenticationService, Pia3CognitoRole } from '../../authentication/authentication.service';


@Component({
  selector: 'pia3-session-title-editable',
  templateUrl: './pia3-session-title-editable.component.html',
  styleUrls: ['./pia3-session-title-editable.component.scss'],
})
export class Pia3SessionTitleEditableComponent implements OnInit {

  @Input() public titleText: string
  @Input() public sessionId: string

  public editMode: boolean
  public mutationMode: boolean
  public allowedToEdit: boolean

  private originalTitleText: string = ""

  constructor(private errorHandlerService: ErrorhandlingService, private sessionService: Pia3SessionService, private authService: Pia3AuthenticationService) { }

  ngOnInit() {
    this.allowedToEdit = this.authService.hasAnyPia3Role([Pia3CognitoRole.SUPERADMIN, Pia3CognitoRole.DATA_TEAMMANAGER])
  }

  editButtonclick(event: Event) {
    this.editMode = true

    //The titleText is bound to the input, so save a copy of the current state to check against for changes later
    this.originalTitleText = this.titleText
  }

  onEnter(event: Event) {
    let keyBoardEvent = event as KeyboardEvent

    if (keyBoardEvent.code != "Enter") {
      return
    }

    this.editMode = false

    //Nothing Changed
    if (this.titleText == this.originalTitleText) {
      return

    }

    this.updateTitle(this.sessionId, this.titleText)

  }

  titleEdited(event: Event) {
    this.editMode = false

    //Nothing Changed
    if (this.titleText == this.originalTitleText) {
      return
    }

    this.updateTitle(this.sessionId, this.titleText)
  }


  async updateTitle(sessionId: string, title: string) {
    this.mutationMode = true
    let sessionVersion = 0
    let sessionName = ""

    //Fetch the current version of the session
    try {
      let result = (await this.sessionService.getSession(sessionId)).data.getSession

      sessionVersion = result._version
      sessionName = result.name

    } catch (error) {
      this.errorHandlerService.handleGenericGraphQLError(error)

      this.titleText = this.originalTitleText
      this.editMode = false
      this.mutationMode = false
      return
    }

    //Should the sessions name have been changed between loading the component and now, bail on editing and update to the new name
    if (sessionName != this.originalTitleText) {
      this.titleText = sessionName
      this.editMode = false
      this.mutationMode = false
      return
    }

    //Update the name
    try {
      let result = await this.sessionService.updateSessionName(sessionId, sessionVersion, title)

      this.titleText = result.name
    }
    catch (error) {
      this.errorHandlerService.handleGenericGraphQLError(error)

      this.titleText = sessionName
    } finally {
      this.mutationMode = false
      this.editMode = false
    }
  }
}
