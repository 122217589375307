<div *ngIf="!isReady">
    <div class="ion-margin">
        <ion-title>Even Wachten A.U.B.</ion-title>
    </div>
</div>

<div *ngIf="isReady">
    <form [formGroup]="clientForm" (ngSubmit)="submit()">
        <ion-list>
            <ion-item lines="full">
                <ion-label position="fixed">* Naam</ion-label>
                <ion-input placeholder="Voer een naam in..." color="primary" type="text" maxlength="30" minlength="2" clearInput="true" formControlName="name"></ion-input>
            </ion-item>
        </ion-list>

        <ion-row>
            <ion-col>
                <ion-text> * Dit is een verplicht veld. </ion-text>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-button [disabled]="!clientForm.valid" class="ion-margin" expand="block" type="submit">verzenden</ion-button>
            </ion-col>
        </ion-row>
    </form>
</div>
