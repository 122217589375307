<!-- SINGLE speechmodel SELECTOR -->
<!-- Enable single selector if selectorMode is SINGLE -->
<div *ngIf="selectorMode == 'SINGLE'">
    <ion-select slot="end" placeholder="Select" (ionChange)="speechmodelChanged($event)" *ngIf="speechmodels != undefined && speechmodels.length > 0 && selectorMode == 'SINGLE'" interface="popover">
        <ion-select-option *ngFor="let speechmodel of speechmodels" [value]="speechmodel">{{ speechmodel.name }} {{ speechmodel.Language.name }}</ion-select-option>
    </ion-select>
</div>

<!-- MULTI speechmodelS SELECTOR -->
<!-- Enable multi selector if selectorMode is MULTI -->
<div *ngIf="selectorMode == 'MULTI'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal-speechmodel"
            ><strong>Select</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal-speechmodel" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>speechmodels</strong></ion-label>
                    </div>
                </ion-toolbar>
            </ion-header>

            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent" [indeterminate]="indeterminateState" (click)="selectAllspeechmodels()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let s of resultsOfSearch">
                        <ion-label>{{ s.speechmodel.connectionString + ' ' + s.speechmodel.Language.name }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="s.isSpeechModelChecked" (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>
<!-- show selection multi -->
<div *ngIf="showSpeechModels !== null">
    <ion-item *ngFor="let s of showSpeechModels; let i = index">
        <ion-icon color="primary" name="person-circle"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ s.connectionString + ' ' + s.Language.name }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small" (click)="removeSelectionMulti(i)"></ion-icon>
    </ion-item>
</div>
<hr class="solid" *ngIf="showSpeechModels !== null" />

<!-- MULTI speechmodelS SELECTOR WITH SEARCH-->
<!-- Enable multi speechmodel selector with search if selectorMode is MULTI_WITH_SEARCH -->
<div *ngIf="selectorMode == 'MULTI_WITH_SEARCH'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal-speechmodel"
            ><strong>Select</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal-speechmodel" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>Speechmodels</strong></ion-label>
                    </div>

                    <ion-searchbar [debounce]="500" (ionChange)="handleChange($event)"></ion-searchbar>
                </ion-toolbar>
            </ion-header>
            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent" [indeterminate]="indeterminateState" (click)="selectAllspeechmodels()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let s of resultsOfSearch">
                        <ion-label>{{ s.speechmodel.connectionString + ' ' + s.speechmodel.Language.name }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="s.isSpeechModelChecked" (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>
<!-- show selection multi with search -->
<div *ngIf="showSpeechModelsWithSearch !== null">
    <ion-item *ngFor="let s of showSpeechModelsWithSearch; let i = index">
        <ion-icon color="primary" name="person-circle"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ s.connectionString + ' ' + s.Language.name }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small" (click)="removeSelectionMultiWithSearch(i)"></ion-icon>
    </ion-item>
</div>
<!-- <ion-item-divider *ngIf="showspeechmodelsWithSearch !== null"></ion-item-divider> -->
<hr class="solid" *ngIf="showSpeechModelsWithSearch !== null" />
