import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Session } from './../../../API.service'
import { Pia3ThemeColor } from '../../theme/pia3-theme.service'
import { Pia3SessionCardConfig } from '../pia3-session-card/pia3-session-card.component'

@Component({
    selector: 'pia3-session-list',
    templateUrl: './pia3-session-list.component.html',
    styleUrls: ['./pia3-session-list.component.scss'],
})
export class Pia3SessionListComponent implements OnInit {
    public _sessionCardConfig: Pia3SessionCardConfig = {
        showAudioplayer: false,
        showVideoplayer: false,
        showTitle: false,
        showSummary: false,
        showKeywords: false,
        showContextLabel: false,
        showSpeakerAvatar: false,
        cardColor: Pia3ThemeColor.Light,
        contextLabelColor: Pia3ThemeColor.Light,
        audioplayerConfig: {
            autoplay: false,
            autoplayOnHover: true,
            showControls: false,
        },
        videoplayerConfig: {
            autoplay: false,
            showControls: false,
        },
    }

    @Input('sessions') sessions: Session[]
    @Input('sessionsLoading') sessionsLoading: boolean = false
    @Input('sessionCardConfig') public set sessionCardConfig(sessionCardConfig: Pia3SessionCardConfig) {
        if (sessionCardConfig != null) {
            this._sessionCardConfig = sessionCardConfig
        }
    }

    @Output('sessionClick')
    sessionClick: EventEmitter<Session> = new EventEmitter<Session>(true)

    constructor() {}

    ngOnInit() {}

    sessionClicked($event) {
        this.sessionClick.emit($event)
    }
}
