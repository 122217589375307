import { Injectable } from '@angular/core'
import { NLPStep, UpdateNLPStepInput, UpdateNLPStepLanguagesMutation, UpdateNLPStepMutation } from '../../API.service'
import * as mutations from './../../graphql/mutations'
import { Pia3ContextchannelService } from './../contextchannel/pia3-contextchannel.service'
import { API, graphqlOperation } from 'aws-amplify'

@Injectable({
    providedIn: 'root',
})
export class Pia3NlpStepService {
    constructor(private ctxService: Pia3ContextchannelService) {}

    async getContextchannelNLPSteps(contextchannelId: string) {
        return (await this.ctxService.getContextchannel(contextchannelId)).data.getContextchannel.NLPSteps.items
    }

    async addNLPStep(contextchannelId: string, nlpStep: NLPStep) {
        alert('addNLPStep not implemented yet')
    }

    async updateNLPStepName(nlpStep: NLPStep, newName: string) {
        let input: UpdateNLPStepInput = {
            id: nlpStep.id,
            name: newName,
            _version: nlpStep._version,
        }
        //console.log(input)
        return API.graphql(graphqlOperation(mutations.updateNLPStep, { input: input })) as Promise<{ data: UpdateNLPStepMutation }>
    }
}
