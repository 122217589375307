import { Injectable } from '@angular/core'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import { GetClientQuery, ListClientsQuery, Client, CreateClientInput, queries, mutations, subscriptions, CreateClientMutation, UpdateClientMutation, UpdateClientInput, CreateSpeakerMutation, CreateSpeakerInput, Speaker } from '@humain-r/pia3-codegen'
import { Observable, BehaviorSubject } from 'rxjs'
import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'
import { Pia3ContextchannelService } from '../contextchannel/pia3-contextchannel.service'

@Injectable({
    providedIn: 'root',
})
export class Pia3ClientService {
    constructor(private pia3ContextchannelService: Pia3ContextchannelService) {}

    //#region API Promises

    async get(id: string): Promise<{ data: GetClientQuery }> {
        return API.graphql(graphqlOperation(queries.getClient, { id: id })) as Promise<{ data: GetClientQuery }>
    }

    async listClients(limit: number = 100): Promise<{ data: ListClientsQuery }> {
        return API.graphql(
            graphqlOperation(queries.listClients, {
                limit: limit,
            })
        ) as Promise<{ data: ListClientsQuery }>
    }

    async getContextchannelClientsPromiseAll(contextchannelID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let ctxClientConnectionItems = this.pia3ContextchannelService
                .getContextchannel(contextchannelID)
                .then(
                    (result) => {
                        resolve(result.data.getContextchannel.Client)
                    },
                    (rejected) => {
                        reject(rejected)
                    }
                )
                .catch((error) => {
                    reject(error)
                })
        })
    }

    async creatClient(clientName: string): Promise<Client> {
        let createClientInput: CreateClientInput = {
            displayName: clientName,
        }

        const query = await (API.graphql(graphqlOperation(mutations.createClient, { input: createClientInput })) as Promise<{ data: CreateClientMutation }>)

        return query.data.createClient as Client
    }

    async updateClient(input: UpdateClientInput): Promise<Client> {
        const query = await (API.graphql(graphqlOperation(mutations.updateClient, { input: input })) as Promise<{ data: UpdateClientMutation }>)

        return query.data.updateClient
    }

    async addSpeakerToClient(input: CreateSpeakerInput): Promise<Speaker> {
        const query = await (API.graphql(graphqlOperation(mutations.createSpeaker, { input: input })) as Promise<{ data: CreateSpeakerMutation }>)

        return query.data.createSpeaker as Speaker
    }

    //#endregion

    //#region Client Helpers

    public getFullname(client: Client) {
        if (client != undefined && client != null) {
            let fullName = client.displayName != null ? client.displayName : 'No name set'
            return fullName
        } else {
            return 'Undefined'
        }
    }

    public getShortName(Client: Client) {
        if (Client != undefined && Client != null) {
            let shortName = Client.displayName != null ? Client.displayName.charAt(0) : '0'
            return shortName
        } else {
            return '00'
        }
    }

    //#endregion

    //TODO: ListClientByContextchannelByLastname (extend api to include an secondary index for ClientsByContextchannel) - not a priority as this only leads to performance issues when there are a lot of Clients for each client and we do not provide functionality to edit/control Clients for the contextchannel at the moment (needed later)
    // async listClientsByContextchannelByLastname(
    //   clientID: string,
    //   limit: number = 999
    // ): Promise<{ data: ClientsByClientByLastNameQuery }> {
    //   return API.graphql(
    //     graphqlOperation(queries.ClientsByClientByLastNameQuery, {
    //       clientID: clientID,
    //       limit: limit,
    //     })
    //   ) as Promise<{ data: ClientsByClientByLastNameQuery }>;
    // }
}
