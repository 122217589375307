<ion-item [lines]="cropImgPreview === '' ? 'full' : 'none'">
  <p>
    <code>{{ message }}</code>
  </p>
  <ion-buttons slot="end">
    <ion-button color="primary" id="open-modal-cropper" (click)="present()"><strong>Select</strong>
      <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
    </ion-button>
  </ion-buttons>
</ion-item>

<ion-item lines="full" *ngIf="cropImgPreview !== '' ? true : false">
  <ion-img class="image-preview ion-margin-bottom" slot="end" [src]="cropImgPreview" alt="A image"></ion-img>
</ion-item>

<!-- modal -->
<ion-content>
  <ion-modal trigger="open-modal-cropper" (willDismiss)="onWillDismiss($event)">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <div class="ion-text-center ion-margin">
            <ion-label><strong>Image Cropper</strong></ion-label>
          </div>
        </ion-toolbar>
      </ion-header>
      <!-- <ion-content scrollY="false"> -->
      <ion-list>
        <ion-item lines="none">
          <ion-input type="file" (change)="onFileChange($event)"></ion-input>
        </ion-item>

        <ion-item class="image-cropper-item" lines="none">
          <image-cropper class="image-cropper" [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true"
            [aspectRatio]="16 / 10" [resizeToWidth]="256" format="png" (imageCropped)="cropImg($event)"
            (imageLoaded)="imgLoad()" (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()"
            [cropper]="currentPosition">
          </image-cropper>
        </ion-item>
      </ion-list>
      <!-- </ion-content> -->
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ng-template>
  </ion-modal>
</ion-content>