import { Component, Input, OnInit } from '@angular/core'
import { Pia3ThemePositionHorizontal, Pia3ThemePositionVertical } from '../../theme/pia3-theme.service'
import { Pia3AuthenticationService, Pia3CognitoRole } from '../../authentication/authentication.service'

@Component({
    selector: 'pia3-client-add-popup-button',
    templateUrl: './pia3-client-add-popup-button.component.html',
    styleUrls: ['./pia3-client-add-popup-button.component.scss'],
})
export class Pia3ClientAddPopupButtonComponent implements OnInit {
    @Input('clientId') clientId: string
    @Input('horizontal') horizontal: Pia3ThemePositionHorizontal = Pia3ThemePositionHorizontal.End // Enum:Pia3ThemePositionHorizontal start, center, end
    @Input('vertical') vertical: Pia3ThemePositionVertical = Pia3ThemePositionVertical.Bottom // Enum:Pia3ThemePositionVertical top, center, bottom
    @Input('edge') edge: boolean = false

    public isModalOpen = false
    public enableAddClientButton: boolean = false

    constructor(private pia3AuthenticationService: Pia3AuthenticationService) {
        this.enableAddClientButton = this.pia3AuthenticationService.hasAnyPia3Role([Pia3CognitoRole.CLIENT_MANAGER, Pia3CognitoRole.SUPERADMIN])
    }

    ngOnInit() {}

    openAddClientModal() {
        this.setOpen(true)
    }

    setOpen(isOpen: boolean) {
        this.isModalOpen = isOpen
    }
}
