import { ModalController } from '@ionic/angular'
import { Router } from '@angular/router'
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { Client, CreateSpeakerInput, Speaker, Gender, UpdateSpeakerInput } from '@humain-r/pia3-codegen'

import { ErrorhandlingService } from '../../_common/errorhandling/errorhandling.service'
import { Pia3ClientService } from '../../client/pia3-client.service'
import { Pia3SpeakerService } from '../pia3-speaker.service'

@Component({
    selector: 'pia3-speaker-new-client-speaker',
    templateUrl: './pia3-speaker-new-client-speaker.component.html',
    styleUrls: ['./pia3-speaker-new-client-speaker.component.scss'],
})
export class Pia3SpeakerNewClientSpeakerComponent implements OnInit {
    @Input() clientId: string
    @Input() speakerId: string

    @Output() speakerAdded: EventEmitter<Speaker> = new EventEmitter()
    @Output() speakerChanged: EventEmitter<Speaker> = new EventEmitter()

    public clientIsReady: boolean = false
    public speakerIsReady: boolean = false
    public updateMode: boolean = false

    private client: Client
    private speaker: Speaker

    speakerForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        gender: ['', Validators.required],
    })

    constructor(private speakerService: Pia3SpeakerService, private clientService: Pia3ClientService, private errorhandlerService: ErrorhandlingService, private router: Router, private formBuilder: FormBuilder, private modalController: ModalController) {}

    ngOnInit() {}

    async ngOnChanges(changes: SimpleChanges) {
        if (!changes) {
            return
        }

        if (changes['speakerId'] && changes['speakerId'].currentValue != null && changes['speakerId'].currentValue != '') {
            this.loadSpeaker(this.speakerId)
        }

        if (changes['clientId'] && changes['clientId'].currentValue != null && changes['clientId'].currentValue != '') {
            this.loadClient(this.clientId)
        }
    }

    async loadSpeaker(speakerId: string) {
        this.updateMode = true
        this.speakerIsReady = false

        try {
            this.speaker = (await this.speakerService.get(speakerId)).data.getSpeaker as Speaker

            this.clientId = this.speaker.clientID
            this.client = (await this.clientService.get(this.clientId)).data.getClient as Client

            this.speakerIsReady = true
            this.clientIsReady = true
        } catch (error) {
            // console.log('Could not load speaker', error)
            this.errorhandlerService.handleGenericGraphQLError(error)
            this.speakerIsReady = false
        }

        this.speakerForm.setValue({ firstName: this.speaker.firstName, lastName: this.speaker.lastName, gender: this.speaker.gender })
    }

    async loadClient(clientId: string) {
        try {
            this.client = (await this.clientService.get(clientId)).data.getClient as Client
            this.clientIsReady = true
        } catch (error) {
            // console.log('Could not load client', error)
            this.errorhandlerService.handleGenericGraphQLError(error)
            this.clientIsReady = false
        }
    }

    async submit() {
        if (!this.speakerForm.valid) {
            return
        }

        if (!this.updateMode) {
            await this.createNewSpeakerOnClient()
        } else {
            await this.updateSpeaker()
        }
    }

    async updateSpeaker() {
        let input: UpdateSpeakerInput = {
            id: this.speaker.id,
            _version: this.speaker._version,
            firstName: this.speakerForm.value['firstName'].trim(),
            lastName: this.speakerForm.value['lastName'].trim(),
            gender: Gender.other,
        }

        switch (this.speakerForm.value['gender']) {
            case 'male':
                input.gender = Gender.male
                break

            case 'female':
                input.gender = Gender.female
                break

            default:
            case 'other':
                input.gender = Gender.other
                break
        }

        try {
            let speaker = await this.speakerService.updateSpeaker(input)

            let currentSpeaker = (await this.speakerService.get(speaker.id)).data.getSpeaker as Speaker
            this.speakerChanged.emit(currentSpeaker)
        } catch (error) {
            this.errorhandlerService.handleGenericGraphQLError(error)
            return
        }
    }

    async createNewSpeakerOnClient() {
        let input: CreateSpeakerInput = {
            firstName: this.speakerForm.value['firstName'].trim(),
            lastName: this.speakerForm.value['lastName'].trim(),
            gender: Gender.other,
            clientID: this.client.id,
        }

        switch (this.speakerForm.value['gender']) {
            case 'male':
                input.gender = Gender.male
                break

            case 'female':
                input.gender = Gender.female
                break

            default:
            case 'other':
                input.gender = Gender.other
                break
        }

        try {
            let speaker = (await this.clientService.addSpeakerToClient(input)) as Speaker
            this.speakerAdded.emit(speaker)
        } catch (error) {
            this.errorhandlerService.handleGenericGraphQLError(error)
            return
        }
    }
}
