import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input,} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'pia3-accordion',
  templateUrl: './pia3-accordion.component.html',
  styleUrls: ['./pia3-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),
  ],
})

export class Pia3AccordionComponent implements AfterViewInit {

  expanded = new Set<number>();
  @Input() title: string = ""  
  @Input() disabled: boolean = false
  @Input() toggleState: boolean = false

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {

  }

  toggleAccordionState()
  {
    if(!this.disabled)
    {
      this.toggleState = !this.toggleState;
       
    }
  }
}