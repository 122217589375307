import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Router } from '@angular/router'
import { ErrorhandlingService } from './../_common/errorhandling/errorhandling.service'

export enum Pia3CognitoRole {
    CLIENT_SUPPLIER = '_client_supplier',
    CLIENT_USER = '_client_user',
    DATA_AGENT = '_data_agent',
    DATA_TEAMMANAGER = '_data_teammanager',
    DATA_VALIDATOR = '_data_validator',
    CLIENT_MANAGER = '_data_clientmanager ',
    CONTENT_MANAGER = '_data_contentmanager',
    SUPERADMIN = '_superadmin',
}

@Injectable({
    providedIn: 'root',
})
export class Pia3AuthenticationService {
    private _currentLoggedInUser: BehaviorSubject<CognitoUser> = new BehaviorSubject(null)

    public readonly currentLoggedInUser: Observable<CognitoUser> = this._currentLoggedInUser.asObservable()

    private _currentLoggedInUserRoles: BehaviorSubject<[string]> = new BehaviorSubject(null)

    public readonly currentLoggedInUserRoles: Observable<[string]> = this._currentLoggedInUserRoles.asObservable()

    private _loginPath: BehaviorSubject<string> = new BehaviorSubject(null)

    public readonly loginPath: Observable<string> = this._loginPath.asObservable()

    private _loginRedirectPath: BehaviorSubject<string> = new BehaviorSubject(null)

    public readonly loginRedirectPath: Observable<string> = this._loginRedirectPath.asObservable()

    constructor(public router: Router, public errorHandlingService: ErrorhandlingService) {
        this.currentLoggedInUser.subscribe(this.currentLoggedInUserSubscription)
        this.loginPath.subscribe(this.loginPathSubscription)
        this.loginRedirectPath.subscribe(this.loginRedirectPathSubscription)
        this.getCurrentSignedInUser()
    }

    setLoginPath(path: string) {
        this._loginPath.next(path)
    }

    setLoginRedirectPath(path: string) {
        this._loginRedirectPath.next(path)
    }

    async getCurrentSignedInUser() {
        Auth.currentAuthenticatedUser()
            .then(
                (fulfilled) => {
                    this._currentLoggedInUser.next(fulfilled)
                },
                (reject) => {
                    this.router.navigateByUrl(this._loginPath.value)
                    this.errorHandlingService.handleGenericError(reject)
                }
            )
            .catch((error) => {
                alert('reject auth' + this._loginPath.getValue())
                this.router.navigateByUrl(this._loginPath.value)
                this.errorHandlingService.handleGenericError(error)
            })
    }

    async signInUser(email: string, password: string) {
        return new Promise((resolve, reject) => {
            try {
                Auth.signIn(email, password)
                    .then(
                        (onfulfilled) => {
                            this._currentLoggedInUser.next(onfulfilled)
                            resolve(onfulfilled)
                        },
                        (onreject) => {
                            this.errorHandlingService.handleGenericError(onreject)
                            reject(onreject)
                        }
                    )
                    .catch((error) => {
                        this.errorHandlingService.handleGenericError(error)
                        reject(error)
                    })
            } catch (error) {
                return reject(error)
            }
        })
    }

    async signOutUser() {
        try {
            Auth.signOut()
                .then((onfulfilled) => {
                    this._currentLoggedInUser.next(null)
                }, this.errorHandlingService.handleGenericError)
                .catch(this.errorHandlingService.handleGenericError)
        } catch (error) {
            this.errorHandlingService.handleGenericError(error)
        }
    }

    //#region Subscriptions

    loginPathSubscription = (path: string) => {}

    loginRedirectPathSubscription = (path: string) => {}

    currentLoggedInUserSubscription = (user) => {
        ////console.log('Subscription:Auth_CurrentLoggedInUser changed:', user);
        if (user == null) {
            this.router.navigateByUrl(this._loginPath.getValue())
        } else {
            this.setCurrentUserRoles(user)
            this.router.navigateByUrl(this._loginRedirectPath.getValue())
        }
    }

    //#endregion

    //#region User Roles

    setCurrentUserRoles(user: CognitoUser) {
        if (user == null) {
            this._currentLoggedInUserRoles.next(null)
        } else {
            let userSession: CognitoUserSession = user.getSignInUserSession()
            let roles: [string] = userSession.getIdToken().payload['cognito:groups']
            this._currentLoggedInUserRoles.next(roles)
        }
    }

    hasPia3Role(role: Pia3CognitoRole): boolean {
        let userRoles = this._currentLoggedInUserRoles.getValue()
        //console.log(userRoles)
        let hasRole = false
        userRoles.forEach((userRole) => {
            if (userRole == role) {
                hasRole = true
            }
        })
        return hasRole
    }

    hasAnyPia3Role(roles: Pia3CognitoRole[]): boolean {
        let hasAnyPia3Role = roles.length == 0 ? true : false
        roles.forEach((role) => {
            //console.log(role)
            if (this.hasPia3Role(role)) {
                hasAnyPia3Role = true
            }
        })
        return hasAnyPia3Role
    }

    //#endregion
}
