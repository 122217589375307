import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core'
import { Router, Routes, RouterOutlet, Event, RouterEvent, NavigationEnd } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'

import { Hub, Logger } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { DataStore, Predicates } from 'aws-amplify'
import { isDevMode } from '@angular/core'

import { routes } from './app-routing.module'
import { AuthService } from './auth/auth.service'
import { GenericErrorHandlerService } from './.common/services/generic-error-handler.service'
import { Session, Client, Language, LanguageDialect } from 'src/app/API.service'
import { CognitoUser } from '@aws-amplify/auth'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { PiaMediaEventSource, PiaMediaEventsService } from './.common/services/pia-media-events.service'
import { Pia3Service, Pia3AuthenticationService, Pia3CognitoRole } from 'pia3'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    @ViewChild(RouterOutlet) outlet: RouterOutlet

    public currentLoggedInUser: CognitoUser = null
    public currentLoggedInUser$ = new BehaviorSubject<CognitoUser>(this.currentLoggedInUser)
    public isInitialized: boolean = false
    public showShortcuts: boolean = false
    public showDocumentation: boolean = false
    sectionRoutes: Routes = routes
    isFullscreenRoute: boolean = false
    defaultRedirectRoute: string = '/contextchannels'

    private currentHotkeys: Hotkey[] = []

    // public logger = new Logger('My-Logger')

    // public listener = (data) => {
    //     switch (data.payload.event) {
    //         case 'signIn':
    //             this.logger.info('user signed in')
    //             break
    //         case 'signUp':
    //             this.logger.info('user signed up')
    //             break
    //         case 'signOut':
    //             this.logger.info('user signed out')

    //             break
    //         case 'signIn_failure':
    //             this.logger.error('user sign in failed')
    //             break
    //         case 'tokenRefresh':
    //             this.logger.info('token refresh succeeded')
    //             break
    //         case 'tokenRefresh_failure':
    //             this.logger.error('token refresh failed')
    //             break
    //         case 'configured':
    //             this.logger.info('the Auth module is configured')
    //     }
    //     this.logger.info(data.payload)
    // }

    constructor(private pia3Service: Pia3Service, private router: Router, public pia3AuthService: Pia3AuthenticationService, public hotkeyService: HotkeysService, private genericErrorHandlerService: GenericErrorHandlerService, public piaMediaEventsService: PiaMediaEventsService) {
        //Convenient default redirect override for dev mode only
        if (isDevMode) {
            //this.defaultRedirectRoute = '/contextchannels/fd803a20-8f46-4349-872b-df0928e82558/sessions/b47caee9-5e74-46f3-aa29-2e4873a19dcc/transcripts'
        }

        this.pia3Service.setupPia3()

        this.pia3AuthService.setLoginPath('/auth/login')
        this.pia3AuthService.setLoginRedirectPath(this.defaultRedirectRoute)
    }

    ngOnInit() {
        this.isInitialized = true
        this.pia3AuthService.currentLoggedInUser.subscribe(this.currentLoggedInUserChanged)
        this.attachHotkeys()
    }

    currentLoggedInUserChanged(user) {
        if (this.isInitialized) {
            if (user == null) {
                ////console.log('currentLoggedInUserChanged on app.component.ts', user)
                this.router.navigateByUrl('auth/login')
            } else {
                ////console.log('currentLoggedInUserChanged on app.component.ts', user)
                this.currentLoggedInUser = user
                this.currentLoggedInUser$.next(this.currentLoggedInUser)
                this.router.navigateByUrl(this.defaultRedirectRoute)
            }
        }
    }

    attachHotkeys() {
        let elementsWithHotkeysEnabled: Array<string> = ['BODY', 'ION-ITEM', 'TEXTAREA', 'DIV']

        //CTRL+?: PIAMEDIAEVENT:SHOW HOTKEY CHEATSHEET
        this.addHotkey(
            new Hotkey(
                'ctrl+shift+x',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+x pressed on app')
                    this.showShortcuts = !this.showShortcuts
                    return false
                },
                elementsWithHotkeysEnabled,
                'Laat deze pagina zien.'
            )
        )

        //CTRL+?: PIAMEDIAEVENT:SHOW HOTKEY CHEATSHEET
        this.addHotkey(
            new Hotkey(
                '?',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+x pressed on app')
                    this.showShortcuts = !this.showShortcuts
                    return false
                },
                ['BODY', 'DIV'],
                'Laat deze pagina zien.'
            )
        )

        //CTRL+SHIFT+D: Documentation show hotkey
        this.addHotkey(
            new Hotkey(
                'ctrl+shift+d',
                (event: KeyboardEvent): boolean => {
                    this.showDocumentation = !this.showDocumentation
                    return false
                },
                elementsWithHotkeysEnabled,
                'Laat de documentatie pagina zien.'
            )
        )

        //CTRL+SPACE: PIAMEDIAEVENT:GLOBALPLAYPAUSE
        this.addHotkey(
            new Hotkey(
                'ctrl+space',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+space pressed on app')
                    this.piaMediaEventsService.globalPlayPause.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Speel/Pauzeer de audio.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALSEEKLEFT
        this.addHotkey(
            new Hotkey(
                'ctrl+left',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+left pressed on app')
                    this.piaMediaEventsService.globalScrubLeft.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Scrub audio naar links. Eén seconde terug in de tijd.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALSEEKLEFT
        this.addHotkey(
            new Hotkey(
                'ctrl+right',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+right pressed on app')
                    this.piaMediaEventsService.globalScrubRight.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Scrub audio naar rechts. Eén seconde vooruit in de tijd.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALZOOMIN
        this.addHotkey(
            new Hotkey(
                'ctrl+shift+right',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+right pressed on app')
                    this.piaMediaEventsService.globalStartOfLabel.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Spring naar het begin van het huidige label.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALZOOMOUT
        this.addHotkey(
            new Hotkey(
                'ctrl+shift+left',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+left pressed on app')
                    this.piaMediaEventsService.globalEndOfLabel.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Spring naar het einde van het huidige label.'
            )
        )

        //CTRL+SHIFT+<: PIAMEDIAEVENT:GLOBALSLOWER
        this.addHotkey(
            new Hotkey(
                'ctrl+shift+,',
                (event: KeyboardEvent): boolean => {
                    this.piaMediaEventsService.globalSlower.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Speel audio langzamer af.'
            )
        )

        //CTRL+SHIFT+>: PIAMEDIAEVENT:GLOBALFASTER
        this.addHotkey(
            new Hotkey(
                'ctrl+shift+.',
                (event: KeyboardEvent): boolean => {
                    this.piaMediaEventsService.globalFaster.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Speel audio sneller af.'
            )
        )

        //ALT+E: PIAMEDIAEVENT:INSERT EH
        this.addHotkey(
            new Hotkey(
                'alt+e',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+left pressed on app')
                    this.piaMediaEventsService.insertEh.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Voeg een [eh] tag in.'
            )
        )
    }

    addHotkey(hotkey: Hotkey) {
        this.currentHotkeys.push(hotkey)
        this.hotkeyService.add(hotkey)
    }

    removeCurrentHotkeys() {
        this.currentHotkeys.forEach((hotkey: Hotkey) => {
            this.hotkeyService.remove(hotkey)
        })

        this.currentHotkeys = []
    }

    redirectUserIfLoggedIn() {}

    subscribeToRouterEvents() {
        this.router.events.pipe(filter((e: Event): e is RouterEvent => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
            if (e.urlAfterRedirects.startsWith('/auth/')) {
                this.isFullscreenRoute = true
            } else {
                this.isFullscreenRoute = false
            }
        })
    }
}
