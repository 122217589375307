import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { RouterModule } from '@angular/router'

//Globals
import { Pia3Component } from './pia3.component'

//Audio
import { Pia3AudioPlayerComponent } from './components/audio/pia3-audio-player/pia3-audio-player.component'

//Blocks
import { Pia3AccordionComponent } from './components/_common/blocks/pia3-accordion/pia3-accordion.component'

//Authentication
import { Pia3LoginComponent } from './components/authentication/pia3-login/pia3-login.component'
import { Pia3LogoutButtonComponent } from './components/authentication/pia3-logout-button/pia3-logout-button.component'
import { Pia3RegisterComponent } from './components/authentication/pia3-register/pia3-register.component'
import { Pia3ResetPasswordComponent } from './components/authentication/pia3-reset-password/pia3-reset-password.component'

//Contextchannel
import { Pia3CreateContextChannelComponent } from './components/contextchannel/pia3-create-context-channel/pia3-create-context-channel.component'
import { Pia3ContextchannelAddPopupButtonComponent } from './components/contextchannel/pia3-contextchannel-add-popup-button/pia3-contextchannel-add-popup-button.component'
import { Pia3ContextchannelListComponent } from './components/contextchannel/pia3-contextchannel-list/pia3-contextchannel-list.component'
import { Pia3ContextchannelAddSpeakerComponent } from './components/contextchannel/pia3-contextchannel-add-speaker/pia3-contextchannel-add-speaker.component'
import { Pia3ContextchannelQuestionAwnserComponent } from './components/contextchannel/pia3-contextchannel-question-awnser/pia3-contextchannel-question-awnser.component'

//Common
import { Pia3DocumentDisplayComponent } from './components/_common/pia3-document-display/pia3-document-display.component'
import { Pia3HotkeyDisplayComponent } from './components/_common/pia3-hotkey-display/pia3-hotkey-display.component'

//Client
import { Pia3ClientSelectorComponent } from './components/client/pia3-client-selector/pia3-client-selector.component'
import { Pia3ClientSelectorMultiComponent } from './components/client/pia3-client-selector-multi/pia3-client-selector-multi.component'
//import { Pia3ClientSelectorMultiWithSearchComponent } from './components/client/pia3-client-selector-multi-with-search/pia3-client-selector-multi-with-search.component' //todo: create this component, was removed from refactoring because of timelimit, copy from speaker component
import { Pia3ClientSelectorSingleComponent } from './components/client/pia3-client-selector-single/pia3-client-selector-single.component'
import { Pia3ClientAddPopupButtonComponent } from './components/client/pia3-client-add-popup-button/pia3-client-add-popup-button.component'
import { Pia3ClientCreateFormComponent } from './components/client/pia3-client-create-form/pia3-client-create-form.component'

//Language
import { Pia3LanguageSelectorComponent } from './components/language/pia3-language-selector/pia3-language-selector.component'
import { Pia3LanguageSelectorMultiComponent } from './components/language/pia3-language-selector-multi/pia3-language-selector-multi.component'
import { Pia3LanguageSelectorMultiWithSearchComponent } from './components/language/pia3-language-selector-multi-with-search/pia3-language-selector-multi-with-search.component'
import { Pia3LanguageSelectorSingleComponent } from './components/language/pia3-language-selector-single/pia3-language-selector-single.component'

//Session
import { Pia3SessionCardComponent } from './components/session/pia3-session-card/pia3-session-card.component'
import { Pia3SessionGridCardsComponent } from './components/session/pia3-session-grid-cards/pia3-session-grid-cards.component'
import { Pia3SessionListComponent } from './components/session/pia3-session-list/pia3-session-list.component'
import { Pia3SessionSearchComponent } from './components/session/pia3-session-search/pia3-session-search.component'
import { Pia3SessionSliderCardsComponent } from './components/session/pia3-session-slider-cards/pia3-session-slider-cards.component'
import { Pia3SessionCategorySliderComponent } from './components/session/pia3-session-category-slider/pia3-session-category-slider.component'
import { Pia3SessionTitleEditableComponent } from './components/session/pia3-session-title-editable/pia3-session-title-editable.component'

//Speaker
import { Pia3SpeakerAvatarComponent } from './components/speaker/pia3-speaker-avatar/pia3-speaker-avatar.component'
import { Pia3SpeakerSelectorComponent } from './components/speaker/pia3-speaker-selector/pia3-speaker-selector.component'
import { Pia3SpeakerSelectorSingleComponent } from './components/speaker/pia3-speaker-selector-single/pia3-speaker-selector-single.component'
import { Pia3SpeakerSelectorMultiComponent } from './components/speaker/pia3-speaker-selector-multi/pia3-speaker-selector-multi.component'
import { Pia3SpeakerSelectorMultiWithSearchComponent } from './components/speaker/pia3-speaker-selector-multi-with-search/pia3-speaker-selector-multi-with-search.component'
import { Pia3SpeakerNewClientSpeakerComponent } from './components/speaker/pia3-speaker-new-client-speaker/pia3-speaker-new-client-speaker.component'
import { Pia3SpeakerNewClientSpeakerButtonComponent } from './components/speaker/pia3-speaker-new-client-speaker-button/pia3-speaker-new-client-speaker-button.component'

//Speechmodel
import { Pia3SpeechmodelSelectorComponent } from './components/speechmodel/pia3-speechmodel-selector/pia3-speechmodel-selector.component'
import { Pia3SpeechmodelSelectorSingleComponent } from './components/speechmodel/pia3-speechmodel-selector-single/pia3-speechmodel-selector-single.component'

//Transcript
import { Pia3TranscriptViewerComponent } from './components/transcript/pia3-transcript-viewer/pia3-transcript-viewer.component'
import { Pia3TranscriptExportButtonComponent } from './components/transcript/pia3-transcript-export-button/pia3-transcript-export-button.component'

//User
import { Pia3CurrentUserFabComponent } from './components/user/pia3-current-user-fab/pia3-current-user-fab.component'
import { Pia3CurrentUserFabPopoverComponent } from './components/user/pia3-current-user-fab-popover/pia3-current-user-fab-popover.component'
import { Pia3UserPerformanceChartTranscriptionDataminutesComponent } from './components/user/pia3-user-performance-chart-transcription-dataminutes/pia3-user-performance-chart-transcription-dataminutes.component'
import { Pia3UserListItemComponent } from './components/user/pia3-user-listitem/pia3-user-listitem.component'
import { Pia3UserAvatarComponent } from './components/user/pia3-user-avatar/pia3-user-avatar.component'

//Video
import { Pia3VideoModule } from './components/video/pia3-video.module'
import { Pia3VideoPlayerComponent } from './components/video/pia3-video-player/pia3-video-player.component'

import { Pia3GeneralSelectorComponent } from './components/generalselector/pia3-general-selector/pia3-general-selector.component'
import { Pia3GeneralSelectorSingleComponent } from './components/generalselector/pia3-general-selector-single/pia3-general-selector-single.component'

//Image Cropper
import { Pia3ImageCropperComponent } from './components/imagecropper/pia3-image-cropper/pia3-image-cropper.component'
import { ImageCropperModule } from 'ngx-image-cropper'

//word cloud
import { AngularD3CloudModule } from 'angular-d3-cloud' //npm install --save angular-d3-cloud
import { Pia3AngularD3WordCloudComponent } from './components/wordcloud/pia3-angular-d3-word-cloud/pia3-angular-d3-word-cloud.component'

//word tagger
import { Pia3WordTaggerComponent } from './components/wordtagger/pia3-word-tagger/pia3-word-tagger.component'

//word cloud list
import { Pia3WordCloudListComponent } from './components/wordcloud/pia3-word-cloud-list/pia3-word-cloud-list.component'
//
import { Pia3HomeComponent } from './components/home/pia3Home.component'
//text converter
import { Pia3TextconverterComponent } from './components/textconverter/pia3-textconverter/pia3-textconverter.component'

//charting
import { Pia3ColumnChartComponent } from './components/_common/charting/pia3-columnchart/pia3-columnchart.component'
import { Pia3StackedcolumnchartComponent } from './components/_common/charting/pia3-stackedcolumnchart/pia3-stackedcolumnchart.component'

//statistics
import { Pia3UserStatisticsComponent } from './components/statistics/pia3-statistics-user-performance/pia3-statistics-user-statistics.component'

@NgModule({
    imports: [ReactiveFormsModule, CommonModule, FormsModule, RouterModule, IonicModule, Pia3VideoModule, ImageCropperModule, AngularD3CloudModule],
    exports: [
        Pia3AccordionComponent,
        Pia3ColumnChartComponent,
        Pia3DocumentDisplayComponent,
        Pia3HotkeyDisplayComponent,
        Pia3StackedcolumnchartComponent,
        Pia3UserStatisticsComponent,
        Pia3CurrentUserFabPopoverComponent,
        Pia3CurrentUserFabComponent,
        Pia3TextconverterComponent,
        Pia3HomeComponent,
        Pia3Component,
        Pia3ContextchannelListComponent,
        Pia3ContextchannelAddPopupButtonComponent,
        Pia3ContextchannelAddSpeakerComponent,
        Pia3ContextchannelQuestionAwnserComponent,
        Pia3AudioPlayerComponent,
        Pia3ClientSelectorComponent,
        Pia3ClientSelectorSingleComponent,
        Pia3ClientSelectorMultiComponent,
        Pia3ClientAddPopupButtonComponent,
        Pia3ClientCreateFormComponent,
        Pia3LanguageSelectorComponent,
        Pia3LoginComponent,
        Pia3LogoutButtonComponent,
        Pia3RegisterComponent,
        Pia3ResetPasswordComponent,
        Pia3SessionCardComponent,
        Pia3SessionGridCardsComponent,
        Pia3SessionListComponent,
        Pia3SessionSearchComponent,
        Pia3SessionSliderCardsComponent,
        Pia3SessionCategorySliderComponent,
        Pia3SessionTitleEditableComponent,
        Pia3SpeakerAvatarComponent,
        Pia3SpeechmodelSelectorComponent,
        Pia3SpeechmodelSelectorSingleComponent,
        Pia3TranscriptExportButtonComponent,
        Pia3TranscriptViewerComponent,
        //Pia3VideoPlayerComponent,
        Pia3CreateContextChannelComponent,
        Pia3LanguageSelectorMultiComponent,
        Pia3LanguageSelectorMultiWithSearchComponent,
        Pia3LanguageSelectorSingleComponent,
        Pia3GeneralSelectorComponent,
        Pia3GeneralSelectorSingleComponent,
        Pia3SpeakerSelectorComponent,
        Pia3SpeakerSelectorSingleComponent,
        Pia3SpeakerSelectorMultiComponent,
        Pia3SpeakerSelectorMultiWithSearchComponent,
        Pia3SpeakerNewClientSpeakerComponent,
        Pia3SpeakerNewClientSpeakerButtonComponent,
        Pia3ImageCropperComponent,
        Pia3AngularD3WordCloudComponent,
        Pia3UserPerformanceChartTranscriptionDataminutesComponent,
        Pia3UserListItemComponent,
        Pia3UserAvatarComponent,
        Pia3WordTaggerComponent,
        Pia3WordCloudListComponent,
        Pia3VideoModule,
    ],
    declarations: [
        Pia3AccordionComponent,
        Pia3ColumnChartComponent,
        Pia3DocumentDisplayComponent,
        Pia3HotkeyDisplayComponent,
        Pia3StackedcolumnchartComponent,
        Pia3UserStatisticsComponent,
        Pia3CurrentUserFabPopoverComponent,
        Pia3CurrentUserFabComponent,
        Pia3TextconverterComponent,
        Pia3HomeComponent,
        Pia3Component,
        Pia3ContextchannelListComponent,
        Pia3ContextchannelAddPopupButtonComponent,
        Pia3ContextchannelAddSpeakerComponent,
        Pia3ContextchannelQuestionAwnserComponent,
        Pia3AudioPlayerComponent,
        Pia3ClientSelectorComponent,
        Pia3ClientSelectorSingleComponent,
        Pia3ClientSelectorMultiComponent,
        Pia3ClientAddPopupButtonComponent,
        Pia3ClientCreateFormComponent,
        Pia3LanguageSelectorComponent,
        Pia3LoginComponent,
        Pia3LogoutButtonComponent,
        Pia3RegisterComponent,
        Pia3ResetPasswordComponent,
        Pia3SessionCardComponent,
        Pia3SessionGridCardsComponent,
        Pia3SessionListComponent,
        Pia3SessionSearchComponent,
        Pia3SessionSliderCardsComponent,
        Pia3SessionCategorySliderComponent,
        Pia3SessionTitleEditableComponent,
        Pia3SpeakerAvatarComponent,
        Pia3SpeechmodelSelectorComponent,
        Pia3SpeechmodelSelectorSingleComponent,
        Pia3TranscriptExportButtonComponent,
        Pia3TranscriptViewerComponent,
        //Pia3VideoPlayerComponent,
        Pia3CreateContextChannelComponent,
        Pia3LanguageSelectorMultiComponent,
        Pia3LanguageSelectorMultiWithSearchComponent,
        Pia3LanguageSelectorSingleComponent,
        Pia3GeneralSelectorComponent,
        Pia3GeneralSelectorSingleComponent,
        Pia3SpeakerSelectorComponent,
        Pia3SpeakerSelectorSingleComponent,
        Pia3SpeakerSelectorMultiComponent,
        Pia3SpeakerSelectorMultiWithSearchComponent,
        Pia3SpeakerNewClientSpeakerComponent,
        Pia3SpeakerNewClientSpeakerButtonComponent,
        Pia3ImageCropperComponent,
        Pia3UserPerformanceChartTranscriptionDataminutesComponent,
        Pia3UserListItemComponent,
        Pia3UserAvatarComponent,
        Pia3AngularD3WordCloudComponent,
        Pia3WordTaggerComponent,
        Pia3WordCloudListComponent,
    ],
})
export class Pia3Module {}
