import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Pia3Service } from '../../../pia3.service'
import { Pia3LanguagelabelService } from '../../languagelabel/pia3-languagelabel.service'
import { GoldTranscript, LanguageLabel, Speaker } from './../../../API.service'
import { Pia3SpeakerAvatarComponent } from '../../speaker/pia3-speaker-avatar/pia3-speaker-avatar.component'
import { InfiniteScrollCustomEvent } from '@ionic/angular'

export interface speakerWithCompoundedLanguagelabels {
    speaker: Speaker
    speakerID: string
    languagelabelsWithWords: LanguageLabelWithWords[]
}

export interface LanguageLabelWithWords {
    label: LanguageLabel
    words: LanguageLabelWord[]
}

export interface LanguageLabelWord {
    word: string
    starttime: string
    endtime: string
}

export interface ILanguagelabelWordClickEvent {
    word: LanguageLabelWord
    languagelabel: LanguageLabel
}

@Component({
    selector: 'pia3-transcript-viewer',
    templateUrl: './pia3-transcript-viewer.component.html',
    styleUrls: ['./pia3-transcript-viewer.component.scss'],
})
export class Pia3TranscriptViewerComponent implements OnInit {
    //#region LOCAL VARIABLES
    public _transcript: GoldTranscript
    public _languagelabels: LanguageLabel[] = []
    public _languagelabelsNexttoken: string = null
    public _languagelabelsLoading: boolean = false
    public _speakerCompounedLanguagelabels: speakerWithCompoundedLanguagelabels[] = []
    //#endregion

    //#region INPUTS
    @Input('Transcript') public set transcript(transcript: GoldTranscript) {
        if (transcript != undefined) {
            this._transcript = transcript
            this.loadLanguagelabels(this._transcript.id, this._languagelabelsNexttoken)
            //////console.log('got autotranscript:', this._transcript)
        }
    }
    //#endregion

    //#region OUTPUTS
    @Output('languagelabelWordClickEvent')
    languagelabelWordClickEvent: EventEmitter<ILanguagelabelWordClickEvent> = new EventEmitter<ILanguagelabelWordClickEvent>()
    //#endregion
    constructor(private pia3Service: Pia3Service, private pia3LanguagelabelService: Pia3LanguagelabelService) {}

    ngOnInit() {}

    public parseContentJson(contentJson: string) {
        return JSON.parse(contentJson)
    }

    async loadLanguagelabels(goldTranscriptID: string, nexttoken: string = null) {
        this._languagelabelsLoading = true
        try {
            let result = await this.pia3LanguagelabelService.listLanguageLabelsByGoldTranscriptIdByStarttime(goldTranscriptID, 200, nexttoken)

            this.addToSpeakersCompoundedLanguagelabels(result.data.LanguageLabelsByGoldTranscriptByStarttime.items)

            this._languagelabelsNexttoken = result.data.LanguageLabelsByGoldTranscriptByStarttime.nextToken
        } catch (error) {
            console.log(error)
            alert('Error loading languageLabels... not yet implemented correctly')
        }
        this._languagelabelsLoading = false
    }

    public addToSpeakersCompoundedLanguagelabels(languagelabels: LanguageLabel[]): void {
        //////console.log('compounding labels:', languagelabels)

        //Get the currentSpeakerCompound from array or create new
        let currentSpeakerCompound: speakerWithCompoundedLanguagelabels =
            this._speakerCompounedLanguagelabels.length > 0 ? this._speakerCompounedLanguagelabels[this._speakerCompounedLanguagelabels.length - 1] : this.createSpeakerCompoundedLanguagelabels(languagelabels[0].Speaker, languagelabels[0].speakerID)

        //Add the currentSpeakerCompound to the array if new
        if (this._speakerCompounedLanguagelabels.length == 0) {
            this._speakerCompounedLanguagelabels.push(currentSpeakerCompound)
        }

        //Add the language
        currentSpeakerCompound = this.addLabelsToSpeakerCompound(languagelabels, currentSpeakerCompound)
    }

    private addLabelsToSpeakerCompound(languagelabels: LanguageLabel[], currentSpeakerCompound: speakerWithCompoundedLanguagelabels) {
        languagelabels.forEach((languagelabel: LanguageLabel) => {
            //New known speaker
            if (currentSpeakerCompound.speakerID != languagelabel.speakerID) {
                //////console.log('adding label to new speaker')
                //Add a new compound
                currentSpeakerCompound = this.createSpeakerCompoundedLanguagelabels(languagelabel.Speaker, languagelabel.speakerID)
                //Add the current label
                currentSpeakerCompound.languagelabelsWithWords.push(this.createLanguagelabelWithWords(languagelabel))
                //Add current compound to list
                this._speakerCompounedLanguagelabels.push(currentSpeakerCompound)
            } else {
                //////console.log('adding label to same speaker')
                currentSpeakerCompound.languagelabelsWithWords.push(this.createLanguagelabelWithWords(languagelabel))
            }
        })
        return currentSpeakerCompound
    }

    private createSpeakerCompoundedLanguagelabels(speaker: Speaker, speakerID: string): speakerWithCompoundedLanguagelabels {
        //////console.log('speaker of compound:', speaker)
        return {
            speaker: speaker,
            speakerID: speakerID,
            languagelabelsWithWords: [],
        }
    }

    private createLanguagelabelWithWords(languagelabel: LanguageLabel): LanguageLabelWithWords {
        return {
            label: languagelabel,
            words: this.getWordsFromLanguagelabel(languagelabel),
        }
    }

    private getWordsFromLanguagelabel(languagelabel: LanguageLabel): LanguageLabelWord[] {
        let words: LanguageLabelWord[] = []
        this.parseContentJson(languagelabel.contentJson).forEach((word) => {
            words.push({
                word: word['word'],
                starttime: word['starttime'],
                endtime: word['endttime'],
            })
        })
        return words
    }

    //#region Template events

    onIonInfinite($event) {
        //////console.log('on infinite:', $event)
        //load more labels if nexttoken not null
        if (this._languagelabelsNexttoken != null) {
            this.loadLanguagelabels(this._transcript.id, this._languagelabelsNexttoken).then((result) => {
                ;($event as InfiniteScrollCustomEvent).target.complete()
            })
        } else {
            ;($event as InfiniteScrollCustomEvent).target.complete()
        }
    }

    public labelClicked(label: LanguageLabelWithWords) {
        //////console.log('label clicked:', label)
    }

    public wordClicked(word: LanguageLabelWord, label: LanguageLabelWithWords) {
        //////console.log('word clicked')
        this.languagelabelWordClickEvent.emit({
            word: word,
            languagelabel: label.label,
        })
    }

    //#endregion
}
