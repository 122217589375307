/*
 * Public API Surface of pia3
 */

export * from './lib/pia3.service'
export * from './lib/pia3.component'
export * from './lib/pia3.module'

//EXPORT EXTERNALS
export { Region } from 'wavesurfer.js/dist/plugin/wavesurfer.regions'

//EXPORT Services (and Interface, interfaces should always be placed inside the servicename.ts file)
export * from './lib/API.service'
export * from './lib/components/audio/audio.service'
export * from './lib/components/authentication/authentication.service'
export * from './lib/components/contextchannel/pia3-contextchannel.service'
export * from './lib/components/_common/errorhandling/errorhandling.service'
export * from './lib/components/_common/pia3-local-storage/pia3-local-storage.service'
export * from './lib/components/cognito/cognito.service'
export * from './lib/components/language/pia3-language.service'
export * from './lib/components/languagelabel/pia3-languagelabel.service'
export * from './lib/components/nlpstep/pia3-nlpstep.service'
export * from './lib/components/session/pia3-session.service'
export * from './lib/components/theme/pia3-theme.service'
export * from './lib/components/transcript/pia3-transcript.service'
export * from './lib/components/user/pia3-user-performance-chart-transcription-dataminutes/pia3-user-performance-chart-transcription-dataminutes.service'
export * from './lib/components/video/pia3-video.service'
export * from './lib/components/wordcloud/pia3-word-cloud.service'
export * from './lib/components/wordtagger/pia3-word-tagger.service'
export * from './lib/components/textconverter/pia3-textconverter.service'
export * from './lib/components/pipeline/pia3-pipeline.service'

//EXPORT PIPELINE COMPONENTS

//EXPORT INTERFACES

//EXPORT PIPES

//EXPORT DIRECTIVES

//EXPORT GUARDS
export * from './lib/components/authentication/pia3.role.guard'

//HOME COMPONENT
export * from './lib/components/home/pia3Home.component'

//BLOCKS
export * from './lib/components/_common/blocks/pia3-accordion/pia3-accordion.component'

//EXPORT CURRENTUSER COMPONENTS
export * from './lib/components/user/pia3-current-user-fab/pia3-current-user-fab.component'
export * from './lib/components/user/pia3-current-user-fab-popover/pia3-current-user-fab-popover.component'

//EXPORT AUDIO COMPONENTS
export * from './lib/components/audio/pia3-audio-player/pia3-audio-player.component'

//EXPORT AUTHENTICATION COMPONENTS
export * from './lib/components/authentication/pia3-login/pia3-login.component'
export * from './lib/components/authentication/pia3-logout-button/pia3-logout-button.component'
export * from './lib/components/authentication/pia3-register/pia3-register.component'
export * from './lib/components/authentication/pia3-reset-password/pia3-reset-password.component'

//EXPORT CLIENT COMPONENTS
export * from './lib/components/client/pia3-client-selector/pia3-client-selector.component'
export * from './lib/components/client/pia3-client-selector-single/pia3-client-selector-single.component'
export * from './lib/components/client/pia3-client-selector-multi/pia3-client-selector-multi.component'
export * from './lib/components/client/pia3-client-add-popup-button/pia3-client-add-popup-button.component'
export * from './lib/components/client/pia3-client-create-form/pia3-client-create-form.component'
export * from './lib/components/client/pia3-client.service'

//EXPORT CONTEXTCHANNEL COMPONENTS
export * from './lib/components/contextchannel/pia3-contextchannel-add-popup-button/pia3-contextchannel-add-popup-button.component'
export * from './lib/components/contextchannel/pia3-create-context-channel/pia3-create-context-channel.component'
export * from './lib/components/contextchannel/pia3-contextchannel-list/pia3-contextchannel-list.component'
export * from './lib/components/contextchannel/pia3-contextchannel-add-speaker/pia3-contextchannel-add-speaker.component'
export * from './lib/components/contextchannel/pia3-contextchannel-question-awnser/pia3-contextchannel-question-awnser.component'

//EXPORT COMMON COMPONENTRS
export * from './lib/components/_common/pia3-document-display/pia3-document-display.component'
export * from './lib/components/_common/pia3-hotkey-display/pia3-hotkey-display.component'

//EXPORT LANGUAGE COMPONENTS
export * from './lib/components/language/pia3-language-selector/pia3-language-selector.component'
export * from './lib/components/language/pia3-language-selector-single/pia3-language-selector-single.component'
export * from './lib/components/language/pia3-language-selector-multi/pia3-language-selector-multi.component'
export * from './lib/components/language/pia3-language-selector-multi-with-search/pia3-language-selector-multi-with-search.component'
export * from './lib/components/language/pia3-language-selector-single/pia3-language-selector-single.component'

//EXPORT SESSION COMPONENTS
export * from './lib/components/session/pia3-session-card/pia3-session-card.component'
export * from './lib/components/session/pia3-session-list/pia3-session-list.component'
export * from './lib/components/session/pia3-session-grid-cards/pia3-session-grid-cards.component'
export * from './lib/components/session/pia3-session-search/pia3-session-search.component'
export * from './lib/components/session/pia3-session-slider-cards/pia3-session-slider-cards.component'
export * from './lib/components/session/pia3-session-category-slider/pia3-session-category-slider.component'
export * from './lib/components/session/pia3-session-title-editable/pia3-session-title-editable.component'

//EXPORT SPEAKER COMPONENTS
export * from './lib/components/speaker/pia3-speaker-avatar/pia3-speaker-avatar.component'
export * from './lib/components/speaker/pia3-speaker-selector/pia3-speaker-selector.component'
export * from './lib/components/speaker/pia3-speaker-selector-single/pia3-speaker-selector-single.component'
export * from './lib/components/speaker/pia3-speaker-selector-multi/pia3-speaker-selector-multi.component'
export * from './lib/components/speaker/pia3-speaker-selector-multi-with-search/pia3-speaker-selector-multi-with-search.component'
export * from './lib/components/speaker/pia3-speaker-new-client-speaker/pia3-speaker-new-client-speaker.component'
export * from './lib/components/speaker/pia3-speaker-new-client-speaker-button/pia3-speaker-new-client-speaker-button.component'

//EXPORT SPEECHMODEL COMPONENTS
export * from './lib/components/speechmodel/pia3-speechmodel-selector/pia3-speechmodel-selector.component'
export * from './lib/components/speechmodel/pia3-speechmodel-selector-single/pia3-speechmodel-selector-single.component'

//EXPORT TRANSCRIPT COMPONENTS
export * from './lib/components/transcript/pia3-transcript-export-button/pia3-transcript-export-button.component'
export * from './lib/components/transcript/pia3-transcript-viewer/pia3-transcript-viewer.component'

//EXPORT USER COMPONENTS
export * from './lib/components/user/pia3-user-performance-chart-transcription-dataminutes/pia3-user-performance-chart-transcription-dataminutes.component'
export * from './lib/components/user/pia3-user-listitem/pia3-user-listitem.component'
export * from './lib/components/user/pia3-users.service'
export * from './lib/components/user/pia3-user-avatar/pia3-user-avatar.component'

//EXPORT VIDEO COMPONENTS
export * from './lib/components/video/pia3-video.module'
export * from './lib/components/video/pia3-video-player/pia3-video-player.component'

//GENERAL SELECTOR
export * from './lib/components/generalselector/pia3-general-selector/pia3-general-selector.component'
export * from './lib/components/generalselector/pia3-general-selector-single/pia3-general-selector-single.component'

//IMAGE CROPPER
export * from './lib/components/imagecropper/pia3-image-cropper/pia3-image-cropper.component'

//WORD CLOUD
export * from './lib/components/wordcloud/pia3-angular-d3-word-cloud/pia3-angular-d3-word-cloud.component'

//WORD TAGGER
export * from './lib/components/wordtagger/pia3-word-tagger/pia3-word-tagger.component'

//WORD CLOUD LIST
export * from './lib/components/wordcloud/pia3-word-cloud-list/pia3-word-cloud-list.component'

//TEXT CONVERTER
export * from './lib/components/textconverter/pia3-textconverter/pia3-textconverter.component'

//CHARTS
export * from './lib/components/_common/charting/pia3-columnchart/pia3-columnchart.component'
export * from './lib/components/_common/charting/pia3-columnchart/pia3-columnchart-options.model'
export * from './lib/components/_common/charting/pia3-stackedcolumnchart/pia3-stackedcolumnchart.component'

//STATISTICS
export * from './lib/components/statistics/pia3-statistics-user-performance/pia3-statistics-user-statistics.component'
