import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as queries from 'src/graphql/queries'
import * as mutations from 'src/graphql/mutations'
import * as subscriptions from 'src/graphql/subscriptions'
import { GetSpeakerQuery, ListSpeakersQuery, SpeakersByClientByLastNameQuery, Speaker } from 'src/app/API.service'

import { GenericErrorHandlerService } from './../services/generic-error-handler.service'
import { ContextchannelService } from 'src/app/contextchannels/contextchannel.service'
import { rejects } from 'assert'

@Injectable({
    providedIn: 'root',
})
export class SpeakerService {
    // private _clientSpeakers: BehaviorSubject<Speaker[]> = new BehaviorSubject([
    //   Speaker,
    // ]);

    // public readonly clientSpeakers: Observable<Speaker[]> =
    //   this._clientSpeakers.asObservable();

    constructor(private contextchannelService: ContextchannelService) {}

    async get(id: string): Promise<{ data: GetSpeakerQuery }> {
        return API.graphql(graphqlOperation(queries.getSpeaker, { id: id })) as Promise<{ data: GetSpeakerQuery }>
    }

    async listSpeakersByClientByLastname(clientID: string, limit: number = 100): Promise<{ data: SpeakersByClientByLastNameQuery }> {
        return API.graphql(
            graphqlOperation(queries.speakersByClientByLastName, {
                clientID: clientID,
                limit: limit,
            })
        ) as Promise<{ data: SpeakersByClientByLastNameQuery }>
    }

    async listAllSpeakersByClientByLastname(clientID: string): Promise<Speaker[]> {
        let allSpeakers: Speaker[] = []
        let speakerResult: {
            data: SpeakersByClientByLastNameQuery
        }

        do {
            speakerResult = await this.listSpeakersByClientByLastname(clientID)
            let speakers: Speaker[] = speakerResult.data.SpeakersByClientByLastName.items as Speaker[]
            allSpeakers.push(...speakers)
        } while (speakerResult.data.SpeakersByClientByLastName.nextToken != null)

        return allSpeakers
    }

    async getContextchannelSpeakersPromiseAll(contextchannelID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let ctxSpeakerConnectionItems = this.contextchannelService
                .getContextchannel(contextchannelID)
                .then(
                    (result) => {
                        resolve(
                            Promise.all(
                                result.data.getContextchannel.Speakers.items.map((sp) => {
                                    return this.get(sp.speakerID)
                                })
                            )
                        )
                    },
                    (rejected) => {
                        reject(rejected)
                    }
                )
                .catch((error) => {
                    reject(error)
                })
        })
    }

    //TODO: ListSpeakerByContextchannelByLastname (extend api to include an secondary index for SpeakersByContextchannel) - not a priority as this only leads to performance issues when there are a lot of speakers for each client and we do not provide functionality to edit/control speakers for the contextchannel at the moment (needed later)
    // async listSpeakersByContextchannelByLastname(
    //   clientID: string,
    //   limit: number = 999
    // ): Promise<{ data: SpeakersByClientByLastNameQuery }> {
    //   return API.graphql(
    //     graphqlOperation(queries.SpeakersByClientByLastNameQuery, {
    //       clientID: clientID,
    //       limit: limit,
    //     })
    //   ) as Promise<{ data: SpeakersByClientByLastNameQuery }>;
    // }
}
