<ion-modal class="largeModal" [isOpen]="isShortcutsModalOpen" (didDismiss)="shortCutModalDismissed($event)">
  <ng-template>
    <div class="borderBox">
      <ion-list>
       <ion-list-header>
          <ion-title>Toetsencombinatie</ion-title>
          <ion-title>Resultaat</ion-title>
        </ion-list-header>

        <ion-item *ngFor="let hotkey of hotkeys" >
          <ion-label>{{hotkey.shortcut}}</ion-label>
          <ion-label>{{hotkey.description}}</ion-label>
        </ion-item>
      </ion-list>
    </div>
  </ng-template>
</ion-modal>
