<!-- SINGLE SPEAKER SELECTOR -->
<!-- Enable single selector if selectorMode is SINGLE -->
<div *ngIf="selectorMode == 'SINGLE'">
    <ion-select slot="end" placeholder="Select" (ionChange)="speakerChanged($event)" *ngIf="speakers != undefined && speakers.length > 0" interface="popover">
        <ion-select-option *ngFor="let speaker of speakers" [value]="speaker">{{ speaker.firstName + ' ' + speaker.lastName }}</ion-select-option>
    </ion-select>
    <ion-text *ngIf="speakers == undefined || speakers.length == 0">
        <p>Nog geen sprekers beschikbaar.</p>
    </ion-text>
</div>

<!-- MULTI SPEAKERS SELECTOR -->
<!-- Enable multi selector if selectorMode is MULTI -->
<div *ngIf="selectorMode == 'MULTI'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal-speaker"
            ><strong>Select</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal-speaker" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>Speakers</strong></ion-label>
                    </div>
                </ion-toolbar>
            </ion-header>

            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent" [indeterminate]="indeterminateState" (click)="selectAllSpeakers()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let s of resultsOfSearch">
                        <ion-label>{{ s.speaker.firstName + ' ' + s.speaker.lastName }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="s.isSpeakerChecked" (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>
<!-- show selection multi -->
<ion-list lines="none" *ngIf="showSpeakers !== null">
    <ion-item *ngFor="let s of showSpeakers; let i = index">
        <ion-icon color="primary" name="person-circle"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ s.firstName + ' ' + s.lastName }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small" (click)="removeSelectionMulti(i)"></ion-icon>
    </ion-item>
</ion-list>

<!-- <ion-item-divider *ngIf="showSpeakers !== null"></ion-item-divider> -->
<hr class="solid" *ngIf="showSpeakers !== null" />

<!-- MULTI SPEAKERS SELECTOR WITH SEARCH-->
<!-- Enable multi speaker selector with search if selectorMode is MULTI_WITH_SEARCH -->
<div *ngIf="selectorMode == 'MULTI_WITH_SEARCH'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal-speaker"
            ><strong>Kies</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal-speaker" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>Speakers</strong></ion-label>
                    </div>

                    <ion-searchbar [debounce]="500" (ionChange)="handleChange($event)"></ion-searchbar>
                </ion-toolbar>
            </ion-header>
            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent" [indeterminate]="indeterminateState" (click)="selectAllSpeakers()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let s of resultsOfSearch">
                        <ion-label>{{ s.speaker.firstName + ' ' + s.speaker.lastName }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="s.isSpeakerChecked" (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>
<!-- show selection multi with search -->
<ion-list lines="none" *ngIf="showSpeakersWithSearch !== null">
    <ion-item *ngFor="let s of showSpeakersWithSearch; let i = index">
        <ion-icon color="primary" name="person-circle"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ s.firstName + ' ' + s.lastName }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small" (click)="removeSelectionMultiWithSearch(i)"></ion-icon>
    </ion-item>
</ion-list>
<!-- <ion-item-divider *ngIf="showSpeakersWithSearch !== null"></ion-item-divider> -->
<hr class="solid" *ngIf="showSpeakersWithSearch !== null" />
