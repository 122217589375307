<div class="addSpeakerComponent">
    <div class="loadingSpeakerForm" *ngIf="!formLoaded">
        <p>Even geduld A.U.B.</p>
        <ion-spinner></ion-spinner>
    </div>
    <ion-grid class="addSpeakerGrid" *ngIf="formLoaded">
        <ion-row>
            <ion-col>
                <h1>{{ this.contextChannel.Client.displayName }} Sprekers</h1>

                <p *ngIf="clientSpeakerUI.size == 0">Geen sprekers beschikbaar in deze client.</p>

                <div>
                    <ion-button (click)="selectAllButtonClick()" [disabled]="this.queryInProgress">Alles Selecteren</ion-button>
                    <ion-button (click)="selectNoneButtonClick()" [disabled]="this.queryInProgress">Niks Selecteren</ion-button>
                </div>

                <ion-list class="speakerList">
                    <ion-item *ngFor="let s of clientSpeakerUI | keyvalue" (click)="clientSpeakerClick(s.key)" [disabled]="channelSpeakersIds.includes(s.value)">
                        <ion-label>{{ s.key }}</ion-label>
                        <ion-icon *ngIf="this.candidateSpeakerIds.includes(s.value)" name="checkmark" aria-hidden="true"></ion-icon>
                    </ion-item>
                </ion-list>
                <pia3-speaker-new-client-speaker-button [clientId]="this.contextChannel.Client.id" (speakerAdded)="clientSpeakerAdded($event)"></pia3-speaker-new-client-speaker-button>
            </ion-col>

            <ion-col>
                <div *ngIf="!this.queryInProgress">
                    <div *ngIf="this.candidateSpeakerIds.length <= 0">
                        <p>Selecteer links één of meer sprekers om toe te voegen.</p>
                    </div>

                    <div *ngIf="this.candidateSpeakerIds.length > 0">
                        <p>
                            {{ this.candidateSpeakerNamesList }}
                        </p>
                        <ion-button (click)="addSpeakerClick()" [disabled]="!this.canAdd">Toevoegen</ion-button>
                        <p *ngIf="!this.canAdd">Deze spreker is al toegevoegd.</p>
                    </div>
                </div>

                <div *ngIf="this.queryInProgress">
                    <p>Even geduld A.U.B. <ion-spinner></ion-spinner></p>
                </div>
            </ion-col>

            <ion-col>
                <h1>{{ this.contextChannel.name }} Sprekers</h1>
                <p *ngIf="channelSpeakerUI.size == 0">Nog geen sprekers toegevoegd aan dit contextkanaal.</p>
                <ion-list class="speakerList">
                    <ion-item *ngFor="let s of channelSpeakerUI | keyvalue">
                        <ion-label>{{ s.key }}</ion-label>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
