import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { AlertController, IonModal } from '@ionic/angular'
import { OverlayEventDetail } from '@ionic/core/components'
import { CropperPosition, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper' //npm install ngx-image-cropper

@Component({
    selector: 'pia3-image-cropper',
    templateUrl: './pia3-image-cropper.component.html',
    styleUrls: ['./pia3-image-cropper.component.scss'],
})
export class Pia3ImageCropperComponent implements OnInit {
    @Output('imageSelectedPath') imageSelectedPath = new EventEmitter<string>()

    imgChangeEvt: any = ''
    cropImgPreview: any = ''
    cropImgPreviewDummy: any = ''

    private oldPosition: CropperPosition //Saved position between seeing and hiding cropper
    private firstPosition: CropperPosition //Initial Cropper position
    public currentPosition: CropperPosition //Used tell component to change cropper position

    // Modal
    @ViewChild(IonModal) modalCropper: IonModal
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent

    message = ''
    name: string

    constructor(private alertController: AlertController) { }

    ngOnInit() {
        this.currentPosition = {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0
        }
    }

    onFileChange(event: any): void {
        //Fires when the cropper changes image file
        this.firstPosition = null
        this.imgChangeEvt = event
    }

    cropImg(e: ImageCroppedEvent) {
        //Fires whenver the cropping box moves or changes size
        this.cropImgPreviewDummy = e.base64

        if (e.cropperPosition == null) {
            return
        }

        if (this.firstPosition == null) {
            this.firstPosition = e.cropperPosition
            this.oldPosition = e.cropperPosition

            return
        }

        if (this.firstPosition.x1 == e.cropperPosition.x1
            && this.firstPosition.x2 == e.cropperPosition.x2
            && this.firstPosition.y1 == e.cropperPosition.y1
            && this.firstPosition.y2 == e.cropperPosition.y2) {

            return
        }

        this.oldPosition = e.cropperPosition
    }

    imgLoad() {
        //Fires when the image loads in the cropper display
    }

    initCropper() {
        //Fires when the cropper finishes setting up        
        if (this.oldPosition == null) {
            return
        }

        let newPosition: CropperPosition = {
            x1: this.oldPosition.x1,
            x2: this.oldPosition.x2,
            y1: this.oldPosition.y1,
            y2: this.oldPosition.y2,
        }

        this.currentPosition = newPosition
    }

    imgFailed() {
        // error msg
    }

    // Modal
    present() {
        this.modalCropper.present()
    }

    cancel() {
        this.modalCropper.dismiss(null, 'cancel')
    }

    confirm() {
        this.modalCropper.dismiss(this.name, 'confirm')

        //
        if (this.cropImgPreviewDummy !== '') {
            this.cropImgPreview = this.cropImgPreviewDummy
            this.savePicture()
        } else {
            this.presentNoSelectedImageAlert()
        }
    }

    onWillDismiss(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
    }

    //Saves image as base64, will need to be decoded again.
    async savePicture() {
        const base64Data = this.cropImgPreview

        // Write the file to the data directory
        const fileName = new Date().getTime() + '.png'
        const savedFile = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Data,
        })

        if (this.cropImgPreview !== '') {
            let imagePath = savedFile.uri.toString()
            let imageName = imagePath.split('/')

            this.message = imageName[2]
            this.imageSelectedPath.emit(imagePath)
        }


    }

    async presentNoSelectedImageAlert() {
        const alert = await this.alertController.create({
            header: 'Geen plaatje geselecteerd',
            message: 'Kies A.U.B. een plaatje.',
            buttons: ['OK'],
            mode: 'ios',
        })

        await alert.present()
    }
}
