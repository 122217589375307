import { Pia3Service } from '../../pia3.service'
import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import { queries, mutations, subscriptions, Contextchannel, Session, ListUsersQuery, GetUserQuery, Language, User } from '@humain-r/pia3-codegen'

import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'
import { filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class Pia3UsersService {
    public async getUser(userId: string): Promise<User> {
        const query = await (API.graphql<Session>(graphqlOperation(queries.getUser), { id: userId }) as Promise<{ data: GetUserQuery }>)
        return query.data.getUser as User
    }

    public async listUsers(): Promise<User[]> {
        const query = await (API.graphql<Session>(graphqlOperation(queries.listUsers)) as Promise<{ data: ListUsersQuery }>)
        return query.data.listUsers.items as User[]
    }
}
