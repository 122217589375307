<ion-grid
  class="pia3-audio-player ion-align-items-center"
  (mouseover)="onMouseOver($event)"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseOut($event)"
>
  <ion-row class="ion-align-items-center" style="height: 100%">
    <ion-col>
      <div class="ion-text-center" [hidden]="!isLoading">
        <ion-spinner color="light"></ion-spinner>
      </div>
      <div #ws [id]="'ws' + controlID"></div>
      <ion-grid
        class="controls_container no-padding"
        *ngIf="!pia3AudioPlayer.audio != null"
      >
        <ion-row *ngIf="configuration.showControls">
          <ion-col class="ion-text-center">
            <ion-button (click)="backward(pia3AudioPlayer)"
              ><ion-icon name="play-back-sharp"></ion-icon
            ></ion-button>
            <ion-button
              (click)="play(pia3AudioPlayer)"
              [hidden]="pia3AudioPlayer.isPlaying"
              ><ion-icon name="play-sharp"></ion-icon
            ></ion-button>
            <ion-button
              (click)="pause(pia3AudioPlayer)"
              [hidden]="!pia3AudioPlayer.isPlaying"
              ><ion-icon name="pause-sharp"></ion-icon
            ></ion-button>
            <ion-button (click)="stop(pia3AudioPlayer)"
              ><ion-icon name="stop-sharp"></ion-icon
            ></ion-button>
            <ion-button (click)="forward(pia3AudioPlayer)"
              ><ion-icon name="play-forward-sharp"></ion-icon
            ></ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
