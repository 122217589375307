<ion-grid>
  <ion-row>
    <ion-col
      size="12"
      sizeSm="12"
      sizeMd="6"
      sizeLg="4"
      sizeXl="3"
      *ngFor="let session of sessions"
    >
      <pia3-session-card
        [session]="session"
        [sessionCardConfig]="_sessionCardConfig"
        (cardClickEvent)="ionSessionCardClicked($event)"
      ></pia3-session-card>
    </ion-col>
  </ion-row>
</ion-grid>
