<ion-title [color]="categoryNameColor"
  ><h1>{{ categoryName }}</h1>
  <!-- <span *ngIf="ionSliderReachedEndNoMoreData">No more data</span> -->
  <ion-spinner name="dots" *ngIf="sessionsLoading"
    >loading...</ion-spinner
  ></ion-title
>
<pia3-session-slider-cards
  [sessions]="sessions"
  [sessionsLoading]="sessionsLoading"
  [sessionCardConfig]="sessionCardConfig"
  [slidesPerViewXs]="slidesPerViewXs"
  [slidesPerViewSm]="slidesPerViewSm"
  [slidesPerViewMd]="slidesPerViewMd"
  [slidesPerViewLg]="slidesPerViewLg"
  [slidesPerViewXl]="slidesPerViewXl"
  [slidesPerViewXXl]="slidesPerViewXXl"
  [ionSliderReachedEndNoMoreData]="ionSliderReachedEndNoMoreData"
  (ionSliderReachEnd)="ionSliderReachedEnd($event)"
  (ionSliderSessionCardClick)="ionSliderSessionCardClicked($event)"
></pia3-session-slider-cards>
