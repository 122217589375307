<ion-modal class="largeModal" [isOpen]="isDocumentationModalOpen" (didDismiss)="documentationModalDismissed($event)">
  <ng-template>
    <div class="borderBox">
      <div class="titleSection">
        <ion-title>Documentatie</ion-title>
      </div>
      <iframe *ngIf="isDataAgent"  src="https://docs.google.com/document/d/e/2PACX-1vRUW2cC2vE4QhLP19x2JWuHyjDrCwjdRfW8_w8yxc5stJq-3QiceTVySheHHzXwK1tc6Wzi8bRkzhsQ/pub?embedded=true"></iframe>
    </div>
  </ng-template>
</ion-modal>
