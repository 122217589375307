export class Pia3ColumnChartOptions {
    public title: string = "";
    public xAxis: string = "";
    public yAxis: string = "";
    public seriesName: string = "";

    static ConstructFromOptions(input: Pia3ColumnChartOptions): Pia3ColumnChartOptions {
        var newOptions = new Pia3ColumnChartOptions()

        newOptions.title = input.title
        newOptions.xAxis = input.xAxis
        newOptions.yAxis = input.yAxis
        newOptions.seriesName = input.seriesName

        return newOptions
    }
}
