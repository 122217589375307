<!-- Add Client Fab-->
<ion-fab *ngIf="enableAddClientButton" [vertical]="vertical" [horizontal]="horizontal" [edge]="edge" position="static" slot="fixed">
    <ion-fab-button (click)="openAddClientModal()" color="tertiary">
        <ion-icon name="add"></ion-icon>
    </ion-fab-button>
</ion-fab>
<ion-modal [isOpen]="isModalOpen" (ionModalWillDismiss)="setOpen(false)">
    <pia3-client-create-form></pia3-client-create-form>
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-title>Organisatie toevoegen</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="setOpen(false)">Sluiten</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content><pia3-client-create-form [clientId]="this.clientId"></pia3-client-create-form> </ion-content>
    </ng-template>
</ion-modal>
