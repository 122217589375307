<div *ngIf="!(updateMode ? speakerIsReady : true && clientIsReady)">
    <div class="ion-margin">
        <ion-title>Even Wachten A.U.B.</ion-title>
    </div>
</div>

<div *ngIf="updateMode ? speakerIsReady : true && clientIsReady">
    <form [formGroup]="speakerForm" (ngSubmit)="submit()">
        <ion-list>
            <ion-item lines="full">
                <ion-label position="fixed">* Voornaam</ion-label>
                <ion-input placeholder="Voer een voornaam in..." color="primary" type="text" maxlength="30" minlength="2" clearInput="true" formControlName="firstName"></ion-input>
            </ion-item>

            <ion-item lines="full">
                <ion-label position="fixed">* Achternaam</ion-label>
                <ion-input placeholder="Voer een achternaam in..." color="primary" type="text" maxlength="30" minlength="2" clearInput="true" formControlName="lastName"></ion-input>
            </ion-item>

            <ion-item lines="full">
                <ion-label position="fixed">* Geslacht</ion-label>
                <ion-select formControlName="gender" placeholder="Selecteer een geslach">
                    <ion-select-option value="male">Man</ion-select-option>
                    <ion-select-option value="female">Vrouw</ion-select-option>
                    <ion-select-option value="other">Anders</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-list>

        <ion-row>
            <ion-col>
                <ion-text> * Dit is een verplicht veld. </ion-text>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-button [disabled]="!speakerForm.valid" class="ion-margin" expand="block" type="submit">verzenden</ion-button>
            </ion-col>
        </ion-row>
    </form>
</div>
