import { Component, OnInit, ViewChild } from '@angular/core'
import { formatDate } from '@angular/common'
import { ModalController } from '@ionic/angular'

import { Contextchannel } from '@humain-r/pia3-codegen'

import { Pia3ContextchannelService } from '../../contextchannel/pia3-contextchannel.service'
import { Pia3AuthenticationService } from '../../authentication/authentication.service'
import { Pia3LanguagelabelService, SearchLanguageLabelsQueryForChartsItem } from '../../languagelabel/pia3-languagelabel.service'
import { Pia3ColumnChartOptions } from '../../_common/charting/pia3-columnchart/pia3-columnchart-options.model'
import { Pia3StackedColumnChartSeries } from '../../_common/charting/pia3-stackedcolumnchart/pia3-stackedcolumnchart-series.model'
import { Pia3ThemeColor, Pia3ThemeService } from '../../theme/pia3-theme.service'
import { Pia3Service } from '../../../pia3.service'


@Component({
  selector: 'pia3-statistics-user-statistics',
  templateUrl: './pia3-statistics-user-statistics.component.html',
  styleUrls: ['./pia3-statistics-user-statistics.component.scss'],
})
export class Pia3UserStatisticsComponent implements OnInit {
  public chartData: Map<string, any>
  public chartOptions: Pia3ColumnChartOptions = new Pia3ColumnChartOptions()
  public isOpenDetailsPopup = false
  public isOpenChannelPopup = false
  public isOpenWaitingModal = false

  public dateFrom: string
  public dateTo: string
  public barType: string = "Day"
  public totalBar: boolean = true
  public totalDataMinutes = 0

  public filteredUserGUID: string = null
  public filteredChannelGUID: string = null
  public filterIssue: boolean = false
  private continueQuery: boolean = false

  public userMap = new Map<string, string>()
  public channelMap = new Map<string, string>()

  private _currentUserID: string;
  private _colorMap;

  private _nameMap = new Map<string, string>([
    ["Rejected", "Afgewezen"],
    ["Validated", "Gevalideerd"],
    ["ValidationRequested", "Validatie Aangevraagd"],
    ["New", "Uitvoeren"],
  ])

  @ViewChild('popoverDetails') popoverDetails
  @ViewChild('popoverChannels') popoverChannels

  constructor(
    private pia3Service: Pia3Service,
    private languagelabelService: Pia3LanguagelabelService,
    private contextchannelService: Pia3ContextchannelService,
    private authenticationService: Pia3AuthenticationService,
    private themeService: Pia3ThemeService,
    private modalController: ModalController,
  ) {
    this.authenticationService.currentLoggedInUser.subscribe((val) => {
      this._currentUserID = val.getUsername()
    });
  }

  async ngOnInit() {
    let now = new Date()
    this.dateTo = now.toISOString()

    let past = new Date()
    past.setDate(now.getDate() - 1)
    this.dateFrom = past.toISOString()

    this.chartOptions.title = ""
    this.chartOptions.xAxis = "Tijd"
    this.chartOptions.yAxis = "Data Minuten"

    this._colorMap = new Map<string, string>([
      ["Rejected", this.themeService.getThemeColorValue(Pia3ThemeColor.Danger)],
      ["Validated", this.themeService.getThemeColorValue(Pia3ThemeColor.Success)],
      ["ValidationRequested", this.themeService.getThemeColorValue(Pia3ThemeColor.Warning)],
      ["New", this.themeService.getThemeColorValue(Pia3ThemeColor.Primary)],
    ])

    this.loadFormData()
    this.generateChartData()
  }

  async generateChartData() {
    this.totalDataMinutes = 0

    //Don't get labels for all users in all channels    
    let emptyUserFilter = (this.filteredUserGUID == null || this.filteredUserGUID == "")
    let emptyChannelFilter = (this.filteredChannelGUID == null || this.filteredChannelGUID == "")

    if (emptyChannelFilter && emptyUserFilter) {
      this.filterIssue = true
      return
    }

    this.filterIssue = false

    //pop up a modal to indicate something is happening the user needs to wait for
    this.isOpenWaitingModal = true

    //This is where the label date will be parsed into. 
    // Labelstate : {Date, Dataminutes }
    // "Validation Requested" : {"Week 52", 15.8}
    let chartMap = new Map<string, Map<string, number>>()

    //Because anychart does not understand the order of bars we feed anychart a dummy series with all the bars 
    //in the correct order with a value of 0. If you are very careful with your mouse you might be able to find them.
    let dummySeriesMap = new Map<string, number>()

    //Actually Fetch Labels
    let labels = await this.queryLanguageLabels(this.filteredUserGUID, this.filteredChannelGUID, this.dateFrom.split("T")[0], this.dateTo.split("T")[0])

    labels.forEach((label) => {
      //Date and Time Wrangling
      let labelDate = this.timeStringtoNumber(label.submittedAt)
      let barLabel = this.dateToBarType(labelDate)

      //Label Satus
      if (!chartMap.has(label.validationStatus)) {
        chartMap.set(label.validationStatus, new Map<string, number>())
      }

      //Time Bars
      let chartMapStatus = chartMap.get(label.validationStatus)
      if (!chartMapStatus.has(barLabel)) {
        chartMapStatus.set(barLabel, 0)
      }

      //Increment
      let start = this.timeAsSeconds(label.starttime)
      let end = this.timeAsSeconds(label.endtime)
      let seconds = (end - start) / 60

      chartMapStatus.set(barLabel, chartMapStatus.get(barLabel) + seconds)

      //Save in the dummy Series
      if (!dummySeriesMap.has(barLabel)) {
        dummySeriesMap.set(barLabel, labelDate)
      }
    })

    //Sort the dummyseries 
    dummySeriesMap = new Map([...dummySeriesMap.entries()].sort((a, b) => a[1] - b[1]))
    let dummieStacks: Pia3StackedColumnChartSeries[] = []

    dummySeriesMap.forEach((_date: number, barLabel: string) => {
      dummieStacks.push({
        'x': barLabel,
        'value': 0,
        'stroke': "white",
        'fill': "white"
      })
    })

    //Clear the chartdata, Create a new map for the new set of chartdata and add the dummy series
    this.chartData = new Map<string, any>()
    let newChartData = new Map<string, any>()
    newChartData.set("dummy", dummieStacks)

    let timeTotals = new Map<string, number>([
      ["Rejected", 0],
      ["Validated", 0],
      ["ValidationRequested", 0],
      ["New", 0],
    ])

    //Generate Bars, in order of Status
    this._colorMap.forEach((color: string, labelState: string) => {
      let barStacks: Pia3StackedColumnChartSeries[] = []

      //Skip empty Status
      if (!chartMap.has(labelState)) {
        return
      }

      let chartMapStatus = chartMap.get(labelState)
      let previousTime = timeTotals.get(labelState)

      //Generate Bars
      chartMapStatus.forEach((minutes: number, barLabel: string) => {
        previousTime += minutes
        timeTotals.set(labelState, previousTime)
        this.totalDataMinutes += minutes

        barStacks.push({
          'x': barLabel,
          'value': minutes,
          'stroke': "white",
          'fill': color
        })
      })

      //Add the total barstack
      if (this.totalBar) {
        barStacks.push({
          'x': "Totaal",
          'value': timeTotals.get(labelState),
          'stroke': "white",
          'fill': color
        })
      }

      //add the bar to the output      
      newChartData.set(this._nameMap.get(labelState), barStacks)
    })

    //Push data to charting component
    this.chartData = newChartData

    //Update Title
    let from = this.dateFrom.split("T")[0];
    let to = this.dateTo.split("T")[0]

    //Date Section
    let dateSection = ""

    if (from == to) {
      dateSection = `op ${from}`
    } else {
      dateSection = `van ${from} tot ${to}`
    }

    //Person Section
    let personSection = ""

    this.userMap.forEach((value: string, key: string) => {
      if (value == this.filteredUserGUID) {
        personSection = key
      }
    })

    //Update Title
    let updatedChartOptions = Pia3ColumnChartOptions.ConstructFromOptions(this.chartOptions)
    updatedChartOptions.title = `${personSection} ${dateSection}`
    this.chartOptions = updatedChartOptions

    //Close the waiting popup again.    
    const isModalOpened = await this.modalController.getTop();

    if (isModalOpened) {
      this.modalController.dismiss()
    }

    this.isOpenWaitingModal = false
  }

  //Do the hard work of actually getting some labels to process.
  async queryLanguageLabels(userId: string, channelId: string, dateFrom: string, dateTo: string): Promise<SearchLanguageLabelsQueryForChartsItem[]> {
    this.continueQuery = true

    try {
      let result = await this.languagelabelService.SearchLanguageLabelsForCharts(
        { between: [dateFrom, dateTo] }, null, channelId, userId, null, "submittedAt",
      )

      let results = result.data.searchLanguageLabels.items
      let nextToken = result.data.searchLanguageLabels.nextToken

      while (nextToken != null) {
        if (!this.continueQuery) {
          return []
        }

        result = await this.languagelabelService.SearchLanguageLabelsForCharts(
          { between: [dateFrom, dateTo] }, null, channelId, userId, null, "submittedAt", 999, nextToken
        )

        results.push(...result.data.searchLanguageLabels.items)
        nextToken = result.data.searchLanguageLabels.nextToken
      }

      return results

    } catch (error) {

      console.error(error)
      this.isOpenWaitingModal = false
      this.modalController.dismiss()

      return []
    }
  }

  stopQuery() {
    this.continueQuery = false
  }

  presentPopoverDetails(e: Event) {
    this.popoverDetails.event = e
    this.isOpenDetailsPopup = true
  }

  presentPopoverChannel(e: Event) {
    this.popoverChannels.event = e
    this.isOpenChannelPopup = true
  }

  handleUserChange(e: any) {
    this.generateChartData()
  }

  handleChannelChange(e: any) {
    this.generateChartData()
  }

  updateChart(e: Event) {
    this.isOpenDetailsPopup = false
    this.generateChartData()
  }

  async loadFormData() {
    //Dummy option for no user filtering
    this.userMap = new Map<string, string>([
      [" Iedereen", null]
    ])

    //Populate with users
    let users = (await this.pia3Service.listUsers()).data.listUsers.items
    users.forEach((user) => {
      this.userMap.set(`${user.firstName || ""} ${user.infix || ""} ${user.lastName || ""}`, user.id)
    })

    //Dummy option for no channel filtering
    this.channelMap = new Map<string, string>([
      [" Alles", null]
    ])

    //Populate with context channels
    let contextchannels = ((await this.contextchannelService.getContextchannelsForCurrentUserWithImages())
      .data.listContextchannels.items) as Contextchannel[]

    contextchannels.forEach((channel) => {
      this.channelMap.set(channel.name, channel.id)
    })

    //Default user, provided by Input
    this.filteredUserGUID = this._currentUserID
    //First channel in list
    this.filteredChannelGUID = this.channelMap.values().next().value
  }

  timeStringtoNumber(input: string): number {
    let date = Date.parse(input.split("T")[0])
    return date
  }

  timeAsSeconds(input: string): number {
    let parts = input.split(":")
    return (+ parts[0] * 3600) + (+parts[1] * 60) + (+parts[2])
  }

  dateToBarType(input: number): string {
    let date = new Date(input)

    if (this.barType == "Day") {
      return formatDate(date, "dd/MM/yyyy", "en") // 31/10/2023
    }
    else if (this.barType == "Week") {
      return "Week " + formatDate(date, "ww yyyy", "en") // Week 44 2023
    }
    else if (this.barType == "Month") {
      return formatDate(date, "LLL yyyy", "en") // Oct 2023
    }
    return date.toDateString()
  }

  fillDates(e: Event, days: number) {
    let now = new Date()
    this.dateTo = now.toISOString()

    let past = new Date()
    past.setDate(now.getDate() - days)
    this.dateFrom = past.toISOString()

    if (days <= 14) {
      this.barType = "Day"
    } else if (days <= 30) {
      this.barType = "Week"
    } else if (days >= 31) {
      this.barType = "Month"
    }
  }
}